<mat-card class="cardWithShadow theme-card">
  <mat-card-header>
    <mat-card-title class="m-b-0">New Email</mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>

  <div style="padding: 2.5%">
    <div mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>Subject</mat-label>
        <input matInput [(ngModel)]="emailSubject">
      </mat-form-field>
      <quill-editor [(ngModel)]="emailBody" style="height: 250px"></quill-editor>
    </div>
    <div mat-dialog-actions style="margin-top: 1%">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button color="primary" (click)="onSend()">Send Email</button>
    </div>
  </div>

</mat-card>

