import {Component, Input, OnInit} from '@angular/core';
import {SearchContact} from "../../models/search-contact.model";
import {Search} from "../../models/search.model";
import {UserProfileService} from "../../services/user-profile.service";
import {Contact} from "../../models/contact.model";
import {CreateContactDialogComponent} from "../create-contact-dialog/create-contact-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import {UserProfile} from "../../models/user-profile.model";

@Component({
  selector: 'app-search-contacts',
  templateUrl: './search-contacts.component.html',
  styleUrl: './search-contacts.component.scss'
})
export class SearchContactsComponent implements OnInit {

  @Input() search!: Search;
  @Input() contacts!: any;
  @Input() isSuggested: boolean = false;

  engagementProfiles: PortfolioSearchEngagement[] = [];
  searchContacts: SearchContact[] = [];
  suggestedContacts: UserProfile[] = [];
  searchFirm!: any[];
  isContactsActive: boolean = true;
  public message: string = '';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private contactService: UserProfileService,
    private portfolioSearchEngagementService: PortfolioSearchEngagementService
    ) {
  }

  ngOnInit(): void {

    this.loadSearchContacts(this.search.id);
    this.loadSuggestedContacts(this.search.id);
  }

  private loadSearchContacts(searchId: number): void {

    this.portfolioSearchEngagementService.getCandidatesForSearchBy(searchId, 2).subscribe({
      next: (response) => {
        // console.log('Candidate List :: ', response);
        this.engagementProfiles = response
      },
      error: (error) => console.error('Failed to load contacts', error)
    });
  }

  private loadSuggestedContacts(searchId: number): void {
    this.contactService.getSuggestedContact(searchId).subscribe({
      next: (response) => {

        this.suggestedContacts = response.slice(0, 6)
        // console.log("Suggested Contact :: ", this.suggestedContacts)
        if (this.suggestedContacts.length === 0) {
          // this.isContactsActive = false;  // Switch to suggested if no contacts
        }
      },
      error: (error) => console.error('Failed to load contacts', error)
    });
  }

  removeSearchContact(index: number) {
    this.suggestedContacts.splice(index, 1);  // Removes the search contact from the array
    this.loadSearchContacts(this.search.id);
  }

  removeContact(index: number) {
    this.suggestedContacts.splice(index, 1);  // Removes the contact from the array
    this.loadSearchContacts(this.search.id);
  }

  addToSearchContact(newContact: UserProfile) {
    // this.suggestedContacts.push(newContact);  // Adds the new contact to the array
    // this.loadSearchContacts(this.search.id);
  }

  toggleTabs(isContacts: boolean) {
    this.isContactsActive = isContacts;
    if (isContacts && this.engagementProfiles.length === 0 && this.suggestedContacts.length > 0) {
      this.isContactsActive = false;  // Prevent showing an empty contacts tab
    }
  }

  openCreateContactDialog() {
    const dialogRef = this.dialog.open(CreateContactDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', top: '0px' },
      data: { search: this.search }
    });

    dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {

      this.loadSearchContacts(this.search.id);
      this.message = message;
      dialogRef.close(); // Close the dialog when the event is received
      console.log(message);
      setTimeout(() => {
        this.message = '';
      }, 7000);
    });
  }

  goToSearchPrep() {
    this.router.navigate([`/home/search/${this.search.id}/prep`]);
  }

}
