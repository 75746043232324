import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-email-dialog',
  templateUrl: './create-email-dialog.component.html',
  styleUrl: './create-email-dialog.component.scss'
})
export class CreateEmailDialogComponent {

  emailSubject = '';
  emailBody = '';

  constructor(public dialogRef: MatDialogRef<CreateEmailDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSend(): void {
    // Logic to handle email sending
    console.log('Sending email:', this.emailSubject, this.emailBody);
    this.dialogRef.close({ subject: this.emailSubject, body: this.emailBody });
  }

}
