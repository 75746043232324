<!-- Adaptively show different rating displays based on screen size -->
<div class="rating-container">
  <ng-container>
    <span class="stars">
      <span class="stars">
        <mat-icon *ngIf="roundedRating >= 1" class="star">star</mat-icon>
        <mat-icon *ngIf="roundedRating < 1" class="star">star_border</mat-icon>
      </span>

    </span>
    <span *ngIf="displayRatingValue" class="display-rating-value">{{ rating }}</span>
  </ng-container>
</div>
