import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {SearchService} from "../../services/search.service";
import {MatDialog} from '@angular/material/dialog';
import {CreateSearchDialogComponent} from "../create-search-dialog/create-search-dialog.component";
import {CreateContactDialogComponent} from "../create-contact-dialog/create-contact-dialog.component";
import {Router} from "@angular/router";
import {SearchGroupByPortfolio} from "../../models/search.model";
import {CreateCompanyDialogComponent} from "../create-company-dialog/create-company-dialog.component";

@Component({
  selector: 'app-search-board',
  templateUrl: './search-board.component.html',
  styleUrl: './search-board.component.scss'
})
export class SearchBoardComponent implements OnInit {

  @Input() data: any;
  orderedGroupedSearches: any[] = [];
  orderedGroupedSearchesByPortfolio: SearchGroupByPortfolio = {};
  isStageViewSelected: boolean = true;

  public message: string = '';

  statusMap = new Map<string, string>([
    ['cdk-drop-list-0', 'TRIGGERED'],
    ['cdk-drop-list-1', 'PREP'],
    ['cdk-drop-list-2', 'OPEN'],
    ['cdk-drop-list-3', 'OFFER_PREP'],
    ['cdk-drop-list-4', 'OFFER_EXTENDED'],
    ['cdk-drop-list-5', 'SIGNED'],
    ['cdk-drop-list-6', 'CLOSED']
  ]);

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private searchService: SearchService) {
  }

  ngOnInit(): void {
    this.getGroupSearches();
    this.getGroupSearchesByCompany()
  }

  drop(event: CdkDragDrop<any[]>) {

    console.log('Previous container:', event.previousContainer.id);
    console.log('Current container:', event.container.id);

    if (event.previousContainer === event.container) {
      console.log('Moving item within the same container.');
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log('Moving item across containers.');
      console.log('Item:', event.previousContainer.data[event.previousIndex]);

      const status = this.statusMap.get(event.container.id)
      const searchId = event.previousContainer.data[event.previousIndex].id

      // Attempt to move items between containers
      try {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );

        console.log('Transfer succeeded.');
        console.log('Search ID : ', searchId);
        this.updateSearchStatus(searchId, status as string);

      } catch (error) {
        console.error('Transfer failed:', error);
      }

      // Log resulting data in each container
      console.log('Previous container data:', event.previousContainer.data);
      console.log('Current container data:', event.container.data);
    }
  }

  openCreateSearchDialog() {
    const dialogRef = this.dialog.open(CreateSearchDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', top: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {

      if(success) {
        this.getGroupSearches();
      }
      this.message = message;
      dialogRef.close(); // Close the dialog when the event is received
      console.log(message);
      setTimeout(() => {
        this.message = '';
      }, 10000);
    });

  }

  openCreateContactDialog() {
    const dialogRef = this.dialog.open(CreateContactDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', top: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {

      this.message = message;
      dialogRef.close(); // Close the dialog when the event is received
      console.log(message);
      setTimeout(() => {
        this.message = '';
      }, 10000);
    });
  }

  openCreateCompanyDialog() {
    const dialogRef = this.dialog.open(CreateCompanyDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', top: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {

      this.message = message;
      dialogRef.close(); // Close the dialog when the event is received
      console.log(message);
      setTimeout(() => {
        this.message = '';
      }, 10000);
    });
  }

  orderSearches(groupedSearches: any): any[] {
    const order = ['Triggered', 'Prep', 'Open', 'Offer Prep', 'Offer Extended', 'Signed', 'Closed'];
    return order.map(status => ({ status, data: groupedSearches[status] || [] }));
  }

  private getGroupSearches() {
    this.searchService.getGroupedSearchesByStatusGiven(2).subscribe({
      next: (groupedData) => {
        // console.log('Grouped Searches By Status :: ', groupedData);
        this.orderedGroupedSearches = this.orderSearches(groupedData);
      },
      error: (err) => console.error('Failed to load grouped searches:', err)
    });
  }

  updateSearchStatus(searchId: number, status: string): void {
    this.searchService.updateSearchStatus(searchId, status).subscribe({
      next: () => {
        console.log('Search status updated successfully');
      },
      error: (error) => {
        console.error('Failed to update search status:', error);
      }
    });
  }

  goToSearchDetails(searchId: number) {
    this.router.navigate(['/home/searches', searchId]);
  }

  toggleTabs(toggle: boolean) {
    this.isStageViewSelected = toggle;
  }

  private getGroupSearchesByCompany() {

    this.searchService.getGroupedSearchesByPortfolio(2, 2).subscribe({
      next: (response) => {
        this.orderedGroupedSearchesByPortfolio = response;
        // console.log('Grouped Searches By Portfolio :: ', this.orderedGroupedSearchesByPortfolio)
      },
      error: (err) => console.error('Failed to load grouped searches:', err)
    });
  }

  getCompanyNames(): string[] {
    return Object.keys(this.orderedGroupedSearchesByPortfolio);
  }

}
