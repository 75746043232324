export enum SearchProgress {
  CALIPER = "Caliper",
  PREVIOUS_SEARCH = "Previous Search",
  SEARCH_FIRM_SCREENED = "Search Firm Screened",
  DIRECTOR_SCREENED = "Director Screened",
  VP_SCREENED = "VP Screened",
  HIRING_MANAGER_INTERVIEW = "Hiring Manager Interview",
  TECHNICAL_ASSESSMENT_INVESTING = "Technical Assessment - Investing",
  TECHNICAL_ASSESSMENT_OPERATOR = "Technical Assessment - Operator",
  COMPANY_INTERVIEW = "Company Interview",
  WORKING_SESSION = "Working Session",
  BOARD_SELLING_CONVERSATION = "Board Selling Conversation",
  REFERENCES_BACKCHANNEL = "References/Backchannel",
  OFFER_EXTENSION = "Offer Extension",
  TOP_CANDIDATE = "Top candidate"
}

export const SEARCH_PROGRESS_COLORS = {
  [SearchProgress.CALIPER.valueOf()]: "#f44336",
  [SearchProgress.PREVIOUS_SEARCH.valueOf()]: "#e91e63",
  [SearchProgress.SEARCH_FIRM_SCREENED.valueOf()]: "#9c27b0",
  [SearchProgress.DIRECTOR_SCREENED.valueOf()]: "#673ab7",
  [SearchProgress.VP_SCREENED.valueOf()]: "#3f51b5",
  [SearchProgress.HIRING_MANAGER_INTERVIEW.valueOf()]: "#2196f3",
  [SearchProgress.TECHNICAL_ASSESSMENT_INVESTING.valueOf()]: "#03a9f4",
  [SearchProgress.TECHNICAL_ASSESSMENT_OPERATOR.valueOf()]: "#00bcd4",
  [SearchProgress.COMPANY_INTERVIEW.valueOf()]: "#009688",
  [SearchProgress.WORKING_SESSION.valueOf()]: "#4caf50",
  [SearchProgress.BOARD_SELLING_CONVERSATION.valueOf()]: "#8bc34a",
  [SearchProgress.REFERENCES_BACKCHANNEL.valueOf()]: "#cddc39",
  [SearchProgress.OFFER_EXTENSION.valueOf()]: "#ffeb3b",
  [SearchProgress.TOP_CANDIDATE.valueOf()]: "#ffc107"
};

export const SEARCH_PROGRESS_ORDER = Object.values(SearchProgress);
