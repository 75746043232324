<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows style="margin-top: 3%">

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="table-header-style"> Status </th>
    <td mat-cell *matCellDef="let element"> {{ element.search?.status }} </td>
  </ng-container>

  <ng-container matColumnDef="portfolio_company">
    <th mat-header-cell *matHeaderCellDef class="table-header-style"> Portfolio Company </th>
    <td mat-cell *matCellDef="let element"> {{ element.search?.portfolioCompany?.name }}  </td>
  </ng-container>

  <ng-container matColumnDef="job_title">
    <th mat-header-cell *matHeaderCellDef class="table-header-style"> Job Title </th>
    <td mat-cell *matCellDef="let element"> {{ element.search?.jobTitle }} </td>
  </ng-container>

  <ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef class="table-header-style"> Rating </th>
    <td mat-cell *matCellDef="let element">
      <app-rating [rating]="element.assessmentRating"></app-rating>
    </td>
  </ng-container>

  <ng-container matColumnDef="dates">
    <th mat-header-cell *matHeaderCellDef class="table-header-style"> Dates </th>
    <td mat-cell *matCellDef="let element"> {{ element.lastActiveDate }} </td>
  </ng-container>

  <!-- Column for expand/collapse icon -->
  <ng-container matColumnDef="toggle">
    <th mat-header-cell *matHeaderCellDef class="table-header-style">  </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon class="expand-icon" (click)="toggleRow($event, element)">
        {{ element === expandedElement ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </td>
  </ng-container>

  <!-- Expanded Detail Row Definition -->
  <ng-container matColumnDef="promotion">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div *ngIf="expandedElement === element" class="example-element-detail"
           [@detailExpand]="expandedElement === element ? 'promotion' : 'collapsed'">
        Promotions
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row" (click)="toggleRow($event, element)">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['promotion']; "
      [@detailExpand]="row === expandedElement ? 'promotion' : 'collapsed'"
      class="example-detail-row">
  </tr>

</table>
