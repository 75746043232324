<body [ngClass]="themeClass">
  <header>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

    <div class="top-bar">
      <div class="logo">
        <ng-container class="app-name" *ngIf="themeClass === 'light-theme'">
          <img src="/assets/images/logo/PulseHub.png" class="logo" alt="PulseHub Logo">
        </ng-container>
        <ng-container class="app-name" *ngIf="themeClass === 'dark-theme'">
          <img src="/assets/images/logo/PulseHub_Dark_Theme.png" class="logo" alt="PulseHub Dark Theme Logo">
        </ng-container>
      </div>
      <nav>
        <ul [ngClass]="{'show-menu': isMenuOpen}">
          <li><a href="#why_plusehub">Why PluseHub?</a></li>
          <li><a href="#connected-solutions">Solutions</a></li>
          <li><a href="#features">Features</a></li>
          <li><a href="#comparison">Differentiation</a></li>
          <li><a href="#team">Team</a></li>
          <li><a href="#contact">Contact Us</a></li>
          <li><a [routerLink]="'/login'">Login</a></li>
        </ul>
        <div class="menu-toggle" (click)="toggleMenu()">
          <i class="menu-icon">☰</i> <!-- Placeholder icon -->
        </div>
      </nav>
    </div>
  </header>

  <main>
    <section class="image-slider">
      <div class="slides">
        <div class="slide" *ngFor="let slide of slides; let i = index" [ngClass]="{'active': currentSlide === i}">
          <div class="overlay-text">
            <h1>{{ slide.title }}</h1>
            <p>{{ slide.description }}</p>
            <img src="/assets/images/logo/PulseHub_P.png" class="p-logo" alt="PulseHub P Logo">

            <div class="rotating-header-section">
              <hr>
              <h2 class="main-header">
                <span>{{ currentMainWord }} </span> Management that Enables
              </h2>
              <p class="subtitle">{{ currentSubtitle }}</p>
            </div>

          </div>
        </div>
      </div>
<!--      <button class="prev" (click)="prevSlide()">&#10094;</button>-->
<!--      <button class="next" (click)="nextSlide()">&#10095;</button>-->

    </section>

    <section id="why_plusehub" class="pulse-hub-section">
      <h2>Why <span style="color: #009cff">PulseHub</span>?</h2>
      <div class="pulse-hub-grid">
        <div class="pulse-hub-card">
          <h3>Born out of Frustration </h3>
          <p>
            Pulsehub was created from firsthand PE experience, where outdated data, fragmented tools,
            and manual processes slowed everything down. Despite established systems, there was no
            solution comprehensive enough to connect all teams and support the complex needs of PE
            operations end-to-end.
            <b>
              Pulsehub fills this gap, designed to unify, streamline, and bring
              real-time insight to every part of the firm.
            </b>
          </p>
        </div>
        <div class="pulse-hub-card">
          <h3>Built for Your Firm’s Collective Success</h3>
          <p>
            Pulsehub understands that each team has unique goals and workflows, yet all efforts ultimately
            align to drive firm-wide value. By integrating seamlessly with your existing systems,
            Pulsehub unifies data across teams, eliminates silos, and creates a shared foundation for
            collaboration—empowering everyone to work smarter and deliver greater impact.
          </p>
        </div>
        <div class="pulse-hub-card">
          <h3>Essential: Network-Driven Technology</h3>
          <p>
            PE firms need more than data— they need a platform that connects, integrates & maximizes their
            most valuable asset: people.
            PulseHub empowers firms with a comprehensive 360-degree view of their executive network,
            portfolio, and engagement strategies—transforming data into actionable insights that fuel
            smarter, more informed investment decisions.
          </p>
        </div>
      </div>
    </section>

    <section id="connected-solutions" class="connected-solutions-section">
      <h2>Connected Solutions</h2>
      <div class="connected-solutions-grid">
        <div class="connected-solution-card" (click)="selectSolution('search')">
          <span class="material-symbols-outlined">data_loss_prevention</span>
          <h3>Search Management</h3>
          <p>Enable real-time collaboration and efficient management of search firms, internal teams, and stakeholders to improve placement accuracy and reduce time-to-hire.</p>
          <a href="#" class="details-link" (click)="selectSolution('search'); $event.preventDefault()">Details</a>
        </div>
        <div class="connected-solution-card" (click)="selectSolution('workflow')">
          <span class="material-symbols-outlined">sync_alt</span>
          <h3>Workflow Management</h3>
          <p>Streamline and automate critical workflows across the firm, ensuring efficient collaboration and tracking of key processes while reducing manual tasks and improving overall productivity.</p>
          <a href="#" class="details-link" (click)="selectSolution('workflow'); $event.preventDefault()">Details</a>
        </div>
        <div class="connected-solution-card" (click)="selectSolution('portfolio')">
          <span class="material-symbols-outlined">rebase</span>
          <h3>Portfolio Management</h3>
          <p>Gain insights into talent, financial performance, portfolio optics, and overall management, empowering data-driven decisions to drive portfolio success.</p>
          <a href="#" class="details-link" (click)="selectSolution('portfolio'); $event.preventDefault()">Details</a>
        </div>
      </div>

      <!-- Detail Sections -->
      <div *ngIf="selectedSolution === 'search'" class="solution-details">
        <h3>
          <span style="color: #009cff">PulseHub's</span> search management feature transforms the way your team handles executive searches
        </h3>
        <p>
          keeping all relevant data, collaboration, and workflows in one unified space—while seamlessly
          handling the administrative work for you.
        </p>

        <div class="pulse-hub-grid">
          <div class="pulse-hub-card-solutions">
            <h3>Collaborative Hub for Stakeholders </h3>
            <p>
              Pulsehub’s collaborative hub keeps every stakeholder in sync, with instant access to interview
              histories, guides, and AI-powered updates. Each stakeholder remains informed and prepared,
              ensuring every candidate is assessed with a comprehensive, consistent approach.
            </p>
          </div>
          <div class="pulse-hub-card-solutions">
            <h3>Efficient Search Preparation</h3>
            <p>
              Say goodbye to lengthy email chains and presentations. Pulsehub enables stakeholders to
              collaborate from the outset, with tech-supported workflows. Create interview processes
              with smart, out-of-the-box role criteria that can be customized, allowing searches to
              kick off faster with fewer gaps or bottlenecks.
            </p>
          </div>
          <div class="pulse-hub-card-solutions">
            <h3>Smart Candidate Recommendations</h3>
            <p>
              Let Pulsehub’s intelligent system suggest candidates from your database who align with
              specific search criteria. Auto-tagging aggregates insights from interviews, and other
              sources creating a 360-degree view of each candidate without the need for manual data entry.
            </p>
          </div>
        </div>

        <p>
          <b>All the admin tasks—tracked, updated, and organized automatically.</b>
          Pulsehub handles the details, so your team can focus on finding and evaluating top talent effortlessly.
        </p>

      </div>
      <div *ngIf="selectedSolution === 'workflow'" class="solution-details">
        <h3>
          <span style="color: #009cff">PulseHub's</span> workflow management streamlines and automates routine tasks
        </h3>
        <p>
          keeping your team aligned in one collaborative space. Configurable workflows adapt to your
          firm’s needs with ready-to-use best practices, eliminating manual tasks so you can focus
          on high-impact work.
        </p>
      </div>
      <div *ngIf="selectedSolution === 'portfolio'" class="solution-details">
        <h3>
          <span style="color: #009cff">PulseHub's</span> portfolio management puts your entire portfolio at your fingertips,
          giving you unmatched visibility and control
        </h3>
        <p>
          With centralized views, track all portfolio executives, key company data including relevant documents,
          firm alignment, and upcoming board meetings across companies, all updated in real time.
          This powerful insight enables you to coordinate seamlessly, respond faster, and keep every
          team member aligned on critical portfolio priorities, from high-level strategy to day-to-day operations.
        </p>
      </div>
    </section>

    <section id="features" class="collaborative-features-section">
      <h2>Collaborative Features</h2>
      <!-- Icon Grid -->
      <div class="icon-grid">
        <div class="icon-card">
          <span class="material-symbols-outlined">task</span>
          <p>Tasks</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">bar_chart_4_bars</span>
          <p>Dashboard</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">content_paste</span>
          <p>Forms</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">database</span>
          <p>Database</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">description</span>
          <p>Docs</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">precision_manufacturing</span>
          <p>Automation</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">lightbulb</span>
          <p>AI</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">strategy</span>
          <p>KPIs</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">email</span>
          <p>Email</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">reviews</span>
          <p>Reviews</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">dashboard</span>
          <p>Templates</p>
        </div>
        <div class="icon-card">
          <span class="material-symbols-outlined">quiz</span>
          <p>Surveys</p>
        </div>
      </div>

      <!-- First Row: Image Left, Text Right -->
      <div class="features-content">
        <div class="features-image">
          <img src="/assets/images/website/Dashboard-Screenshot.png" alt="App Screenshot" class="transparent-image" />
        </div>
        <div class="features-text">
          <h2>Real-time portfolio insight.</h2>
          <p>Enabling real-time decisions.</p>
        </div>
      </div>

      <!-- Second Row: Text Left, Image Right with Overlay -->
      <div class="features-content reverse">
        <div class="features-image overlay-image">
          <img src="/assets/images/website/Portfolio-Board-Screenshot.png" alt="App Screenshot" class="transparent-image" />
        </div>
        <div class="features-text">
          <h2>Say goodbye to</h2>
          <p>Data silos</p>
          <p>Fragmented data</p>
          <p>Outdated data</p>
          <p>Missing source of truth</p>
          <p>Redundant processes</p>
          <p>An unaligned firm</p>
        </div>
      </div>
    </section>

    <section id="comparison" class="comparison-section">

      <p-table [value]="competitors" class="comparison-table">
        <ng-template pTemplate="caption">
          <div class="table-caption">
            <h2><span class="highlight">Pulsehub</span> Search Management Competitors</h2>
            <p>The Only HCM Platform for Private Equity</p>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>Solution Type</th>
            <th *ngFor="let header of tableHeaders">{{ header }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-competitor>
          <tr>
            <td>{{ competitor.feature }}</td>
            <td *ngFor="let item of competitor.options">
              <i *ngIf="item === 'check'" class="pi pi-check-circle check-icon"></i>
              <i *ngIf="item === 'cross'" class="pi pi-times-circle cross-icon"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </section>

    <section id="team" class="team-section">
      <h2>OUR TEAM</h2>
      <div class="team-grid">
        <div class="team-member">
          <div class="team-image">
            <img src="/assets/images/website/Ayrinn-Sariol-2.png" alt="Ayrinn Sariol">
          </div>
          <div class="team-text">
            <h3>Ayrinn Sariol</h3>
            <h4>Co-Founder & CEO</h4>
          </div>
        </div>

        <div class="team-member">
          <div class="team-image">
            <img src="/assets/images/website/Issa-Ennab-2.png" alt="Issa Ennab"
            style="transform: scale(1)">
          </div>
          <div class="team-text">
            <h3>Issa Ennab</h3>
            <h4>Co-Founder & CTO</h4>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="contact-section">
      <div class="contact-container">
        <!-- Left Column with Logo and Company Info -->
        <div class="contact-column">
          <img src="/assets/images/logo/PulseHub_P.png" class="contact-logo" alt="PulseHub Logo">
          <h3>QRated Technologies Inc.</h3>
          <p>Austin, TX, USA</p>
          <p>Toronto, ON, Canada</p>
          <p>Contact: <a href="mailto:info&#64;pulsehub.tech">info&#64;pulsehub.tech</a></p>
        </div>

        <!-- Middle Column with Solutions -->
        <div class="contact-column">
          <h3><a href="#connected-solutions">Solutions</a></h3>
          <ul>
            <li>Search Management</li>
            <li>Workflow Management</li>
            <li>Portfolio Management</li>
          </ul>
          <h3><a href="#why_plusehub">Why PulseHub?</a></h3>
          <h3><a href="#features">Features</a></h3>
          <h3><a href="#comparison">Differentiation</a></h3>
        </div>

        <!-- Right Column with Why PulseHub and CTA -->
        <div class="contact-column">
          <h3>
            <a href="mailto:info&#64;pulsehub.tech?subject=Early Access Application&body=Hello PulseHub Team,%0D%0A%0D%0AI am interested in applying for early access to PulseHub. Please let me know the next steps.%0D%0A%0D%0AThank you!%0D%0A[Your Name]"
               class="cta-link">Apply for Early Access</a>
          </h3>
          <p>We are still fine-tuning the product and would love your help. <br/>
            Join our Beta to help contribute.</p>
        </div>
      </div>
    </section>

  </main>

  <footer class="footer">
    <div>© 2024 QRated Technologies Inc. All rights reserved</div>
  </footer>

</body>
