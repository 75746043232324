import {Component, OnInit} from '@angular/core';
import {TodoItem} from "../../models/to-do-item.model";
import {TodoService} from "../../services/todo.service";

@Component({
  selector: 'app-candidate-documents',
  templateUrl: './candidate-documents.component.html',
  styleUrl: './candidate-documents.component.scss'
})
export class CandidateDocumentsComponent implements OnInit {

  documentList: TodoItem[] = [
    {
      id: 1,
      description: 'Resume',
      completed: true
    },
    {
      id: 2,
      description: 'NDA',
      completed: true
    },
    {
      id: 3,
      description: 'Caliper',
      completed: false
    },
    {
      id: 4,
      description: 'Metrics',
      completed: false
    },
    {
      id: 5,
      description: 'Working Session',
      completed: false
    },
    {
      id: 6,
      description: 'References',
      completed: false
    }

  ];

  constructor(private todoService: TodoService) { }

  ngOnInit(): void {
    // this.todoService.getTodos().subscribe(todos => {
    //   this.documentList = todos;
    // });
  }

  onToggleTodoComplete(todoId: number) {
    const todo = this.documentList.find(t => t.id === todoId);
    if (todo) {
      todo.completed = !todo.completed;
    }
  }

}
