import { Component, Input, OnInit } from '@angular/core';
import { SearchProgress, SEARCH_PROGRESS_COLORS, SEARCH_PROGRESS_ORDER } from '../../constants/search-progress.constants';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss']
})
export class ProgressTrackerComponent implements OnInit {

  @Input() currentStage!: string;

  getProgressColor(stage: string): string {

    const currentIndex = SEARCH_PROGRESS_ORDER.indexOf(this.currentStage as SearchProgress);
    const stageIndex = SEARCH_PROGRESS_ORDER.indexOf(stage as SearchProgress);
    return stageIndex <= currentIndex ? SEARCH_PROGRESS_COLORS[stage as keyof typeof SearchProgress] : '#e0e0e0';
  }

  ngOnInit(): void {
  }

}
