<mat-card class="cardWithShadow theme-card">
  <mat-card-header>
    <mat-card-title class="m-b-0">Create New Search</mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>

  <mat-card-content class="b-t-1">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

      <!-- Hidden focus element -->
      <div tabindex="-1" cdkFocusInitial></div>

      <!-- Portfolio Company -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Portfolio Company</mat-label>
        <mat-select placeholder="Select Portfolio Company" formControlName="portfolioCompany">
          <mat-option *ngFor="let company of portfolioCompanies" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('portfolioCompany')?.invalid">Portfolio Company is required.</mat-error>
      </mat-form-field>

      <!-- Priority -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Priority</mat-label>
        <mat-select placeholder="Select Priority" formControlName="priority">
          <mat-option *ngFor="let priority of priorities" [value]="priority.viewValue">
            {{ priority.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('priority')?.invalid">Priority is required.</mat-error>
      </mat-form-field>

      <!-- Function Group -->
<!--      <mat-form-field appearance="outline" class="full-width">-->
<!--        <mat-label>Employment Type</mat-label>-->
<!--        <mat-select placeholder="Select Function Group" formControlName="functionGroup">-->
<!--          <mat-option *ngFor="let group of functionGroups" [value]="group.viewValue">-->
<!--            {{ group.viewValue }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--        <mat-error *ngIf="searchForm.get('functionGroup')?.invalid">Employment Type is required.</mat-error>-->
<!--      </mat-form-field>-->

      <!-- Job Function -->
<!--      <mat-form-field appearance="outline" class="full-width">-->
<!--        <mat-label>Job Function</mat-label>-->
<!--        <mat-select placeholder="Select Job Function" formControlName="jobFunction">-->
<!--          <mat-option *ngFor="let job of jobFunctions" [value]="job.viewValue">-->
<!--            {{ job.viewValue }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--        <mat-error *ngIf="searchForm.get('jobFunction')?.invalid">Job Function is required.</mat-error>-->
<!--      </mat-form-field>-->

      <!-- Job Title Dropdown -->
      <mat-checkbox formControlName="confidential" color="primary">Confidential</mat-checkbox>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Job Title</mat-label>
        <mat-select placeholder="Select Job Title" formControlName="jobTitle">
          <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.viewValue">
            {{ jobTitle.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('jobTitle')?.invalid">Job Title is required.</mat-error>
      </mat-form-field>

      <!-- Trigger Date -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Trigger Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="triggerDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="searchForm.get('triggerDate')?.invalid">Trigger Date is required.</mat-error>
      </mat-form-field>

      <!-- Trigger Reason -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Trigger Reason</mat-label>
        <mat-select placeholder="Select Trigger Reason" formControlName="triggerReason">
          <mat-option *ngFor="let triggerReason of triggerReasons" [value]="triggerReason.viewValue">
            {{ triggerReason.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('triggerReason')?.invalid">Trigger Reason is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Trigger Note</mat-label>
        <input matInput formControlName="triggerNote">
      </mat-form-field>

      <!-- Job Location -->
      <mat-checkbox formControlName="remote" color="primary">Remote</mat-checkbox>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Job Location</mat-label>
        <input type="text" matInput placeholder="Select Job Location" formControlName="jobLocation" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let location of filteredLocations | async" [value]="location.viewValue">
            {{ location.viewValue }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="searchForm.get('jobLocation')?.invalid">Job Location is required.</mat-error>
      </mat-form-field>

      <!-- Budgeted Compensation -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Budgeted Compensation</mat-label>
        <input type="number" matInput formControlName="budgetedCompensation">
        <mat-error *ngIf="searchForm.get('budgetedCompensation')?.invalid">Budgeted Compensation is required.</mat-error>
      </mat-form-field>

      <!-- Submit button -->
      <div class="form-actions">
        <button mat-flat-button class="submit-button" [disabled]="searchForm.invalid">Create</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
