import {Component, EventEmitter, Output} from '@angular/core';
import {TenantService} from "../../services/tenant.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(
    private tenantService: TenantService,
    private router: Router
  )
  {}

  search() {}

  logout(): void {
    sessionStorage.clear(); // Clear all session data
    this.tenantService.clearTenantId();
    console.log('User logged out');
    this.router.navigate(['/login']);
  }
}
