import { Injectable } from '@angular/core';
import {SearchService} from "../services/search.service";
import {Search} from "../models/search.model";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SearchDetailsResolverService implements Resolve<Search | null> {

  constructor(
    private searchService: SearchService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Search | null> {

    const id = route.paramMap.get('id');
    if (id) {
      // console.log('Resolver has been triggered with id:', id);
      return this.searchService.getSearchById(+id).pipe(
        catchError(error => {
          console.error('Fetching data failed', error);
          return of(null);
        })
      );
    } else {
      console.log('No ID provided in route');
      return of(null);
    }
  }
}
