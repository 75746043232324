<div class="left-nav" [ngClass]="{'is-collapsed': isNavCollapsed, 'is-expanded': !isNavCollapsed}">
  <app-left-nav (navToggle)="toggleNav()"></app-left-nav>
</div>

<div class="main-content" [ngClass]="{'main-collapsed': isNavCollapsed, 'main-expanded': !isNavCollapsed}">
  <div class="header">
    <app-header></app-header>
  </div>
  <router-outlet></router-outlet>
</div>
