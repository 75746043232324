import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements OnInit {

  isNavCollapsed: boolean = false;

  constructor(
    private notificationService: NotificationService
  )
  { }

  toggleNav() {
    console.log('isNavCollapsed :: ', this.isNavCollapsed)
    this.isNavCollapsed = !this.isNavCollapsed;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.notificationService.showNotification('New portco placement: Justine Kuwahara, Lyric Innovations, CEO, 8.12.24');
    }, 5000);

    setTimeout(() => {
      this.notificationService.showNotification('Portco departure: Juliana Spiers, Stellar Innovations, CHRO, 8.30.24');
    }, 20000);

    setTimeout(() => {
      this.notificationService.showNotification('New search: Stellar Innovations, CHRO');
    }, 50000);

    setTimeout(() => {
      this.notificationService.showNotification('Reminder: Your interview with Deshaun Brown, Astra, CFO starts in an hour');
    }, 100000);
  }

}
