import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Search } from "../models/search.model";

@Injectable({
  providedIn: 'root'
})
export class SearchStateServiceService {

  private _searches = new BehaviorSubject<Search[]>([]);
  private _selectedSearch = new BehaviorSubject<Search | null>(null);

  private _pinnedSearches = new BehaviorSubject<Search[]>([]);
  private _pinnedSearchCount = new BehaviorSubject<number>(0);

  // Public Observable streams for subscribers
  public readonly searches$: Observable<Search[]> = this._searches.asObservable();
  public readonly selectedSearch$: Observable<Search | null> = this._selectedSearch.asObservable();
  public readonly pinnedSearches$: Observable<Search[]> = this._pinnedSearches.asObservable();
  public readonly pinnedSearchCount$: Observable<number> = this._pinnedSearchCount.asObservable();

  constructor() {}

  // Update the list of general searches
  updateSearches(searches: Search[]): void {
    this._searches.next(searches);
  }

  // Set a specific search as selected
  setSelectedSearch(search: Search | null): void {
    this._selectedSearch.next(search);
  }

  // Update the list of pinned searches
  updatePinnedSearches(searches: Search[]): void {
    this._pinnedSearches.next(searches);
    this.updatePinnedSearchCount(searches.length);
  }

  // Update the count of pinned searches directly
  updatePinnedSearchCount(count: number): void {
    this._pinnedSearchCount.next(count);
  }

  // Clear the list of pinned searches
  clearPinnedSearches(): void {
    this._pinnedSearches.next([]);
    this._pinnedSearchCount.next(0);
  }
}
