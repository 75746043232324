<!-- left-nav.component.html -->
<div class="left-nav">
  <div class="toolbar-menu-logo">
    <div class="app-name-section" (click)="goToHomePage()">
      <ng-container *ngIf="isNavCollapsed">
        <img src="/assets/images/logo/PulseHub_P.png" width="35" style="padding-right: 10px" alt="PulseHub P Logo">
      </ng-container>
      <ng-container class="app-name" *ngIf="!isNavCollapsed">
        <img src="/assets/images/logo/PulseHub.png" width="160" alt="PulseHub Full Logo">
      </ng-container>
    </div>
  </div>

  <ul>
    <button mat-icon-button (click)="toggleNav()" [ngClass]="{'menuWhenCollapsed': isNavCollapsed, '' : !isNavCollapsed}">
      <mat-icon>menu</mat-icon>
    </button>

    <!--    [ngClass]="{'selected-item' : item.label === 'Dashboard'}"-->
    <li *ngFor="let item of navItems">
      <a [routerLink]="item.link" (click)="item.submenu &&  item.label !== 'Dashboard' ? toggleSubMenu(item) : null"
         [pTooltip]="item.label">
        <i class="{{ item.icon }}" [ngClass]="{'icon-style': isNavCollapsed, 'icon-only': !isNavCollapsed}"></i>
        <span *ngIf="!isNavCollapsed">{{ item.label }}</span>
      </a>

      <ul *ngIf="item.isExpanded" class="submenu">
        <li *ngIf="item.label === 'Dashboard' && !isNavCollapsed">
          <ng-container *ngFor="let search of searches">
            <mat-icon class="icon" *ngIf="search.pinnedSearch" (click)="updatePinnedSearch(search.id, false)">push_pin</mat-icon>
            <a (click)="goToSearchDetails(search.id, $event)" style="color: #009CFF"
               [ngClass]="{'selected-search-by-url-param': selectedSearch && search.id === selectedSearch.id, 'list-searches-left-nav': true}">
              {{ search.portfolioCompany.name }} - {{ search.jobTitle }}
            </a>
            <ng-container *ngIf="selectedSearch && search.id === selectedSearch.id && item.label === 'Dashboard'">
              <li *ngFor="let subItem of item.submenu">
                <a [routerLink]="subItem.link">
                  <i class="{{ subItem.icon }}" [ngClass]="{'icon-style': isNavCollapsed, 'icon-only': !isNavCollapsed}"></i>
                  <span *ngIf="!isNavCollapsed">{{ subItem.label }} </span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </li>

        <ng-container *ngIf="(selectedSearch && item.label === 'Dashboard') || item.label !== 'Dashboard'">
          <ng-container *ngIf="item.label !== 'Dashboard'">
            <li *ngFor="let subItem of item.submenu">
              <a [routerLink]="subItem.link" [pTooltip]="subItem.label">
                <i class="{{ subItem.icon }}" [ngClass]="{'icon-style': isNavCollapsed, 'icon-only': !isNavCollapsed}"></i>
                <span *ngIf="!isNavCollapsed">{{ subItem.label }}</span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
