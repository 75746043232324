export const JOB_TITLES = [
  { value: 'CEO', viewValue: 'CEO' },
  { value: 'CFO', viewValue: 'CFO' },
  { value: 'CHRO', viewValue: 'CHRO' },
  { value: 'CMO', viewValue: 'CMO' },
  { value: 'COO', viewValue: 'COO' },
  { value: 'CCO', viewValue: 'CCO' },
  { value: 'CPO_PAYMENTS', viewValue: 'CPO (PAYMENTS)' },
  { value: 'CSO_SALES', viewValue: 'CSO (SALES)' },
  { value: 'CPO_PRODUCT', viewValue: 'CPO (PRODUCT)' },
  { value: 'CTO', viewValue: 'CTO' },
  { value: 'CSO_SECURITY', viewValue: 'CSO (SECURITY)' },
  { value: 'CISO_INFORMATION_SECURITY', viewValue: 'CISO (INFORMATION SECURITY)' },
  { value: 'CIO_INFORMATION', viewValue: 'CIO (INFORMATION)' },
  { value: 'CSO_STRATEGY', viewValue: 'CSO (STRATEGY)' },
  { value: 'PRESIDENT', viewValue: 'PRESIDENT' },
  { value: 'GM', viewValue: 'GM' },
  { value: 'VP_FINANCE', viewValue: 'VP OF FINANCE' },
  { value: 'VP_HR', viewValue: 'VP OF HR' },
  { value: 'VP_MARKETING', viewValue: 'VP OF MARKETING' },
  { value: 'VP_OPERATIONS', viewValue: 'VP OF OPERATIONS' },
  { value: 'VP_CUSTOMER_SUCCESS', viewValue: 'VP OF CUSTOMER SUCCESS' },
  { value: 'VP_PAYMENTS', viewValue: 'VP OF PAYMENTS' },
  { value: 'VP_SALES', viewValue: 'VP OF SALES' },
  { value: 'VP_PRODUCT', viewValue: 'VP OF PRODUCT' },
  { value: 'VP_TECHNOLOGY', viewValue: 'VP OF TECHNOLOGY' },
  { value: 'VP_SECURITY', viewValue: 'VP OF SECURITY' },
  { value: 'VP_INFORMATION_SECURITY', viewValue: 'VP OF INFORMATION SECURITY' },
  { value: 'VP_INFORMATION', viewValue: 'VP OF INFORMATION' },
  { value: 'VP_STRATEGY', viewValue: 'VP OF STRATEGY' },
  { value: 'SVP_FINANCE', viewValue: 'SVP OF FINANCE' },
  { value: 'SVP_HR', viewValue: 'SVP OF HR' },
  { value: 'SVP_MARKETING', viewValue: 'SVP OF MARKETING' },
  { value: 'SVP_OPERATIONS', viewValue: 'SVP OF OPERATIONS' },
  { value: 'SVP_CUSTOMER_SUCCESS', viewValue: 'SVP OF CUSTOMER SUCCESS' },
  { value: 'SVP_PAYMENTS', viewValue: 'SVP OF PAYMENTS' },
  { value: 'SVP_SALES', viewValue: 'SVP OF SALES' },
  { value: 'SVP_PRODUCT', viewValue: 'SVP OF PRODUCT' },
  { value: 'SVP_TECHNOLOGY', viewValue: 'SVP OF TECHNOLOGY' },
  { value: 'SVP_SECURITY', viewValue: 'SVP OF SECURITY' },
  { value: 'SVP_INFORMATION_SECURITY', viewValue: 'SVP OF INFORMATION SECURITY' },
  { value: 'SVP_INFORMATION', viewValue: 'SVP OF INFORMATION' },
  { value: 'SVP_STRATEGY', viewValue: 'SVP OF STRATEGY' },
  { value: 'EVP_FINANCE', viewValue: 'EVP OF FINANCE' },
  { value: 'EVP_HR', viewValue: 'EVP OF HR' },
  { value: 'EVP_MARKETING', viewValue: 'EVP OF MARKETING' },
  { value: 'EVP_OPERATIONS', viewValue: 'EVP OF OPERATIONS' },
  { value: 'EVP_CUSTOMER_SUCCESS', viewValue: 'EVP OF CUSTOMER SUCCESS' },
  { value: 'EVP_PAYMENTS', viewValue: 'EVP OF PAYMENTS' },
  { value: 'EVP_SALES', viewValue: 'EVP OF SALES' },
  { value: 'EVP_PRODUCT', viewValue: 'EVP OF PRODUCT' },
  { value: 'EVP_TECHNOLOGY', viewValue: 'EVP OF TECHNOLOGY' },
  { value: 'EVP_SECURITY', viewValue: 'EVP OF SECURITY' },
  { value: 'EVP_INFORMATION_SECURITY', viewValue: 'EVP OF INFORMATION SECURITY' },
  { value: 'EVP_INFORMATION', viewValue: 'EVP OF INFORMATION' },
  { value: 'EVP_STRATEGY', viewValue: 'EVP OF STRATEGY' },
  { value: 'DIRECTOR_FINANCE', viewValue: 'DIRECTOR OF FINANCE' },
  { value: 'DIRECTOR_HR', viewValue: 'DIRECTOR OF HR' },
  { value: 'DIRECTOR_MARKETING', viewValue: 'DIRECTOR OF MARKETING' },
  { value: 'DIRECTOR_OPERATIONS', viewValue: 'DIRECTOR OF OPERATIONS' },
  { value: 'DIRECTOR_CUSTOMER_SUCCESS', viewValue: 'DIRECTOR OF CUSTOMER SUCCESS' },
  { value: 'DIRECTOR_PAYMENTS', viewValue: 'DIRECTOR OF PAYMENTS' },
  { value: 'DIRECTOR_SALES', viewValue: 'DIRECTOR OF SALES' },
  { value: 'DIRECTOR_PRODUCT', viewValue: 'DIRECTOR OF PRODUCT' },
  { value: 'DIRECTOR_TECHNOLOGY', viewValue: 'DIRECTOR OF TECHNOLOGY' },
  { value: 'DIRECTOR_SECURITY', viewValue: 'DIRECTOR OF SECURITY' },
  { value: 'DIRECTOR_INFORMATION_SECURITY', viewValue: 'DIRECTOR OF INFORMATION SECURITY' },
  { value: 'DIRECTOR_INFORMATION', viewValue: 'DIRECTOR OF INFORMATION' },
  { value: 'DIRECTOR_STRATEGY', viewValue: 'DIRECTOR OF STRATEGY' },
  { value: 'SENIOR_DIRECTOR_FINANCE', viewValue: 'SENIOR DIRECTOR OF FINANCE' },
  { value: 'SENIOR_DIRECTOR_HR', viewValue: 'SENIOR DIRECTOR OF HR' },
  { value: 'SENIOR_DIRECTOR_MARKETING', viewValue: 'SENIOR DIRECTOR OF MARKETING' },
  { value: 'SENIOR_DIRECTOR_OPERATIONS', viewValue: 'SENIOR DIRECTOR OF OPERATIONS' },
  { value: 'SENIOR_DIRECTOR_CUSTOMER_SUCCESS', viewValue: 'SENIOR DIRECTOR OF CUSTOMER SUCCESS' },
  { value: 'SENIOR_DIRECTOR_PAYMENTS', viewValue: 'SENIOR DIRECTOR OF PAYMENTS' },
  { value: 'SENIOR_DIRECTOR_SALES', viewValue: 'SENIOR DIRECTOR OF SALES' },
  { value: 'SENIOR_DIRECTOR_PRODUCT', viewValue: 'SENIOR DIRECTOR OF PRODUCT' },
  { value: 'SENIOR_DIRECTOR_TECHNOLOGY', viewValue: 'SENIOR DIRECTOR OF TECHNOLOGY' },
  { value: 'SENIOR_DIRECTOR_SECURITY', viewValue: 'SENIOR DIRECTOR OF SECURITY' },
  { value: 'SENIOR_DIRECTOR_INFORMATION_SECURITY', viewValue: 'SENIOR DIRECTOR OF INFORMATION SECURITY' },
  { value: 'SENIOR_DIRECTOR_INFORMATION', viewValue: 'SENIOR DIRECTOR OF INFORMATION' },
  { value: 'SENIOR_DIRECTOR_STRATEGY', viewValue: 'SENIOR DIRECTOR OF STRATEGY' },
  { value: 'EXECUTIVE_DIRECTOR_FINANCE', viewValue: 'EXECUTIVE DIRECTOR OF FINANCE' },
  { value: 'EXECUTIVE_DIRECTOR_HR', viewValue: 'EXECUTIVE DIRECTOR OF HR' },
  { value: 'EXECUTIVE_DIRECTOR_MARKETING', viewValue: 'EXECUTIVE DIRECTOR OF MARKETING' },
  { value: 'EXECUTIVE_DIRECTOR_OPERATIONS', viewValue: 'EXECUTIVE DIRECTOR OF OPERATIONS' },
  { value: 'EXECUTIVE_DIRECTOR_CUSTOMER_SUCCESS', viewValue: 'EXECUTIVE DIRECTOR OF CUSTOMER SUCCESS' },
  { value: 'EXECUTIVE_DIRECTOR_PAYMENTS', viewValue: 'EXECUTIVE DIRECTOR OF PAYMENTS' },
  { value: 'EXECUTIVE_DIRECTOR_SALES', viewValue: 'EXECUTIVE DIRECTOR OF SALES' },
  { value: 'EXECUTIVE_DIRECTOR_PRODUCT', viewValue: 'EXECUTIVE DIRECTOR OF PRODUCT' },
  { value: 'EXECUTIVE_DIRECTOR_TECHNOLOGY', viewValue: 'EXECUTIVE DIRECTOR OF TECHNOLOGY' },
  { value: 'EXECUTIVE_DIRECTOR_SECURITY', viewValue: 'EXECUTIVE DIRECTOR OF SECURITY' },
  { value: 'EXECUTIVE_DIRECTOR_INFORMATION_SECURITY', viewValue: 'EXECUTIVE DIRECTOR OF INFORMATION SECURITY' },
  { value: 'EXECUTIVE_DIRECTOR_INFORMATION', viewValue: 'EXECUTIVE DIRECTOR OF INFORMATION' },
  { value: 'EXECUTIVE_DIRECTOR_STRATEGY', viewValue: 'EXECUTIVE DIRECTOR OF STRATEGY' },
  { value: 'INTERIM_CEO', viewValue: 'INTERIM CEO' },
  { value: 'INTERIM_CFO', viewValue: 'INTERIM CFO' },
  { value: 'INTERIM_CHRO', viewValue: 'INTERIM CHRO' },
  { value: 'INTERIM_CMO', viewValue: 'INTERIM CMO' },
  { value: 'INTERIM_COO', viewValue: 'INTERIM COO' },
  { value: 'INTERIM_CCO', viewValue: 'INTERIM CCO' },
  { value: 'INTERIM_CPO_PAYMENTS', viewValue: 'INTERIM CPO (PAYMENTS)' },
  { value: 'INTERIM_CSO_SALES', viewValue: 'INTERIM CSO (SALES)' },
  { value: 'INTERIM_CPO_PRODUCT', viewValue: 'INTERIM CPO (PRODUCT)' },
  { value: 'INTERIM_CTO', viewValue: 'INTERIM CTO' },
  { value: 'INTERIM_CSO_SECURITY', viewValue: 'INTERIM CSO (SECURITY)' },
  { value: 'INTERIM_CISO_INFORMATION_SECURITY', viewValue: 'INTERIM CISO (INFORMATION SECURITY)' },
  { value: 'INTERIM_CIO_INFORMATION', viewValue: 'INTERIM CIO (INFORMATION)' },
  { value: 'INTERIM_CSO_STRATEGY', viewValue: 'INTERIM CSO (STRATEGY)' },
  { value: 'EXECUTIVE_CHAIR', viewValue: 'EXECUTIVE CHAIR' },
  { value: 'P&T CHAIR', viewValue: 'P&T CHAIR' },
  { value: 'BOARD_MEMBER', viewValue: 'BOARD MEMBER' },
  { value: 'BOARD_OBSERVER', viewValue: 'BOARD OBSERVER' },
  { value: 'OPERATING_EXECUTIVE_DIRECTOR', viewValue: 'OPERATING EXECUTIVE DIRECTOR' },
  { value: 'LEAD_DIRECTOR', viewValue: 'LEAD DIRECTOR' },
  { value: 'INDEPENDENT_LEAD_DIRECTOR', viewValue: 'INDEPENDENT LEAD DIRECTOR' },
  { value: 'DIRECTOR', viewValue: 'DIRECTOR' }
];

export type JobTitle = typeof JOB_TITLES[number]['value'];
