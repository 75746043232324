<!-- document-viewer.component.html -->
<div class="document-container">
  <div class="document-list">
    <mat-card style="padding: 5%; min-height: 100%;">
      <mat-card-title style="padding-bottom: 20px">
        Available Documents
        <div class="icon-container" (click)="op.toggle($event)" matTooltip="Attach new file">
          <i class="fas fa-paperclip"></i> <!-- Using paperclip as an attachment icon -->
        </div>
        <p-overlayPanel #op>
          <div>

            <p-fileUpload
              name="fileUpload"
              url="https://www.primefaces.org/cdn/api/upload.php"
              (onUpload)="onUpload($event)"
              (onSelect)="onFileSelect($event)"
              [multiple]="false"
              accept="image/*,application/pdf,application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
          application/vnd.apple.pages, application/vnd.apple.numbers, application/vnd.apple.keynote"
              maxFileSize="1000000">

              <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-clearCallback="clearCallback" let-uploadCallback="uploadCallback">
                <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                  <div class="flex gap-2" style="align-items: center; justify-content: center; text-align: center;">
                    <p-button (onClick)="chooseCallback()" icon="pi pi-images" [rounded]="true" [outlined]="true"></p-button>
                    <p-button (onClick)="uploadCallback()" icon="pi pi-cloud-upload" [rounded]="true" [outlined]="true" severity="success" [disabled]="!files || files.length === 0"></p-button>
                    <p-button (onClick)="clearCallback()" icon="pi pi-times" [rounded]="true" [outlined]="true" severity="danger" [disabled]="!files || files.length === 0"></p-button>
                  </div>
                </div>
              </ng-template>

              <ng-template pTemplate="content" let-files>
                <div *ngIf="!files || files.length === 0" style="display: flex; align-items: center; justify-content: center; text-align: center; height: 80%; font-size: 80%">
                  <div> <!-- Nested div for easier styling and centering -->
                    <i class="pi pi-upload" style="font-size: 2em;"></i> <!-- Drag and drop icon -->
                    <p>Drag and drop files here</p>
                  </div>
                </div>
                <!--          <ul *ngIf="files.length > 0">-->
                <!--            <li *ngFor="let file of files">-->
                <!--              <div>-->
                <!--                <div>{{ file.name }}</div>-->
                <!--                <div>Size: {{ file.size | fileSize }}</div>-->
                <!--                <input type="text" [(ngModel)]="file.name" (change)="onRename(file, file.name)" />-->
                <!--              </div>-->
                <!--            </li>-->
                <!--          </ul>-->
              </ng-template>
            </p-fileUpload>


          </div>
        </p-overlayPanel>
      </mat-card-title>

      <mat-nav-list>
        <li *ngFor="let doc of documents" class="document-item" (click)="selectDocument(doc)" [ngClass]="{'active': selectedDocument === doc}">
          <mat-list-item>
            {{ doc.name }}
          </mat-list-item>
        </li>
      </mat-nav-list>

    </mat-card>
  </div>
  <div class="pdf-viewer" *ngIf="selectedDocument">
    <iframe [src]="(selectedDocument.pdfUrl | safeUrl) ?? defaultPdfUrl"
            width="100%"
            height="100%"
            style="border: 0; width:98%; height:96%;"
    ></iframe>
  </div>

</div>
