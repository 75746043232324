<div class="contact-card">

  <mat-card class="cardWithShadow">
    <mat-card-content>
      <div class="row justify-content-between">
        <div
          class="col-sm-8 text-end d-flex align-items-center justify-content-end">
          <button mat-flat-button (click)="test2()" class="custom-primary-button">Click Me</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="row">
    <div class="col-sm-6 col-lg-4">
      <mat-card class="cardWithShadow">
        <mat-card-content class="text-center p-24">
          <img src="assets/images/profile/user-5.jpg" class="rounded-circle border p-5" width="100" />
          <h4 mat-card-title class="card-title m-t-16 m-b-0">
            {{contact.firstName}} {{contact.lastName}}
          </h4>
          <span class="f-s-14"> {{contact.jobTitle}} </span>
          <div class="m-t-20">
<!--            <button mat-flat-button class="m-r-8" class="approval-button">Approve</button>-->
<!--            <button mat-flat-button class="action-button">Action</button>-->
            <div class="rating">
              <app-rating [rating]="4.8"></app-rating>
            </div>
          </div>
          <div class="general-info">
            <div class="info-item">
              <span class="info-label">Status</span>
              <span class="info-value">In Search</span>
            </div>
            <div class="info-item">
              <span class="info-label">Company</span>
              <span class="info-value">General Dynamics</span>
            </div>
            <div class="info-item">
              <span class="info-label">Phone number</span>
              <span class="info-value">{{contact.phoneNumber}}</span>
            </div>
            <div class="info-item">
              <span class="info-label">Email</span>
              <span class="info-value">{{contact.email}}</span>
            </div>
            <div class="info-item">
              <span class="info-label">EA Email</span>
              <!--      <span class="info-value">support@domain.com</span>-->
            </div>
            <div class="info-item">
              <span class="info-label">Location</span>
              <span class="info-value">{{contact.address}}</span>
            </div>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <div class="icon-row">
          <div class="icon-container">
            <mat-icon>phone</mat-icon>
          </div>
          <div class="icon-container">
            <mat-icon>email</mat-icon>
          </div>
          <div class="icon-container">
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

</div>
