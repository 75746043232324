import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from "../../services/search.service";
import {Search} from "../../models/search.model";
import {ColDef, GridOptions, ValueFormatterParams} from "ag-grid-community";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {SearchContact} from "../../models/search-contact.model";
import {SearchContactService} from "../../services/search-contact.service";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";

@Component({
  selector: 'app-portfolio-company-searches',
  templateUrl: './portfolio-company-searches.component.html',
  styleUrl: './portfolio-company-searches.component.scss'
})
export class PortfolioCompanySearchesComponent implements OnInit {

  @Input() portfolioCompanyId!: number;

  public searches: Search[] = [];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public gridOptions: GridOptions;

  constructor(
    private searchService: SearchService,
    private searchContactService: SearchContactService,
    private searchProfileService: PortfolioSearchEngagementService,
  ) {
    this.columnDefs = [
      { headerName: 'Status', field: 'status' },
      { headerName: 'Job Title', field: 'jobTitle' },
      { headerName: 'Location', field: 'jobLocation' },
      { headerName: 'Placement', field: '',
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          return this.getPlacement(params.data.searchContacts);
        },
      },
      { headerName: 'Lead', field: '',
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          return this.getLeadName(params.data.searchProfiles);
        },
      },
      { headerName: 'Last Active Date', field: 'triggerDate' },
    ];

    this.defaultColDef = {
      resizable: true,
      sortable: true
    };

    this.gridOptions = {
      onGridReady: () => {

      },
      onRowDoubleClicked: event => {
        console.log('Row Clicked', event.data);
      }
    };
  }

  ngOnInit(): void {
    if (this.portfolioCompanyId) {
      this.searchService.getSearchesByPortfolioCompanyId(this.portfolioCompanyId).subscribe({
        next: (searches) => {
          this.searches = searches;
        },
        error: (error) => console.error('Failed to load searches for the portfolio company', error)
      });
    }

  }

  getLeadName(searchProfiles: PortfolioSearchEngagement[]): any {

    return this.searchProfileService.getLeadName(searchProfiles);
  }

  getPlacement(searchContacts: SearchContact[]): any {

    return this.searchContactService.getPlacement(searchContacts);
  }

}
