export const LOCATIONS = [
  { value: 'NYC', viewValue: 'New York, NY' },
  { value: 'LAX', viewValue: 'Los Angeles, CA' },
  { value: 'CHI', viewValue: 'Chicago, IL' },
  { value: 'HOU', viewValue: 'Houston, TX' },
  { value: 'PHX', viewValue: 'Phoenix, AZ' },
  { value: 'PHI', viewValue: 'Philadelphia, PA' },
  { value: 'SA', viewValue: 'Austin, TX' },
  { value: 'SD', viewValue: 'San Diego, CA' },
  { value: 'DAL', viewValue: 'Dallas, TX' },
  { value: 'SJC', viewValue: 'San Jose, CA' }
];
