<div class="trading-dashboard">
<!--  <mat-toolbar color="primary">-->
<!--    <span>Crypto Trading Dashboard</span>-->
<!--  </mat-toolbar>-->
  <div class="cards-container">
<!--    <mat-grid-list cols="3" rowHeight="1:1">-->
<!--      <mat-grid-tile *ngFor="let data of cryptoData">-->
<!--        <app-crypto-card></app-crypto-card>-->
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </div>
</div>
