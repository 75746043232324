import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {JOB_FUNCTIONS} from "../../constants/job-functions.constants";
import {PRIORITIES} from "../../constants/priority.constants";
import {FUNCTION_GROUPS} from "../../constants/function-group.constants";
import {SEARCH_STRATEGIES} from "../../constants/search-strategy.constants";
import {SALARY_RANGES} from "../../constants/salary-range.constants";
import {LOCATIONS} from "../../constants/locations.constants";
import {Observable} from "rxjs";
import {startWith, map} from 'rxjs/operators';
import {PortfolioCompanyService} from "../../services/portfolio-company.service";
import {HttpClient} from "@angular/common/http";
import {SearchService} from "../../services/search.service";
import {JOB_TITLES} from "../../constants/job-titles.constants";
import {TRIGGER_REASONS} from "../../constants/trigger-reason.constants";

@Component({
  selector: 'app-create-search-dialog',
  templateUrl: './create-search-dialog.component.html',
  styleUrl: './create-search-dialog.component.scss'
})
export class CreateSearchDialogComponent implements OnInit{

  searchForm!: FormGroup;
  priorities = PRIORITIES;
  triggerReasons = TRIGGER_REASONS;
  locations = LOCATIONS;
  jobTitles = JOB_TITLES;


  filteredLocations!: Observable<{ value: string; viewValue: string; }[]>;
  portfolioCompanies: any[] = [];

  @Output() dialogClose = new EventEmitter<{ success: boolean, message: string }>();

  constructor(
    private portfolioCompanyService: PortfolioCompanyService,
    private searchService: SearchService,
    private http: HttpClient,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      jobLocation: ['', Validators.required],
      jobTitle: ['', Validators.required],
      confidential: [false],
      remote: [false],
      budgetedCompensation: [''],
      priority: ['', Validators.required],
      triggerReason: ['', Validators.required],
      portfolioCompany: ['', Validators.required],
      triggerDate: ['', Validators.required],
      triggerNote: ['']
    });

    this.setupFormListeners();

    this.filteredLocations = this.searchForm.get('jobLocation')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterLocations(value || ''))
    );

    // Fetch portfolio companies
    this.portfolioCompanyService.getPortfolioCompanies().subscribe(data => {
      this.portfolioCompanies = data;
    });
  }

  private setupFormListeners(): void {
    this.searchForm.get('remote')?.valueChanges.subscribe(remote => {
      const jobLocationControl = this.searchForm.get('jobLocation');
      if (remote) {
        jobLocationControl?.clearValidators();
      } else {
        jobLocationControl?.setValidators(Validators.required);
      }
      jobLocationControl?.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.searchForm.valid) {
      const formData = this.searchForm.value;

      // console.log('Update Search Request :: ', this.prepJsonData(formData))
      this.searchService.createSearch(this.prepJsonData(formData)).subscribe({
        next: (response) => {
          console.log('API response:', response);
          this.dialogClose.emit({success: true, message: 'Search added successfully!'});
        },
        error: (error) => {
          console.error('API error:', error);
          this.dialogClose.emit({success: false, message: 'Error adding search'});
        }
      });

    }
  }

  private prepJsonData(formData: any) {
    // Prepare the JSON object
    const preparedData = {
      ...formData,
      status: 'Triggered',
      isSubSearchFirm: false,
      profile: 2,
      portfolioCompany: {id: formData.portfolioCompany},
    };

    // console.log(preparedData);
    return preparedData;
  }

  private _filterLocations(value: string): { value: string; viewValue: string; }[] {
    const filterValue = value.toLowerCase();
    return this.locations.filter(location => location.viewValue.toLowerCase().includes(filterValue));
  }

}
