import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from "../../services/search.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GridOptions, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";
import {Search} from "../../models/search.model";
import {TEAM_ROLES} from "../../constants/team-role.constants";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";

@Component({
  selector: 'app-interview-stakeholders',
  templateUrl: './interview-stakeholders.component.html',
  styleUrl: './interview-stakeholders.component.scss'
})
export class InterviewStakeholdersComponent implements OnInit {

  @Input() searchDetails!: Search;

  public rowData: any[] = [];
  public columnDefs: any[] = [];
  public defaultColDef: any;

  teamRole = TEAM_ROLES;

  constructor(
    private portfolioSearchEngagementService: PortfolioSearchEngagementService
  ) {

    this.columnDefs = [
      {
        headerName: 'Interviewer',
        field: 'isPartOfTheWorkingSession',
        editable: true,
        headerTooltip: 'Part of the Interview Process',
        maxWidth: 125,
        headerClass: 'ag-header-cell-content-center',
        cellStyle: {textAlign: 'center'}
      },
      {headerName: 'Job Title', field: 'userProfile.jobTitle', minWidth: 120},
      {
        headerName: 'Name', field: 'userProfile.firstName', minWidth: 120,
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          if (params.data.userProfile && params.data.userProfile.firstName && params.data.userProfile.lastName) {
            return `${params.data.userProfile.firstName} ${params.data.userProfile.lastName}`;
          }
          return ''; // Return empty string if data is incomplete
        },
      },
      {
        headerName: 'Role',
        field: 'teamRole',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: (params: ICellRendererParams) => {
          const valueDisplay = params.value ? params.value : 'Select Role';
          return `<span style="display:flex;align-items:center;">${valueDisplay} <i class="material-icons" style="font-size:12px;margin-left:5px;">arrow_drop_down</i></span>`;
        },
        cellEditorParams: {
          values: TEAM_ROLES.map(stage => stage.viewValue) // Extract viewValues for the dropdown
        }
      },
      {headerName: 'Working Session', field: 'isPartOfTheInterviewProcess', editable: true}
    ];

    //valueFormatter: params => `${params.data.contact.firstName} ${params.data.contact.lastName}

    this.defaultColDef = {
      flex: 1,
      minWidth: 70,
      filter: true,
      resizable: true,
      editable: true
    };

    this.gridOptions = {
      onCellValueChanged: event => {
        this.onCellValueChanged(event);
      }
    };
  }

  ngOnInit() {
    this.fetchData();
  }

  // Component method
  onCellValueChanged(event: any): void {
    if (event.colDef.field === 'teamRole' && event.newValue) {
      this.updateTeamRole(event);
    } else {
      this.updateField(event);
    }
  }

  // Helper method to handle team role updates
  private updateTeamRole(event: any): void {
    const correspondingRole = TEAM_ROLES.find(role => role.viewValue === event.newValue);
    if (correspondingRole) {
      this.sendUpdateRequest(event.data.search.id, event.data.userProfile.id, { teamRole: correspondingRole.value });
    }
  }

  // General field update method
  private updateField(event: any): void {
    if (event.data) {
      const updatedField = {[event.colDef.field]: event.newValue};
      this.sendUpdateRequest(event.data.search.id, event.data.userProfile.id, updatedField);
    }
  }

  // Common update request method
  private sendUpdateRequest(searchId: number, userProfileId: number, updatedData: any): void {
    this.portfolioSearchEngagementService.updateEngagement({ searchId, userProfileId, ...updatedData }).subscribe({
      next: () => console.log(`${Object.keys(updatedData)[0]} updated successfully`),
      error: error => console.error(`Error updating ${Object.keys(updatedData)[0]}:`, error)
    });
  }

  public gridOptions: GridOptions = {
    onRowDoubleClicked: event => {
      this.onRowDoubleClick(event)
    }
  };

  onRowDoubleClick(event: any) {
    // this.router.navigate(['/home/searches', event.data.id]);
  }

  fetchData() {
    this.portfolioSearchEngagementService.getStakeholders(this.searchDetails.id, this.searchDetails.portfolioCompany.id).subscribe({
      next: (data) => {
        this.rowData = data;
        // console.log('Grouped Searches:', this.rowData);
      },
      error: (err) => console.error('Failed to load searches:', err)
    });
  }

}
