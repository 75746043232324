import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(url?: string): SafeResourceUrl {
    // Check if the URL is truthy before trying to sanitize it to prevent errors
    return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : '';
  }
}
