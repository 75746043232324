import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.scss'
})
export class PortfolioComponent {

  @Input() label!: string;

  contacts: any[] = [
    {
      firstName: 'John',
      lastName: 'Doe',
      companyName: 'Beacon Technologies',
      position: 'CTO',
      date: this.getDateDaysAgo(-5),
      type: 'Arrival',
      img: 'assets/images/profile/user-7.jpg'
    },
    {
      firstName: 'Jane',
      lastName: 'Post',
      companyName: 'Nebula Systems',
      position: 'CMO',
      date: this.getDateDaysAgo(1),
      type: 'Arrival',
      img: 'assets/images/profile/user-6.jpg'
    },
    {
      firstName: 'Alice',
      lastName: 'Smith',
      companyName: 'Catalyst Dynamics',
      position: 'CFO',
      date: this.getDateDaysAgo(1),
      type: 'Departure',
      img: 'assets/images/profile/user-2.jpg'
    },
    {
      firstName: 'Bob',
      lastName: 'Brown',
      companyName: 'EtherEdge Technologies',
      position: 'CFO',
      date: this.getDateDaysAgo(4),
      type: 'Departure',
      img: 'assets/images/profile/user-3.jpg'
    },
    {
      firstName: 'Charlie',
      lastName: 'Black',
      companyName: 'CyberSphere',
      position: 'CEO',
      date: this.getDateDaysAgo(6),
      type: 'Departure',
      img: 'assets/images/profile/user-8.jpg'
    },
    {
      firstName: 'Diana',
      lastName: 'White',
      companyName: 'Bain Capital',
      position: 'CEO',
      date: this.getDateDaysAgo(5),
      type: 'Arrival',
      img: 'assets/images/profile/user-10.jpg'
    }
  ];

  getDateDaysAgo(days: number): string {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split('T')[0];
  }

  getFilteredContacts(): any[] {
    return this.contacts.filter(contact => contact.type === this.label);
  }

  trackByContact(index: number, contact: any): any {
    return contact.firstName + contact.lastName;
  }
}
