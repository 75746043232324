<div style="margin-left: 2.5%; height: 1000px; padding-top: .5%">

  <app-contact-summary [userProfile]="userProfile"></app-contact-summary>

  <div style="padding-right: 2%; margin-top: 2%;">
    <mat-tab-group mat-stretch-tabs style="background-color: #FFFFFF; border-radius: 10px; margin-bottom: 10%">
      <mat-tab label="Searches">
        <app-contact-searches-expandable-table [userProfile]="userProfile"></app-contact-searches-expandable-table>
      </mat-tab>
      <mat-tab label="Portfolio Engagement">
        <app-contact-portfolio-engagement [contact]="userProfile.id"></app-contact-portfolio-engagement>
      </mat-tab>
      <mat-tab label="Activity">

      </mat-tab>
      <mat-tab label="Work Experience">
        <!-- Content for Tab 4 -->
      </mat-tab>
      <mat-tab label="Document">
        <!-- Content for Tab 4 -->
      </mat-tab>
      <mat-tab label="Search Source">
        <!-- Content for Tab 4 -->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
