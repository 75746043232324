import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Search, SearchGroup, SearchGroupByPortfolio} from '../models/search.model';
import {environment} from "../../environments/environment";
import {PortfolioSearchEngagement} from "../models/portfolio-search-engagement.model";
import {differenceInCalendarDays, formatDistanceToNowStrict, parseISO} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getSearches(): Observable<Search[]> {
    return this.http.get<Search[]>(this.apiUrl);
  }

  getGroupedSearchesByStatus(): Observable<SearchGroup> {
    return this.http.get<SearchGroup>(this.apiUrl + '/searches/grouped-by-status');
  }

  getGroupedSearchesByStatusGiven(profileId: number): Observable<SearchGroup> {
    return this.http.get<SearchGroup>(this.apiUrl + '/searches/grouped-by-status/profile/' + profileId);
  }

  getGroupedSearchesByPortfolio(profileId: number, investmentFirmId: number): Observable<SearchGroupByPortfolio> {
    return this.http.get<SearchGroupByPortfolio>(
      this.apiUrl + '/searches/grouped-by-portfolio/profile/' + profileId + '/investment-firm/' + investmentFirmId
    );
  }

  // createSearch(data: any) {
  //   this.http.post(this.apiUrl + '/searches', data).subscribe({
  //     next: (response) => {
  //       console.log('API response:', response);
  //       // Handle successful response
  //     },
  //     error: (error) => {
  //       console.error('API error:', error);
  //       // Handle error response
  //     }
  //   });
  // }

  createSearch(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/searches', data);
  }

  updateSearchStatus(searchId: number, status: string): Observable<any> {
    const params = new HttpParams().set('status', status);
    // console.log(`${this.apiUrl}/searches/${searchId}/status/${status}`);
    return this.http.put(`${this.apiUrl}/searches/${searchId}/status`, null, {params});
  }

  getActiveSearches(): Observable<Search[]> {
    return this.http.get<Search[]>(this.apiUrl + '/kpi/searches/active-searches');
  }

  getClosedSearches() {
    return this.http.get<Search[]>(this.apiUrl + '/kpi/searches/closed-searches');
  }

  getAllSearches() {
    return this.http.get<Search[]>(this.apiUrl + '/searches');
  }

  getSearchById(searchId: number): Observable<any> {
    return this.http.get<SearchGroup>(this.apiUrl + '/searches/' + searchId);
  }

  getSearchProfilesBySearchId(searchId: number): Observable<any> {
    return this.http.get<PortfolioSearchEngagement>(this.apiUrl + '/search-profiles/search/' + searchId);
  }

  updateSearch(searchId: number, searchRequest: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/searches/${searchId}`, searchRequest);
  }

  getPinnedSearchesByProfile(profileId: number) {
    return this.http.get<PortfolioSearchEngagement[]>(this.apiUrl + '/portfolio-search-engagements/pinned/' + profileId + '/2');
  }

  getSearchesByPortfolioCompanyId(portfolioCompanyId: number) {
    return this.http.get<Search[]>(this.apiUrl + '/searches/portfolio-company/' + portfolioCompanyId);
  }

  getDateByStatus(search: Search): string | undefined {
    switch (search.status) {
      case 'Triggered':
        return search.triggerDate;
      case 'Prep':
        return search.prepDate;
      case 'Open':
        return search.openDate;
      case 'Offer Prep':
      return search.offerPrepDate;
      case 'Offer Extended':
      return search.offerExtendedDate;
      case 'Signed':
        return search.signDate;
      case 'Closed':
      return search.startCloseDate;
      default:
        return undefined;
    }
  }

  getDaysFromToday(dateStr: string): string {
    if (!dateStr) {
      return "-1"; // More descriptive when the date is undefined
    }

    const date = parseISO(dateStr);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day

    const diffDays = differenceInCalendarDays(today, date);

    if (diffDays === 0) {
      return "0"; // Returns "0" if the date is today
    } else {
      return Math.abs(diffDays).toString(); // Returns only the number of days as a string
    }
  }
}
