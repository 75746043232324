<div style="min-height: 55px;">
  <div class="tab-buttons">
    <button mat-button (click)="toggleTabs(true)" [class.active]="isContactsActive && engagementProfiles.length > 0">Candidates</button>
    <button mat-button (click)="toggleTabs(false)" [class.active]="!isContactsActive || engagementProfiles.length == 0">Suggested Contacts</button>
    <button mat-button (click)="toggleTabs(false)" [class.active]="false">Search Firms</button>
  </div>

  <div class="icons">
    <i
      class="fa fa-clipboard-list"
      matTooltip="Go To Search Prep"
      style="color: #66c4f8"
      (click)="goToSearchPrep()">
    </i>
    <i
      class="fa-solid fa-user-plus"
      style="margin-left: 25%; color: #66c4f8"
      matTooltip="Add New Contact to Search"
      (click)="openCreateContactDialog()">
    </i>
  </div>

</div>
<div style="min-height: 2px; background-color: #3F51B5; display: block">
  <div class="message-container" *ngIf="message">
    {{ message }}
  </div>
</div>


<div class="contact-section">

    <ng-container *ngIf="engagementProfiles.length > 0 && isContactsActive">
      <ng-container *ngFor="let engagement of engagementProfiles; let i = index">
        <app-contact-card-horizontal
          [candidates]="engagement"
          [searchId]="search.id"
          [index]="i"
          (remove)="removeContact($event)">
        </app-contact-card-horizontal>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isContactsActive || engagementProfiles.length == 0">
      <ng-container *ngFor="let suggestedContact of suggestedContacts; let i = index">
        <app-contact-card-horizontal
          [userProfile]="suggestedContact"
          [index]="i"
          [searchId]="search.id"
          (remove)="removeSearchContact($event)"
          (add)="addToSearchContact(suggestedContact)">
        </app-contact-card-horizontal>
      </ng-container>
    </ng-container>
</div>
