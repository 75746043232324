<div style="width: 96%; height: 1200px; margin-left: 2.5%;">
  <h2>
    <p>Network</p>
  </h2>

  <div style="margin-top: 1%; height: 4%;">
    <div style="float: right">
      <button mat-raised-button
              [ngClass]="{'button-enabled': selectedRows.length > 0, 'button-disabled': selectedRows.length === 0}"
              (click)="openCreateEmailDialog()"
              [disabled]="!selectedRows.length">
        Send Email
      </button>
    </div>
  </div>
  <ag-grid-angular
    style="width: 100%; height: 500px;"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions">
  </ag-grid-angular>
</div>
