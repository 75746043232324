<mat-card class="cardWithShadow theme-card">
  <mat-card-header>
    <mat-card-title class="m-b-0">Create New Portfolio Company</mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>

  <mat-card-content class="b-t-1">
    <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="name">
        <mat-error *ngIf="companyForm.get('name')?.invalid">Name is required.</mat-error>
      </mat-form-field>

      <!-- Funding Round Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Funding Round</mat-label>
        <mat-select placeholder="Select Funding Round" formControlName="fundingRound">
          <mat-option *ngFor="let round of fundingRounds" [value]="round.viewValue">
            {{ round.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Portfolio Status Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Portfolio Status</mat-label>
        <mat-select placeholder="Select Status" formControlName="portfolioStatus">
          <mat-option *ngFor="let status of statuses" [value]="status.viewValue">
            {{ status.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Portfolio Industry Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Industry</mat-label>
        <mat-select placeholder="Select Industry" formControlName="portfolioIndustry">
          <mat-option *ngFor="let industry of industries" [value]="industry.viewValue">
            {{ industry.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Deal Close Date Picker -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Deal Close Date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Pick a date" formControlName="dealCloseDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="companyForm.get('dealCloseDate')?.invalid">Deal close date is required.</mat-error>
      </mat-form-field>

      <!-- Employee Count Input -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Employee Count</mat-label>
        <input matInput type="number" formControlName="employeeCount">
        <mat-error *ngIf="companyForm.get('employeeCount')?.invalid">Employee count is required.</mat-error>
      </mat-form-field>

<!--      &lt;!&ndash; Location Dropdown &ndash;&gt;-->
<!--      <mat-form-field appearance="outline" class="full-width">-->
<!--        <mat-label>Location</mat-label>-->
<!--        <mat-select placeholder="Select Location" formControlName="location">-->
<!--          <mat-option *ngFor="let location of locations" [value]="location.viewValue">-->
<!--            {{ location.viewValue }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--        <mat-error *ngIf="companyForm.get('location')?.invalid">Location is required.</mat-error>-->
<!--      </mat-form-field>-->

      <!-- Board at Investment Checkbox -->
      <mat-checkbox formControlName="fillBoardAtInvestment">Fill Board at Investment</mat-checkbox>

      <!-- Company Revenue Input -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Company Revenue</mat-label>
        <input matInput type="number" formControlName="companyRevenue">
        <mat-error *ngIf="companyForm.get('companyRevenue')?.invalid">Company revenue is required.</mat-error>
      </mat-form-field>

      <!-- Submit Button -->
      <div class="form-actions">
        <button mat-flat-button color="primary" class="submit-button" [disabled]="companyForm.invalid">Create Company
        </button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
