<div class="page-header">
  <mat-toolbar color="primary">

    <div class="toolbar-logo-search">
      <div class="search-container">
        <input type="text"  placeholder="Search..." class="search-input" />
        <button mat-icon-button class="search-button" (click)="search()">
<!--          <mat-icon>search</mat-icon>-->
        </button>
      </div>
    </div>

    <div class="div-header-spacer"></div>

    <div class="toolbar-icons">
      <button mat-icon-button>
        <mat-icon>notifications_none</mat-icon>
      </button>
      <button mat-icon-button>
        <mat-icon>email</mat-icon>
      </button>
<!--      <button mat-icon-button>-->
<!--        <mat-icon>more_vert</mat-icon>-->
<!--      </button>-->
      <button mat-icon-button (click)="logout()" aria-label="Logout">
        <mat-icon>logout</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Settings</button>
        <button mat-menu-item>Help</button>
        <button mat-menu-item>Sign up</button>
      </mat-menu>
    </div>

  </mat-toolbar>
</div>
