<div style="width: 95%; margin-left: 2.5%; min-height: 1000px">
  <h2>
    <p>Searches</p>
  </h2>

  <div class="stats-cards">
    <app-stats-card style="margin-right: 10%;"
                    [icon]="'search'"
                    [title]="'Searches'"
                    [value]="12"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Searches by Function'"
                    [value]="'8'"
                    [headerValue]="'CEO'"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Searches by Priority'"
                    [value]="'5'"
                    [headerValue]="'First'"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card
                    [icon]="'settings'"
                    [title]="'Searches by Strategy'"
                    [value]="'5'"
                    [headerValue]="'Full Network'"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

<!--    <app-stats-card-->
<!--                    [icon]="'settings'"-->
<!--                    [title]="'Searches by Team'"-->
<!--                    [value]="5"-->
<!--                    [backgroundColor]="'#F7F7F5'"-->
<!--                    [fontColor]="'#777777'"-->
<!--    >-->
<!--    </app-stats-card>-->

  </div>

  <div>
    <app-ag-grid-searches></app-ag-grid-searches>
  </div>
</div>
