import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {SearchContact} from "../../models/search-contact.model";
import {SearchContactService} from "../../services/search-contact.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {Contact} from "../../models/contact.model";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {UserProfile} from "../../models/user-profile.model";
import {Assessment} from "../../models/assessment.model";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import {CreateEmailDialogComponent} from "../create-email-dialog/create-email-dialog.component";

@Component({
  selector: 'app-contact-card-horizontal',
  templateUrl: './contact-card-horizontal.component.html',
  styleUrl: './contact-card-horizontal.component.scss'
})
export class ContactCardHorizontalComponent implements OnInit {

  @Input() candidates!: PortfolioSearchEngagement;
  @Input() userProfile!: UserProfile;
  @Input() contact!: Contact;
  @Input() searchId!: number;
  @Input() index!: number;  // Pass the index if needed for removal
  @Output() remove = new EventEmitter<number>();
  @Output() add = new EventEmitter<SearchContact>();

  tempImgName! : string;
  windowSize: number = window.innerWidth;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public portfolioSearchEngagementService: PortfolioSearchEngagementService
  ) {
  }

  ngOnInit(): void {
    this.tempImgName = this.getRandomImageName();
  }

  getRandomImageName(): string {
    const randomNumber = Math.floor(Math.random() * 9) + 1; // Generates a number between 1 and 9
    return `user-${randomNumber}.jpg`;
  }

  showContactDetails(contact: any) {
    this.router.navigate(['/home/contact', contact.id]);
  }

  addContact(userProfile: UserProfile) {

    const preparedData = {
      userProfileId: userProfile?.id,
      searchId: this.searchId,
      investmentFirmId: 2
    };

    this.addCandidateToSearch(preparedData);
  }

  removeContact(candidateId: any) {
    this.portfolioSearchEngagementService.remove(candidateId).subscribe({
      next: _ => {
        console.log("Search contact updated successfully");
        this.onRemove();
      },
      error: error => console.error('Failed to update search contact', error)
    });
  }

  private addCandidateToSearch(requestBody: any) {

    this.portfolioSearchEngagementService.addCandidateToSearch(requestBody).subscribe({
      next: _ => {
          console.log("Candidate added successfully"),
          this.onRemove();
      },
      error: error => console.error('Failed to add candidate', error)
    });
  }

  // private updateSearchContact(requestBody: any) {
  //   this.searchContactService.updateSearchContact(requestBody).subscribe({
  //     next: _ => {
  //       console.log("Search contact updated successfully");
  //       this.onRemove();
  //     },
  //     error: error => console.error('Failed to update search contact', error)
  //   });
  // }

  onRemove() {
    this.remove.emit(this.index);  // Emit the index of the contact to remove
  }

  confirmRemoveContact(candidate: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: { message: "Are you sure you want to remove this contact?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeContact(candidate.id);
      }
    });
  }

  confirmAddContact(userProfile: UserProfile) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: { message: "Are you sure you want to add this contact?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addContact(userProfile);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowSize = window.innerWidth;
  }

  getLastAssessment(userProfile: UserProfile) {
    console.log('userProfile.assessments :: ', userProfile.assessments?.length);
    // return userProfile.assessments && userProfile.assessments.length > 0 ? userProfile.assessments[userProfile.assessments.length - 1] : null;
  }

  getCandidateProgress(assessments: Assessment[]) {
    if (assessments.length === 0) return undefined;
    return assessments[assessments.length - 1].searchProgress;
  }

  openCreateEmailDialog() {
    const dialogRef = this.dialog.open(CreateEmailDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', bottom: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    // dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {
    //
    //   if(success) {
    //     // this.getGroupSearches();
    //   }
    //   this.message = message;
    //   dialogRef.close(); // Close the dialog when the event is received
    //   console.log(message);
    //   setTimeout(() => {
    //     this.message = '';
    //   }, 10000);
    // });
  }
}
