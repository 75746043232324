import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { TenantService } from '../services/tenant.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  const tenantService = inject(TenantService);
  const router = inject(Router);

  const tenantId = tenantService.getTenantId();
  if (tenantId) {
    return true; // Tenant exists, allow navigation
  } else {
    router.navigate(['/login']); // Redirect to login
    return false; // Block navigation
  }
};
