import {Component, Input} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Project} from "../../models/project.model";

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrl: './basic-table.component.scss'
})
export class BasicTableComponent {

  @Input() dataSource: MatTableDataSource<Project> = new MatTableDataSource<Project>();
  displayedColumns: string[] = ['assigned', 'name', 'priority', 'budget'];
}
