import { Component } from '@angular/core';

@Component({
  selector: 'app-search-card-company-view',
  templateUrl: './search-card-company-view.component.html',
  styleUrl: './search-card-company-view.component.scss'
})
export class SearchCardCompanyViewComponent {

}
