<div class="search-summary-container">
  <div class="search-summary-header">
    <img src="assets/images/companies/company1.png" alt="Company Logo" class="company-logo">
    <div class="company-details" (click)="goToPortfolioPage(search.portfolioCompany)">
      <h2 class="search-summary-title">{{ search.portfolioCompany.name }}
        <span *ngIf="isCollapsed"> - {{ search.jobTitle }}</span>
      </h2>
    </div>
    <mat-icon class="toggle-icon" (click)="toggleCollapse()">{{ isCollapsed ? 'expand_more' : 'expand_less' }}</mat-icon>
  </div>
  <div class="search-summary-details" *ngIf="!isCollapsed">
    <div class="search-summary-info">
      <mat-icon class="icon">business_center</mat-icon>
      <span class="search-summary-label">Job Title:</span>
      <span class="search-summary-value">{{ search.jobTitle }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">history</mat-icon>
      <span class="search-summary-label">
      Days in current status:
    </span>
      <span class="search-summary-value">2</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">info</mat-icon>
      <span class="search-summary-label">
        Status:
      </span>
      <span class="search-summary-value">{{ search.status }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">find_in_page</mat-icon>
      <span class="search-summary-label">
         Search Strategy:
      </span>
      <span class="search-summary-value">{{ search.searchStrategy }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">priority_high</mat-icon>
      <span class="search-summary-label">
         Priority:
      </span>
      <span class="search-summary-value priority">{{ search.priority }}</span>
    </div>
  </div>

  <div class="search-summary-tags" *ngIf="search.searchTags && search.searchTags.length > 0 && !isCollapsed">
    <app-tags [tags]="search.searchTags"></app-tags>
  </div>
</div>
