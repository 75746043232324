<!-- components/assessment-list/assessment-list.component.html -->
<table mat-table [dataSource]="assessments" class="mat-elevation-z8" style="padding-left: 8%; background-color: #EEEEEE">

  <!-- Stage Column -->
  <ng-container matColumnDef="profile">
    <th mat-header-cell *matHeaderCellDef style="width: 23%;">Assessment By</th>
    <td mat-cell *matCellDef="let assessment"> {{assessment.profile?.name}} </td>
  </ng-container>

  <!-- Stage Column -->
  <ng-container matColumnDef="stage">
    <th mat-header-cell *matHeaderCellDef>Stage</th>
    <td mat-cell *matCellDef="let assessment">{{ assessment.candidateStage }}</td>
  </ng-container>

  <!-- Search Progress Column -->
  <ng-container matColumnDef="searchProgress">
    <th mat-header-cell *matHeaderCellDef>Search Progress</th>
    <td mat-cell *matCellDef="let assessment">
      <app-progress-tracker [currentStage]="assessment.searchProgress || ''"></app-progress-tracker>
    </td>
  </ng-container>

  <!-- Rating Column -->
  <ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef>Rating</th>
    <td mat-cell *matCellDef="let assessment">
      <app-rating [rating]="assessment.assessmentRating"></app-rating>
    </td>
  </ng-container>

  <!-- Notes Column with Tooltip -->
  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>
    <td mat-cell *matCellDef="let assessment">
    <span matTooltip="{{ assessment.notes }}">
      {{ assessment.notes | slice:0:10 }}{{ assessment.notes.length > 10 ? '...' : '' }}
    </span>
    </td>
  </ng-container>

  <!-- Table Row Definitions -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
