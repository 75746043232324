import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrl: './tags.component.scss'
})
export class TagsComponent {

  @Input() tags!: any[];

  protected readonly JSON = JSON;
}
