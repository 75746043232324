<div class="search-summary-container">
  <div class="search-summary-header" (click)="toggleCollapse()">
    <img src="assets/images/profile/user-1.jpg" alt="Company Logo" class="company-logo">
    <div class="company-details" style="float: left">
      <h2 class="search-summary-title"> {{ userProfile.firstName }} {{ userProfile.lastName }}
        <span *ngIf="isCollapsed"> - {{ userProfile.jobTitle }}</span>
      </h2>
    </div>
    <div style="float: left; margin-left: 2%">
      <app-rating [rating]="userProfile.assessmentRating" [displayRatingValue]="true"></app-rating>
    </div>
    <mat-icon class="toggle-icon">{{ isCollapsed ? 'expand_more' : 'expand_less' }}</mat-icon>
  </div>
  <div class="search-summary-details" *ngIf="!isCollapsed">
    <div class="search-summary-info">
      <mat-icon class="icon">info</mat-icon>
      <span class="search-summary-label">Status</span>
      <span class="search-summary-value">{{ userProfile.userProfileType }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">business_center</mat-icon>
      <span class="search-summary-label">Job Title:</span>
      <span class="search-summary-value">{{ userProfile.jobTitle }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">business</mat-icon>
      <span class="search-summary-label">Company:</span>
      <span class="search-summary-value">General Dynamics</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">phone</mat-icon>
      <span class="search-summary-label">Phone Number:</span>
      <span class="search-summary-value">{{ userProfile.phoneNumber }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">email</mat-icon>
      <span class="search-summary-label">Email:</span>
      <span class="search-summary-value priority">{{ userProfile.email }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">email</mat-icon>
      <span class="search-summary-label">EA Email:</span>
      <span class="search-summary-value priority">{{ userProfile.executiveAssistantEmail }}</span>
    </div>

  </div>
  <div class="search-summary-tags" *ngIf="userProfile.userProfileTags && userProfile.userProfileTags.length > 0 && !isCollapsed">
    <app-tags [tags]="userProfile.userProfileTags"></app-tags>
  </div>
</div>
