import { Injectable } from '@angular/core';
import {PortfolioSearchEngagement} from "../models/portfolio-search-engagement.model";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserProfile} from "../models/user-profile.model";

@Injectable({
  providedIn: 'root'
})
export class PortfolioSearchEngagementService {

  private apiUrl = environment.apiUrl + '/portfolio-search-engagements';

  constructor(private http: HttpClient) { }

  getCandidatesForSearchBy(searchId: number, investmentFirmId: number): Observable<PortfolioSearchEngagement[]> {
    return this.http.get<PortfolioSearchEngagement[]>(this.apiUrl + '/candidates/' + searchId + '/' + investmentFirmId);
  }

  getLeadName(portfolioSearchEngagements: PortfolioSearchEngagement[]) {

    if (portfolioSearchEngagements && portfolioSearchEngagements.length > 0) {
      // let searchProfile = searchProfiles.filter(searchProfile => searchProfile.leadRole === 'Lead')[0];
      // console.log(searchProfile.profile?.name)
      // return searchProfile.profile?.name;
      return null;
    }

    return '';
  }

  getPortfolioSearchEngagements(): Observable<PortfolioSearchEngagement[]> {
    return this.http.get<PortfolioSearchEngagement[]>(this.apiUrl + '/by-type/PORTFOLIO_EMPLOYEE');
  }

  remove(candidateId: any) {
    return this.http.delete<void>(this.apiUrl + '/' + candidateId);
  }

  addCandidateToSearch(requestBody: any) {
    return this.http.post<UserProfile>(this.apiUrl + '/add-candidate', requestBody);
  }

  getStakeholders(searchId: number, portfolioCompanyId: number): Observable<PortfolioSearchEngagement[]> {
    return this.http.get<PortfolioSearchEngagement[]>(this.apiUrl + '/stakeholders-for-search/search/' + searchId + '/portco/' + portfolioCompanyId);
  }

  updateEngagement(data: Partial<PortfolioSearchEngagement>): Observable<any> {
    return this.http.put(`${this.apiUrl}/update-engagement`, data);
  }

  getSearchesFor(userProfileId: number) {
    return this.http.get<PortfolioSearchEngagement[]>(this.apiUrl + '/user-profile/' + userProfileId );
  }
}
