import { Component, OnInit } from '@angular/core';
import {CellClickedEvent, ColDef, GridOptions} from 'ag-grid-community';
import { UserProfileService } from '../../services/user-profile.service';
import { UserProfile } from '../../models/user-profile.model';
import {Router} from "@angular/router";
import {CreateEmailDialogComponent} from "../create-email-dialog/create-email-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  public selectedRows: any[] = [];
  public defaultColDef: any;
  public rowData: UserProfile[] = [];

  constructor(
    private userProfileService: UserProfileService,
    private  router: Router,
    public dialog: MatDialog
  ) {
    this.gridOptions = {
      pagination: true,
      paginationPageSize: 50,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onSelectionChanged: event => this.onSelectionChanged(event),
    };

    this.defaultColDef = {
      // flex: 1,
      filter: true,
      resizable: true
    };

    this.columnDefs = [
      {
        headerCheckboxSelection: true, // Enables the header checkbox for selecting all rows
        checkboxSelection: true, // Enables the checkbox for each row
        maxWidth: 40,
        filter: false,
      },
      { headerName: 'Name', field: 'firstName', valueFormatter: this.formatName, width: 160,
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToContactPage(event),},
      { headerName: 'Status', field: 'userProfileType', width: 160 },
      { headerName: 'Function', field: 'jobClassification.jobFunction', width: 140 },
      { headerName: 'Job Title', field: 'jobTitle', width: 140 },
      { headerName: 'Company', field: 'portfolioCompany.name', valueFormatter: this.getCompanyName, width: 140,
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToPortfolioPage(event),
      },
      { headerName: 'P. Rating', field: 'assessmentRating', width: 100, headerTooltip: 'Performance Rating'  },
      { headerName: 'A. Rating', field: 'assessmentRating', width: 100, headerTooltip: 'Assessment Rating' }, // This will need additional formatting based on your tags structure
      { headerName: 'Tags', field: 'contactTagIds', width: 120 }, // This will need additional formatting based on your tags structure
      { headerName: 'Location', field: 'address', width: 140 },
      { headerName: 'Email', field: 'email', width: 140 },
      { headerName: 'EA Email', field: 'executiveAssistantEmail', width: 140, headerTooltip: 'Email Assistant Rating' },
      { headerName: 'Phone', field: 'phoneNumber', width: 140 },
    ];
  }


  ngOnInit(): void {
    this.userProfileService.getUserProfilesByInvestmentFirm(2).subscribe(profiles => {
      this.rowData = profiles;
    });
  }

  private formatName(params: any): string {
    return params.data.firstName + ' ' + params.data.lastName;
  }

  private getCompanyName(params: any): string {

    if (params.data.userProfileType === 'Investment Firm Employee') {
      return params.data?.investmentFirm?.name;
    } else if (params.data.userProfileType === 'Portfolio Employee') {
      return params.data?.portfolioCompany?.name;
    }
    return '';
  }

  goToPortfolioPage(event: any) {
    this.router.navigate(['/home/portfolio-board', event.data.portfolioCompany.id]);
  }

  goToContactPage(event: any) {
    this.router.navigate(['/home/contact', event.data.id]);
  }

  onSelectionChanged(event: any): void {
    const selectedNodes = event.api.getSelectedNodes();
    this.selectedRows = selectedNodes.map((node: { data: any; }) => node.data);
    console.log('Selected Nodes:', this.selectedRows);

    // Example: trigger an action like enabling a button based on selection
    // this.enableActionButton(selectedData.length > 0);
  }

  openCreateEmailDialog() {
    const dialogRef = this.dialog.open(CreateEmailDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', bottom: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    // dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {
    //
    //   if(success) {
    //     // this.getGroupSearches();
    //   }
    //   this.message = message;
    //   dialogRef.close(); // Close the dialog when the event is received
    //   console.log(message);
    //   setTimeout(() => {
    //     this.message = '';
    //   }, 10000);
    // });
  }

}
