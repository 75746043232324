<div class="board-container">
  <div class="board-header">

    <div class="tab-buttons">
      <button mat-button (click)="toggleTabs(true)" [class.active]="isStageViewSelected">Stage View</button>
      <button mat-button (click)="toggleTabs(false)" [class.active]="!isStageViewSelected">Company View</button>
    </div>

    <div>
      <button class="search-btn" mat-button (click)="openCreateCompanyDialog()">Create Company</button>
      <button class="search-btn" mat-button (click)="openCreateContactDialog()" style="">Create Contact</button>
      <button class="search-btn" mat-button (click)="openCreateSearchDialog()">Create Search</button>
    </div>
  </div>

  <div class="message-container" *ngIf="message">
    {{ message }}
  </div>

  <ng-container *ngIf="isStageViewSelected">
    <div class="board" cdkDropListGroup>
      <div *ngFor="let item of orderedGroupedSearches"
           class="column"
           cdkDropList
           [cdkDropListData]="item.data"
           (cdkDropListDropped)="drop($event)">

        <span>{{ item.status }} ({{ item.data.length }}) </span>
        <div *ngFor="let search of item.data" class="search" cdkDrag>
          <app-search-card
            [search]="search"
            (dblclick)="goToSearchDetails(search.id)"
          ></app-search-card>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="board" *ngIf="!isStageViewSelected">

    <div *ngFor="let companyName of getCompanyNames()" style="width: 24%; margin-left: 0.75%;">

      <div class="company-view-header">
        {{ companyName }}
      </div>

      <div *ngFor="let search of orderedGroupedSearchesByPortfolio[companyName]" class="search">
        <app-search-card
          [search]="search"
          [isByCompany]="true"
          (dblclick)="goToSearchDetails(search.id)"
        ></app-search-card>
      </div>
    </div>

  </div>

</div>
