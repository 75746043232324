import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrl: './website.component.scss'
})
export class WebsiteComponent implements OnInit {

  themeClass: string = 'light-theme'; // Default theme
  isMenuOpen = false;
  currentSlide = 0;
  slideInterval: any;
  selectedSolution: string = '';

  private mainWordIndex = 0;
  private subtitleIndex = 0;
  private mainWordInterval: any;
  private subtitleInterval: any;

  tableHeaders = ['PulseHub', 'Thrive', ' Others  '];
  competitors = [
    { feature: 'Portfolio Management', options: ['check', 'cross', 'cross'] },
    { feature: 'Automation & AI', options: ['check', 'cross', 'check'] },
    { feature: 'PE-Specific Workflows', options: ['check', 'cross', 'cross'] },
    { feature: 'Centralized Hub for All Ops', options: ['check', 'cross', 'cross'] },
    { feature: 'Integrations & Scalability', options: ['check', 'cross', 'check'] },
    { feature: 'Cost Savings (Admin & Ops)', options: ['check', 'cross', 'check'] },
  ];

  mainWords = ['Search', 'Workflow', 'Portfolio'];
  subtitles = [
    'Better aligned stakeholders',
    'Fewer meetings and emails',
    'Real-time collaboration',
    'Optimized productivity',
    'Less administration, more execution',
    'Real-time performance insight',
    'Data-informed decisions',
    'A source of truth with data integrity',
    'Increased search efficiency & improved performance'
  ];

  currentMainWord = this.mainWords[0];
  currentSubtitle = this.subtitles[0];

  slides = [
    {
      image: '/assets/images/website/slide-5.jpg',
      title: 'The All-in-One Search & Operations Platform for Private Equity',
      description: 'Centered on Network Power.'
    }
  ];

  selectSolution(solution: string) {
    this.selectedSolution = solution;
  }

  startAutoSlide() {
    this.slideInterval = setInterval(() => {
      this.nextSlide();
    }, 10000); // Move every 4 seconds
  }

  prevSlide() {
    this.currentSlide = (this.currentSlide === 0) ? this.slides.length - 1 : this.currentSlide - 1;
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide === this.slides.length - 1) ? 0 : this.currentSlide + 1;
  }

  ngOnInit() {
    const currentHour = new Date().getHours();
    this.themeClass = currentHour >= 18 || currentHour < 6 ? 'dark-theme' : 'light-theme';
    this.startAutoSlide();

    // Rotate main word every 3 seconds
    this.mainWordInterval = setInterval(() => {
      this.mainWordIndex = (this.mainWordIndex + 1) % this.mainWords.length;
      this.currentMainWord = this.mainWords[this.mainWordIndex];
    }, 7500);

    // Rotate subtitle every 2 seconds
    this.subtitleInterval = setInterval(() => {
      this.subtitleIndex = (this.subtitleIndex + 1) % this.subtitles.length;
      this.currentSubtitle = this.subtitles[this.subtitleIndex];
    }, 3000);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const menuElement = document.querySelector('nav ul');
    const toggleElement = document.querySelector('.menu-toggle');

    if (this.isMenuOpen && !menuElement?.contains(target) && !toggleElement?.contains(target)) {
      this.isMenuOpen = false;
    }
  }

  ngOnDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval); // Clean up to avoid memory leaks
    }
  }

}
