import {Component, OnInit} from '@angular/core';
import {TodoItem} from "../../models/to-do-item.model";
import {TodoService} from "../../services/todo.service";

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrl: './todo-list.component.scss'
})
export class TodoListComponent implements OnInit {

  todos: TodoItem[] = [];
  newTaskDescription: string = '';

  constructor(private todoService: TodoService) { }

  ngOnInit(): void {
    this.todoService.getTodos().subscribe(todos => {
      this.todos = todos;
    });
  }

  onAddTodo() {
    if (this.newTaskDescription.trim() !== '') {
      const newTodo: TodoItem = {
        id: Math.max(...this.todos.map(t => t.id)) + 1, // Simple ID increment logic
        description: this.newTaskDescription,
        completed: false
      };
      this.todos.push(newTodo);
      this.newTaskDescription = ''; // Clear the input field after adding
    }
  }

  onToggleTodoComplete(todoId: number) {
    const todo = this.todos.find(t => t.id === todoId);
    if (todo) {
      todo.completed = !todo.completed;
    }
  }
}
