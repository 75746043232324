import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {TenantService} from "./tenant.service";

@Injectable({
  providedIn: 'root'
})
export class TenantInterceptor implements HttpInterceptor {

  constructor(private tenantService: TenantService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the tenant ID from a service or any other source
    const tenantID = this.tenantService.getTenantId();

    if (tenantID) {
      const modifiedRequest = request.clone({
        setHeaders: {
          'X-Tenant-ID': tenantID
        }
      });
      console.log('Intercepted Request with Tenant ID:', modifiedRequest);
      return next.handle(modifiedRequest);
    }


    // Clone the request and add the X-Tenant-ID header
    // const modifiedRequest = request.clone({
    //   setHeaders: {
    //     'X-Tenant-ID': tenantID
    //   }
    // });

    // Log the outgoing modified request
    console.log('Outgoing Modified Request:', request.url);
    console.log('Modified Request Headers:', request.headers.keys().map(key => ({ [key]: request.headers.get(key) })));
    console.log('Modified Request Body:', request.body);

    // Pass the modified request to the next handler in the chain
    return next.handle(request);
  }

  private getTenantId(): string {
    // Logic to retrieve the tenant ID (e.g., from local storage or a service)
    return 'default'; // Replace this with your actual logic
  }
}
