<div class="dashboard">

  <div class="stats-cards">
    <app-stats-card style="margin-right: 10%;"
      [icon]="'search'"
      [title]="'Open Searches'"
      [value]="activeSearches"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
      [icon]="'settings'"
      [title]="'Closed searches'"
      [value]="closedSearches"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
      [icon]="'school'"
      [title]="'Median Days Open'"
      [value]="'15'"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#14923e'"
      [headerValue]="'105'"
      [headerValueFontColor]="'#CCCCCC'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
      [icon]="'school'"
      [title]="'1st Search Kickoff'"
      [value]="'14'"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#d52626'"
      [headerValue]="'7'"
      [headerValueFontColor]="'#CCCCCC'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
      [icon]="'school'"
      [title]="'2nd Search Kickoff'"
      [value]="'20'"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#14923e'"
      [headerValue]="'45'"
      [headerValueFontColor]="'#CCCCCC'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
      [icon]="'school'"
      [title]="'Top 2 Searches Closed'"
      [value]="'90%'"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#14923e'"
      [headerValue]="'80%'"
      [headerValueFontColor]="'#CCCCCC'"
    >
    </app-stats-card>

    <app-stats-card
      [icon]="'school'"
      [title]="'New Top Prospects'"
      [value]="'6'"
      [backgroundColor]="'#F7F7F5'"
      [fontColor]="'#d52626'"
      [headerValue]="'10'"
      [headerValueFontColor]="'#CCCCCC'"
    >
    </app-stats-card>
  </div>

  <div class="search-board">
    <app-search-board></app-search-board>
  </div>

  <div class="portfolio-section">
    <div class="portfolio-departure">
<!--      <app-portfolio [label]="'Departure'"></app-portfolio>-->
    </div>
    <div class="portfolio-arrival">
<!--      <app-portfolio [label]="'Arrival'"></app-portfolio>-->
    </div>
  </div>
</div>
