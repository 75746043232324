<div class="search-matrix-container">
  <div class="header">
    <div class="tab-buttons">
      <button mat-button (click)="toggleTabs('Experience & Competencies')"
              [class.active]="currentCategory === 'Experience & Competencies'">Experience & Competencies</button>
      <button mat-button (click)="toggleTabs('Intrinsics & Fit')"
              [class.active]="currentCategory === 'Intrinsics & Fit'">Intrinsics & Fit</button>
      <button mat-button (click)="toggleTabs('Key Success Outcomes')"
              [class.active]="currentCategory === 'Key Success Outcomes'">Key Success Outcomes</button>
    </div>
  </div>

  <form [formGroup]="form">
    <table mat-table class="mat-elevation-z8" [dataSource]="matrixFormArray.controls">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 1.5%">Criteria</th>
        <td mat-cell *matCellDef="let element" style="padding-left: 1.5%">{{ element.value.description }}</td>
      </ng-container>

      <ng-container matColumnDef="critical">
        <th mat-header-cell *matHeaderCellDef>Critical</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [formControl]="element.get('critical')" class="toggle-checked toggle-unchecked" color="primary"></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="important">
        <th mat-header-cell *matHeaderCellDef>Important</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [formControl]="element.get('important')" class="toggle-checked toggle-unchecked" color="primary"></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="beneficial">
        <th mat-header-cell *matHeaderCellDef>Beneficial</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [formControl]="element.get('beneficial')" class="toggle-checked toggle-unchecked" color="primary"></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let i = index">
          <button mat-icon-button (click)="removeMatrix(i)" aria-label="Remove criterion">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
    </table>

  </form>
  <div style="margin-top: 2%; float: right">
    <button mat-button class="button" (click)="promptAddMatrix()">
      + Criteria
    </button>
    <button mat-button class="button" (click)="generateJobDescription()">Generate Job Description</button>
  </div>
  <div style="margin-top: 2%; float: left">
    <button mat-button class="button">
      <i class="fa fa-share-nodes"></i> Hiring Manager
    </button>
    <button mat-button class="button" (click)="generateJobDescription()">
      <i class="fa fa-share-nodes"></i> Stakeholders
    </button>
  </div>

</div>
