import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {CellClickedEvent, GridOptions, ValueFormatterParams} from "ag-grid-community";
import { EmploymentDetailsService } from "../../services/employment-details.service";
import { EmploymentDetails } from "../../models/employment-details.model";

@Component({
  selector: 'app-contact-portfolio-engagement',
  templateUrl: './contact-portfolio-engagement.component.html',
  styleUrls: ['./contact-portfolio-engagement.component.scss']
})
export class ContactPortfolioEngagementComponent implements OnInit {

  @Input() contact!: number;

  public rowData: EmploymentDetails[] = [];
  public columnDefs: any[] = [];
  public defaultColDef: any;
  public gridOptions: GridOptions = {};

  constructor(
    private employmentDetailsService: EmploymentDetailsService,
    private router: Router
  ) {
    this.columnDefs = [
      { headerName: 'Status', field: 'portfolioCompany.portfolioStatus' },
      { headerName: 'Portfolio Company', field: 'portfolioCompany.name', minWidth: 180,
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToPortfolioPage(event),
      },
      { headerName: 'Job Title', field: 'positionHeld' },
      { headerName: 'Start Date', field: 'startDate', valueFormatter: this.formatDate },
      { headerName: 'End Date', field: 'endDate', valueFormatter: this.formatDate }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 70,
      filter: true,
      resizable: true
    };
  }

  ngOnInit(): void {
    this.fetchData(this.contact);
  }

  fetchData(contactId: number): void {
    this.employmentDetailsService.getPortfolioEngagementBy(contactId).subscribe({
      next: data => {
        this.rowData = data;
      },
      error: err => console.error('Failed to load contact engagements:', err)
    });
  }

  goToPortfolioPage(event: any) {
    this.router.navigate(['/home/portfolio-board', event.data.portfolioCompany.id]);
  }

  formatDate(params: ValueFormatterParams): string {
    if (!params.value) return 'Present';

    // Create a new Date object from the string date
    const date = new Date(params.value);

    // Specify the locale and options for formatting
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    // Convert to local date string with specified options
    return date.toLocaleDateString('en-US', options);
  }

}
