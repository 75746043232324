<mat-card class="cardWithShadow theme-card">

  <mat-card-header>
    <mat-card-subtitle *ngIf="!searchDetails.isComplete" style="color: darkred">
      Search data is incomplete
    </mat-card-subtitle>
    <mat-card-title class="m-b-0">
      Update Search Details
    </mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>

  <mat-card-content class="b-t-1">

    <div *ngIf="showUpdateSuccess" class="update-success-message">
      {{ updateSuccessMessage }}
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="updateSearch()">

      <!-- Debugging Output -->
<!--      <pre>{{ searchDetails | json }}</pre> &lt;!&ndash; Display the input data &ndash;&gt;-->
<!--      <pre>{{ searchForm.value | json }}</pre> &lt;!&ndash; Display form state &ndash;&gt;-->

      <!-- Portfolio Company Dropdown -->
      <ng-container *ngIf="searchDetails && searchDetails.portfolioCompany">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Portfolio Company</mat-label>
          <input matInput [value]="searchDetails.portfolioCompany.name" readonly>
        </mat-form-field>
      </ng-container>

      <!-- Priority Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Priority</mat-label>
        <mat-select placeholder="Select Priority" formControlName="priority">
          <mat-option *ngFor="let priority of priorities" [value]="priority.viewValue">
            {{ priority.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('priority')?.invalid">Priority is required.</mat-error>
      </mat-form-field>

      <!-- Confidential Checkbox -->
      <mat-checkbox formControlName="confidential" color="primary">Confidential</mat-checkbox>

      <mat-form-field class="full-width">
        <mat-label>Job Function</mat-label>
        <input matInput [value]="jobClassificationDisplay" readonly>
      </mat-form-field>

      <!-- Job Title Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Job Title</mat-label>
        <mat-select placeholder="Select Job Title" formControlName="jobTitle">
          <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.viewValue">
            {{ jobTitle.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('jobTitle')?.invalid">Job Title is required.</mat-error>
      </mat-form-field>

      <!-- Trigger Date Picker -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Trigger Date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Pick a trigger date" formControlName="triggerDate" readonly>
        <!--        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="searchForm.get('triggerDate')?.invalid">Trigger Date is required.</mat-error>
      </mat-form-field>

      <!-- Trigger Reason -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Trigger Reason</mat-label>
        <mat-select placeholder="Select Trigger Reason" formControlName="triggerReason">
          <mat-option *ngFor="let triggerReason of triggerReasons" [value]="triggerReason.viewValue">
            {{ triggerReason.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('triggerReason')?.invalid">Trigger Reason is required.</mat-error>
      </mat-form-field>

      <!-- Trigger Note Input -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Trigger Note</mat-label>
        <input matInput placeholder="Enter Trigger Note" formControlName="triggerNote">
        <mat-error *ngIf="searchForm.get('triggerNote')?.invalid">Trigger Note is required.</mat-error>
      </mat-form-field>

      <!-- Remote Work Checkbox -->
      <mat-checkbox formControlName="remote" color="primary">Remote</mat-checkbox>

      <!-- Job Location Input -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Job Location</mat-label>
        <mat-select placeholder="Select Function Group" formControlName="jobLocation">
          <mat-option *ngFor="let jobLocation of locations" [value]="jobLocation.viewValue">
            {{ jobLocation.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('jobLocation')?.invalid">Job Location is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Budget Compensation</mat-label>
        <input matInput type="number" formControlName="budgetedCompensation">
      </mat-form-field>

      <!-- Engagement Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Engagement</mat-label>
        <mat-select placeholder="Select Engagement" formControlName="engagement">
          <mat-option *ngFor="let engagement of engagements" [value]="engagement.viewValue">
            {{ engagement.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('engagement')?.invalid">Engagement is required.</mat-error>
      </mat-form-field>

      <!-- Search Strategy Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Search Strategy</mat-label>
        <mat-select placeholder="Select Search Strategy" formControlName="searchStrategy">
          <mat-option *ngFor="let strategy of searchStrategies" [value]="strategy.viewValue">
            {{ strategy.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="searchForm.get('searchStrategy')?.invalid">Search Strategy is required.</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Status</mat-label>
        <input matInput formControlName="status" readonly>
      </mat-form-field>

      <!-- isSubSearchFirm Work Checkbox -->
      <mat-checkbox formControlName="isSubSearchFirm" color="primary">Sub Search Firm</mat-checkbox>

      <div *ngIf="showUpdateSuccess" class="update-success-message">
        {{ updateSuccessMessage }}
      </div>
      <!-- Submit Button -->
      <div class="form-actions">
        <button mat-flat-button class="submit-button" [disabled]="searchForm.invalid">Update Search</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
