import { Component, Input, OnInit } from '@angular/core';
import { Assessment } from '../../models/assessment.model';

@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrl: './assessment-list.component.scss'
})
export class AssessmentListComponent implements OnInit {

  @Input() assessments!: Assessment[];

  displayedColumns: string[] = ['profile', 'stage', 'searchProgress', 'rating', 'notes'];

  ngOnInit(): void {}

}
