import { Component } from '@angular/core';

@Component({
  selector: 'app-searches',
  templateUrl: './searches.component.html',
  styleUrl: './searches.component.scss'
})
export class SearchesComponent {

  openDialog(add: string, param2: {}) {
  }

  deleteTask() {
  }

}
