import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CryptoApiService} from "../services/crypto-api.service";
import {CryptoDataModel} from "../crypto-data.model";

@Component({
  selector: 'app-crypto-card',
  templateUrl: './crypto-card.component.html',
  styleUrls: ['./crypto-card.component.scss']
})
export class CryptoCardComponent implements OnInit {

  form: FormGroup;
  currentCryptoData: CryptoDataModel = {
    time: Date.now(),
    symbol: '',
    buy: '',
    sell: '',
    changeRate: '',
    changePrice: '',
    high: '',
    low: '',
    vol: '',
    volValue: '',
    last: '',
    averagePrice: '',
    takerFeeRate: '',
    makerFeeRate: '',
    takerCoefficient: '',
    makerCoefficient: ''
  };
  currentDate: string = '';
  symbolControl: FormControl = new FormControl('');
  availableSymbols: string[] = ['BTC-USDT', 'ETH-USDT', 'XRP-USDT', 'PEPE-USDT',
    'MYRO-USDT', 'ONDO-USDT', 'FTM-USDT', 'LINK-USDT'];

  // Properties for calculated values
  PP: number = 0;
  R1: number = 0;
  S1: number = 0;
  R2: number = 0;
  S2: number = 0;
  S3: number = 0;
  S4: number = 0;

  constructor(private fb: FormBuilder, private cryptoApiService: CryptoApiService) {
    this.form = this.fb.group({
      high: [0],
      low: [0],
      close: [0],
      symbol: this.symbolControl
    });

    // Set default value and fetch initial data
    this.symbolControl.setValue(this.availableSymbols[0], {emitEvent: true});
    console.log('Current Symbol:', this.symbolControl.value);
  }

  ngOnInit() {

    // Subscribe to symbol changes to fetch new data
    this.symbolControl.valueChanges.subscribe(newSymbol => {
      console.log('New Symbol Selected:', newSymbol);
      this.fetchData(this.symbolControl.value);
    });

    this.symbolControl.updateValueAndValidity();

  }

  fetchData(symbol: string) {
    this.cryptoApiService.getCurrentPrice(symbol).subscribe(data => {
      this.currentCryptoData = data;
      this.currentDate = new Date(data.time).toLocaleString();
      this.form.patchValue({
        high: parseFloat(data.high),
        low: parseFloat(data.low),
        close: parseFloat(data.last)
      });
      this.calculate();
    });
  }

  onInput(event: Event, field: keyof CryptoDataModel) {
    const inputElement = event.target as HTMLInputElement;
    // this.currentCryptoData[field] = inputElement.value as any;
  }

  calculate() {
    const high = parseFloat(this.currentCryptoData.high);
    const low = parseFloat(this.currentCryptoData.low);
    const close = parseFloat(this.currentCryptoData.last);

    this.PP = (high + low + close) / 3;
    this.R1 = (2 * this.PP) - low;
    this.S1 = (2 * this.PP) - high;
    this.R2 = this.PP + (high - low);
    this.S2 = this.PP - (high - low);
    this.S3 = low - 2 * (high - this.PP);
    this.S4 = this.S3 - (this.R2 - this.S2);
  }

}
