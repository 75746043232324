<div>
  <mat-card class="cardWithShadow">
    <mat-card-content class="text-center p-24">
<!--      <div class="m-b-0 f-w-1 fixed-height">-->
      <div class="m-b-0 f-w-1 fixed-height"> {{ search.jobTitle }} </div>
      <div class="m-b-0 f-c-1 fixed-height" style="font-size: calc(0.30vw + 9px); min-height: 40px">
        <span *ngIf="!isByCompany">
          {{ search.portfolioCompany.name }}
        </span>
        <span *ngIf="isByCompany">
          {{ search.status }}
        </span>
      </div>
      <div class="fixed-height">{{ search.searchStrategy }}</div>

      <div class="days-display">
        <div matTooltip="Days since created" style="color: #BBBBBB">
          <span class="number">{{ search.daysSinceCreated }}</span>
          <span class="label">{{ search.daysSinceCreated > 1 ? 'days' : 'day' }}</span>
        </div>
        <div class="days-display-divider">.</div> <!-- Vertical divider -->

        <div matTooltip="Days since in status"
             [ngClass]="{'green': search.daysInStatus <= 129, 'orange': search.daysInStatus >= 130 && search.daysInStatus <= 149, 'red': search.daysInStatus >= 150}">
          <span class="number">{{ search.daysInStatus }}</span>
          <span class="label">{{ search.daysInStatus > 1 ? 'days' : 'day' }}</span>
        </div>
      </div>

      <div class="fixed-height">
        <div>
          <div style="font-size: calc(0.15vw + 6px); padding: 1%">Lead Candidate:</div>
          <span class="number f-w-1 f-c-1" style="font-size: calc(0.30vw + 9px);">
<!--                [matTooltip]="topCandidate"-->
            {{ search.leadCandidate }}
          </span>
<!--          <span class="number" style="font-size: calc(0.20vw + 7px);">Wells Fargo</span>-->
        </div>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <div class="icon-row">
      <div class="icon-container" (click)="openCreateEmailDialog()">
        <i class="fa-solid fa-envelope" matTooltip="Send Email"></i>
      </div>
      <div>
        <i class="fa fa-clipboard-list" matTooltip="Open 'Search Prep'"  (click)="goToSearchPrep()"></i>
      </div>
      <div class="icon-container" (click)="op.toggle($event)">
        <i class="fa-solid fa-list" matTooltip="To-Do List" [ngClass]="{'red': search.id === 1}">
          <p-overlayPanel #op>
            <div>
              <app-todo-list></app-todo-list>
            </div>
          </p-overlayPanel>
        </i>
      </div>
      <div class="icon-container">
        <i class="fa-solid fa-user-plus" matTooltip="Add Contact" (click)="openCreateContactDialog()"></i>
      </div>
<!--      <div class="icon-container">-->
<!--        <i class="fa-sharp fa-solid fa-thumbtack"></i>-->
<!--      </div>-->
      <!-- Using Angular's ngClass for dynamic styling based on condition -->
      <ng-container *ngIf="canBePinned(search.id); else pinnedIcon">
        <mat-icon class="icon" matTooltip="Pin Search"
                  (click)="updatePinnedSearch(search.id, true)">
          push_pin
        </mat-icon>
      </ng-container>
      <ng-template #pinnedIcon>
        <mat-icon class="icon" [ngStyle]="{'color': '#009CFF', 'opacity': '0.9'}">
          push_pin_off
        </mat-icon>
      </ng-template>

    </div>
  </mat-card>
</div>
