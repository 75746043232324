export const TRIGGER_REASONS = [
  { value: 'EXEC_TRANSITION_OUT_AT_DEAL_CLOSE', viewValue: 'Exec transition out at deal close' },
  { value: 'COMPANY_TERMINATED_EXECUTIVE', viewValue: 'Company terminated executive' },
  { value: 'NEW_POSITION', viewValue: 'New position' },
  { value: 'EXEC_RESIGNATION_REGRETTABLE', viewValue: 'Exec resignation - Regrettable' },
  { value: 'EXEC_RESIGNATION_UNREGRETTABLE', viewValue: 'Exec resignation - Unregrettable' },
  { value: 'REORGANIZATION', viewValue: 'Reorganization' },
  { value: 'EXEC_RETIREMENT', viewValue: 'Exec retirement' },
  { value: 'EXEC_DEATH', viewValue: 'Exec death' }
];
