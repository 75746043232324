import { Component } from '@angular/core';

@Component({
  selector: 'app-trading-dashboard',
  templateUrl: './trading-dashboard.component.html',
  styleUrl: './trading-dashboard.component.scss'
})
export class TradingDashboardComponent {

  cryptoData = [
    { high: 0.0000104, low: 0.00000986, close: 0.00001018 },
    // Add more data as needed
  ];
}
