import { Injectable } from '@angular/core';
import {InterviewGuide} from "../models/interview-guide.model";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InterviewGuideService {

  constructor() {}

  getInterviewGuides(): Observable<InterviewGuide[]> {
    // This should be replaced with an actual HTTP request
    const guides: InterviewGuide[] = [
      {
        category: "Interviewer: Search Firm",
        rating: 3.9,
        completed: true,
        questions: [
          {
            criteria: "Describe your experience, including the business type, its revenue, and whether it was in a growth or turnaround phase.",
            description: "Review resumes and align with PE on candidate quality.",
            minimumRatingNecessary: 5,
            interviewer: ["Search firm"],
            interviewerNotes: "Strong ethical grounding and integrity.",
            critical: '5 years',
            important: '10 years'
          },
          {
            criteria: "Describe your experience, including the business type, its revenue, and whether it was in a growth or turnaround phase.",
            description: "Discuss budgeting, financial reporting, and cost control.",
            minimumRatingNecessary: 4,
            interviewer: ["Search firm", "CFO"],
            interviewerNotes: "Look for evidence of innovative cost-saving measures.",
            critical: "B2B, $70M+, high-growth"
          },
          {
            criteria: "What is the size of the organization that you were responsible for managing? What management challenges did you face and how did you address them?",
            description: "Discuss budgeting, financial reporting, and cost control.",
            minimumRatingNecessary: 4,
            interviewer: ["Search firm", "CFO"],
            interviewerNotes: "Look for evidence of innovative cost-saving measures.",
            critical: "50+ person organization"
          }
        ]
      },
      {
        category: "Interviewer: CEO",
        rating: 2.5,
        completed: true,
        questions: [
          {
            criteria: "What roles have you held within the EdTech space and which segments and markets of EdTech have you operated in?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics.",
            important: "Assessment & Analytics",
            beneficial: "K12"
          },
          {
            criteria: "What experience do you have with M&A? Can you discuss a significant M&A project you were involved in?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Have you worked for a private equity-backed company? What were your responsibilities and achievements in that role?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          }
        ]
      },
      {
        category: "Interviewer: HC VP",
        // rating: 3.8,
        questions: [
          {
            criteria: "Caliper that demonstrates high potential for success in the role, including; strong problem solving, sense of " +
              "urgency balanced with data orientation, accomplishment-driven mindset, balanced accommodation and ego strength",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          }
        ]
      },
      {
        category: "Interviewer: Technical Interviewer",
        // rating: 4.5,
        questions: [
          {
            criteria: "Explain your expertise in FP&A. How have you applied this in your previous roles?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Describe a time when you transformed a finance organization. What strategies did you use and what was the outcome?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Can you share an example of a plan you created and executed to optimize financial performance? What were the results?",
            description: "45-minute initial assessment and cultural fit.",
            minimumRatingNecessary: 3,
            interviewer: ["CEO"],
            interviewerNotes: "Assessment & Analytics."
          }
        ]
      },
      {
        category: "Interviewer: Executive Chair",
        // rating: 3.2,
        questions: [
          {
            criteria: "Can you describe a time when you faced a decision where the best interest of the company conflicted with personal gain or convenience? How did " +
              "you approach that situation, and what was the outcome?B: When's the last time you broke the rules at work in the best interest of the company?",
            description: "Strong ethical grounding and integrity – trusted to make decisions in the best interest of the company",
            minimumRatingNecessary: 3,
            interviewer: ["Executive Chair"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "How would you describe CLTV/CAC to a non-financial stakeholder?",
            description: "Strong communication skills – able to convey financial information clearly to non-financial stakeholders",
            minimumRatingNecessary: 3,
            interviewer: ["Executive Chair"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Can you describe a situation where you anticipated a financial challenge or opportunity? How did you prepare for it, and what was the outcome of your actions?",
            description: "Strategic thinker with a forward-looking vision – anticipates future financial challenges and opportunities",
            minimumRatingNecessary: 3,
            interviewer: ["Executive Chair"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Describe a situation where you had to quickly pivot strategies due to unexpected challenges? What was your approach, and what was the outcome?",
            description: "Adaptable and flexible – able to pivot strategies and approaches in a dynamic environment",
            minimumRatingNecessary: 3,
            interviewer: ["Executive Chair"],
            interviewerNotes: "Assessment & Analytics."
          },
          {
            criteria: "Share an example of how you've managed to collaborate effectively with senior executives while also demonstrating independent decision-making in your previous roles?",
            description: "Balances collaborative and independent style – can be a thought partner and work productively with the executive team but also able to take limited direction to drive decisions",
            minimumRatingNecessary: 3,
            interviewer: ["Executive Chair"],
            interviewerNotes: "Assessment & Analytics."
          }
        ]
      },
      {
        category: "Interviewer: CEO",
        // rating: 2.7,
        topics: [
          {
            name: "In-Depth Discussion:",
            description: "Deeply explore the candidate's relevant experiences."
          },
          {
            name: "Strategic Alignment:",
            description: "Evaluate the candidate's strategic vision for the finance function."
          },
          {
            name: "Problem-Solving and Decision-Making:",
            description: "Assess problem-solving and decision-making skills with real-world scenarios."
          },
          {
            name: "Feedback and Reflection:",
            description: "Reflect on feedback from previous interview stages."
          },
          {
            name: "Final Cultural and Team Fit:",
            description: "Ensure cultural and team fit."
          },
          {
            name: "Commitment and Expectations:",
            description: "Clarify mutual expectations and readiness to commit."
          }
        ]
      }
    ];
    return of(guides);
  }

}
