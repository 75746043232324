import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {SearchContact} from "../models/search-contact.model";

@Injectable({
  providedIn: 'root'
})
export class SearchContactService {

  private apiUrl = environment.apiUrl + '/search-contacts';

  constructor(private http: HttpClient) { }



  getSearchesFor(contactId: number): Observable<SearchContact[]> {
    return this.http.get<SearchContact[]>(this.apiUrl + '/contact/' + contactId + '/searches');
  }

  getSearchContactTopCandidates(searchId: number): Observable<SearchContact> {
    return this.http.get<SearchContact>(this.apiUrl + '/top-candidates/' + searchId);
  }

  addSearchContact(searchContact: any): Observable<SearchContact> {
    return this.http.post<SearchContact>(`${this.apiUrl}`, searchContact);
  }

  updateSearchContact(searchContact: SearchContact): Observable<SearchContact> {
    return this.http.put<SearchContact>(`${this.apiUrl}/${searchContact.id}`, searchContact);
  }

  getPlacement(searchContacts: SearchContact[]) {

    if (searchContacts && searchContacts.length > 0) {
      let searchProfile = searchContacts.filter(searchContact => searchContact.offerStatus === 'Accepted')[0];
      console.log(searchProfile.contact?.firstName)
      return searchProfile.contact?.firstName + ' ' + searchProfile.contact?.lastName;
    }

    return '';
  }
}
