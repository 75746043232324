import { Injectable } from '@angular/core';
import {TodoItem} from "../models/to-do-item.model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  private todos: TodoItem[] = [
    { id: 1, description: 'Source candidates', completed: true },
    { id: 2, description: 'Conduct reference checks', completed: true },
    { id: 3, description: 'Backchannel lead candidate', completed: false },
    { id: 4, description: 'Review matrix edits made by Kevin', completed: false }
  ];
  private todosSubject = new BehaviorSubject<TodoItem[]>(this.todos);

  getTodos(): Observable<TodoItem[]> {
    return this.todosSubject.asObservable();
  }

  addTodo(description: string): void {
    const newTodo: TodoItem = { id: this.todos.length + 1, description: description, completed: false };
    this.todos.push(newTodo);
    this.todosSubject.next(this.todos);
  }

  toggleTodoComplete(todoId: number): void {
    const todo = this.todos.find(t => t.id === todoId);
    if (todo) {
      todo.completed = !todo.completed;
      this.todosSubject.next(this.todos);
    }
  }
}
