<div style="margin-left: 2%; padding: .5%; min-height: 1200px; margin-bottom: 3%">
  <div class="search-management-container">

    <app-search-summary [search]="searchDetails"></app-search-summary>

    <!-- Main Content Area -->
    <div class="content-area" style="background-color: #EFEFEF;">
      <!-- Left Section: Contacts List -->
      <div class="contacts-list">
        <app-search-contacts [search]="searchDetails"></app-search-contacts>
      </div>
      <!-- Right Section: Edit Search Form -->
      <div class="edit-search" style="background-color: #FFFFFF; border-radius: 10px; margin-right: 2%">
        <div style="flex: 3;">
          <mat-tab-group mat-stretch-tabs>
            <mat-tab label="Search Stakeholders">
              <app-interview-stakeholders [searchDetails]="searchDetails"></app-interview-stakeholders>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <div style="display: flex; align-items: center;">
                  <span>Search Details</span>
                  <!-- Conditionally display a red circle if the search is not complete -->
                  <mat-icon *ngIf="!searchDetails.isComplete" class="red-circle">fiber_manual_record</mat-icon>
                </div>
              </ng-template>

              <app-update-search [searchDetails]="searchDetails"></app-update-search>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
