import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CryptoDataModel} from "../crypto-data.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CryptoApiService {

  // private apiUrl = 'https://api.kucoin.com/api/v1/market/stats?symbol=BTC-USDT';

  constructor(private http: HttpClient) { }

  getCurrentPrice(symbol: string): Observable<CryptoDataModel> {
    const apiUrl = `https://api.kucoin.com/api/v1/market/stats?symbol=${symbol}`;
    return this.http.get<{ code: string; data: CryptoDataModel }>(apiUrl).pipe(
      map(response => response.data)
    );
  }

}
