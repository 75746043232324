import {Component, Input, OnInit} from '@angular/core';
import {EmploymentDetails} from "../../models/employment-details.model";
import {CellClickedEvent, GridOptions, ValueFormatterParams} from "ag-grid-community";
import {EmploymentDetailsService} from "../../services/employment-details.service";
import {Router} from "@angular/router";
import {PortfolioCompany} from "../../models/portfolio-company.model";

@Component({
  selector: 'app-portfolio-company-employees',
  templateUrl: './portfolio-company-employees.component.html',
  styleUrl: './portfolio-company-employees.component.scss'
})
export class PortfolioCompanyEmployeesComponent implements OnInit {

  @Input() portfolioCompanyId!: number;

  public rowData: EmploymentDetails[] = [];
  public columnDefs: any[] = [];
  public defaultColDef: any;
  public gridOptions: GridOptions = {};

  constructor(
    private employmentDetailsService: EmploymentDetailsService,
    private router: Router
  ) {
    this.columnDefs = [
      { headerName: 'Job Title', field: 'positionHeld', minWidth: 120 },
      { headerName: 'Employee Name', field: 'userProfile.firstName', minWidth: 180,
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          if (params.data.userProfile && params.data.userProfile.firstName && params.data.userProfile.lastName) {
            return `${params.data.userProfile.firstName} ${params.data.userProfile.lastName}`;
          }
          return ''; // Return empty string if data is incomplete
        },
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToContactPage(event),
      },
      { headerName: 'Email', field: 'userProfile.email', minWidth: 220},
      { headerName: 'Start Date', field: 'startDate', valueFormatter: this.formatDate },
      { headerName: 'End Date', field: 'endDate', valueFormatter: this.formatDate }
    ];

    this.defaultColDef = {
      flex: 1,
      filter: true,
      resizable: true
    };
  }

  ngOnInit(): void {
    this.fetchData(this.portfolioCompanyId);
  }

  fetchData(portfolioCompanyId: number): void {
    this.employmentDetailsService.getPortfolioEmployeesBy(portfolioCompanyId).subscribe({
      next: data => {
        // console.log("portfolioCompany emp :: ", data)
        this.rowData = data;
      },
      error: err => console.error('Failed to load contact engagements:', err)
    });
  }

  goToContactPage(event: any) {
    this.router.navigate(['/home/contact', event.data.userProfile.id]);
  }

  formatDate(params: ValueFormatterParams): string {
    if (!params.value) return 'Present';

    // Create a new Date object from the string date
    const date = new Date(params.value);

    // Specify the locale and options for formatting
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    // Convert to local date string with specified options
    return date.toLocaleDateString('en-US', options);
  }

}
