import { Component, Input, OnInit } from '@angular/core';
import { PortfolioCompany } from "../../models/portfolio-company.model";
import { UserProfileService } from "../../services/user-profile.service";
import { UserProfile } from "../../models/user-profile.model";
import { PortfolioManagement } from "../../models/portfolio-management.model";
import {PortfolioManagementService} from "../../services/portfolio-management.service";

@Component({
  selector: 'app-portfolio-company-info',
  templateUrl: './portfolio-company-info.component.html',
  styleUrls: ['./portfolio-company-info.component.scss']
})
export class PortfolioCompanyInfoComponent implements OnInit {
  @Input() portfolioCompany!: PortfolioCompany;
  public userProfiles!: UserProfile[];
  public selectedUserIds: {[key: string]: number | undefined} = {};
  public selectedUserIsStakeholderValues: {[key: string]: boolean | undefined} = {};

  public roles = ['GP', 'PVP', 'Associate', 'VCO', 'HC', 'BD', 'CEO', 'Executive Chair'];
  public categorizedProfiles: {[key: string]: UserProfile[]} = {};

  isStakeholderGP: undefined | boolean = false;
  isStakeholderPVP: undefined | boolean = false;
  isStakeholder: boolean = false;

  constructor(
    private userProfileService: UserProfileService,
    private portfolioManagementService: PortfolioManagementService
  ) {}

  ngOnInit(): void {
    this.fetchUserProfiles();
  }

  fetchUserProfiles(): void {
    this.userProfileService.getUserProfilesBy(this.portfolioCompany.id).subscribe({
      next: (profiles) => {
        this.userProfiles = profiles;
        this.roles.forEach(role => {
          this.categorizedProfiles[role] = profiles.filter(profile => profile.jobTitle === role);
          const selectedProfile = profiles.find(profile => profile.jobTitle === role && profile.portfolioManagement);
          if (selectedProfile) {
            this.selectedUserIds[role] = selectedProfile.id;
            this.selectedUserIsStakeholderValues[role] = selectedProfile.portfolioManagement?.isStakeholder;
          }
        });
      },
      error: err => console.error('Failed to load user profiles:', err)
    });
  }

  onProfileSelectionChange(profileId: any): void {

    const selectedProfile = this.userProfiles.find(profile => profile.id === profileId);
    console.log(this.selectedUserIds['GP'])

    // if (selectedProfile && selectedProfile.jobTitle && selectedProfile.portfolioManagement) {
    //   this.updatePortfolioManagement(false, selectedProfile.jobTitle, selectedProfile.id, true);
    //   this.selectedUserIsStakeholderValues[selectedProfile.jobTitle] = selectedProfile.portfolioManagement.isStakeholder;
    //
    // } else

    if (selectedProfile && selectedProfile.jobTitle) {
      this.updatePortfolioManagement(false, selectedProfile.jobTitle, selectedProfile.id, false);
      this.selectedUserIsStakeholderValues[selectedProfile.jobTitle] = false;
    }

  }

  updatePortfolioManagement(isStakeholder: boolean, role: string, userProfileId: any, isNew: boolean) {

    const userId = this.selectedUserIds[role];

    if (!userId) {
      console.error('No user ID selected for role:', role);
      isNew = true;
    }

    const portfolioManagementUpdate: PortfolioManagement = {
      isStakeholder: isStakeholder,
      isNew: isNew,
      jobTitle: role,
      userProfileId: userId || userProfileId,
      portfolioCompanyId: this.portfolioCompany.id
    };

    console.log('portfolioManagementUpdate :: ', portfolioManagementUpdate)
    // Call service to update the database
    this.portfolioManagementService.updatePortfolioManagement(1, portfolioManagementUpdate).subscribe({
      next: (response) => {
        console.log('Updated successfully:', response);
        this.fetchUserProfiles();  // Refresh data
      },
      error: (error) => console.error('Failed to update stakeholder status:', error)
    });
  }
}
