import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EmploymentDetails} from "../models/employment-details.model";

@Injectable({
  providedIn: 'root'
})
export class EmploymentDetailsService {

  private apiUrl = environment.apiUrl + '/employment-details';

  constructor(private http: HttpClient) { }

  getPortfolioEngagementBy(userProfileId: number): Observable<EmploymentDetails[]> {
    let url = `${this.apiUrl}/user-profile/${userProfileId}`;
    return this.http.get<EmploymentDetails[]>(url);
  }

  getAllContactEmploymentDetails(): Observable<EmploymentDetails[]> {
    return this.http.get<any>(this.apiUrl);
  }

  getPortfolioEmployeesBy(portfolioCompanyId: any) {
    let url = `${this.apiUrl}/portfolio-company/${portfolioCompanyId}`;
    return this.http.get<EmploymentDetails[]>(url);
  }

  getPortfolioBoardMembers() {
    return this.http.get<EmploymentDetails[]>(this.apiUrl + '/portfolio-board-member');
  }
}
