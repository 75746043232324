<section class="auth-container">
  <div class="auth-form">
    <h2>Login to Your Account</h2>
    <p *ngIf="loginError" class="error-text">{{ loginError }}</p>
    <form (submit)="login(); $event.preventDefault()">
      <div class="input-group">
        <label for="email">Email</label>
        <input type="email" [(ngModel)]="email" name="email" id="email" placeholder="example.email@gmail.com" required>
      </div>
      <div class="input-group">
        <label for="password">Password</label>
        <input type="password" [(ngModel)]="password" name="password" id="password"
               placeholder="Enter at least 8+ characters" required>
      </div>
      <button type="submit" class="btn-submit">Sign In</button>
      <p class="auth-option-text">Or sign in with</p>
      <div class="auth-options">
        <button type="button" class="btn-auth google" (click)="redirectToGoogle()">
          <i class="pi pi-google"></i>
        </button>
        <button type="button" class="btn-auth linkedin" (click)="redirectToLinkedIn()">
          <i class="pi pi-linkedin"></i>
        </button>
        <button type="button" class="btn-auth microsoft" (click)="redirectToMicrosoft()">
          <i class="pi pi-microsoft"></i> <!-- Replace Apple with Microsoft icon -->
        </button>
      </div>
    </form>
  </div>
  <div class="promo-section">
    <div class="promo-content">
      <h2>Meet <br><span class="highlight">PULSEHUB</span></h2>
      <p>We are building the first
        <span class="highlight-red">AI-native PE HCM</span>
        rethinking data management for private equity.
      </p>
    </div>
  </div>
</section>
