import { Component, OnInit } from '@angular/core';
import { InterviewGuideService } from "../../services/interview-guide.service";
import { InterviewGuide } from "../../models/interview-guide.model";

@Component({
  selector: 'app-interview-guides',
  templateUrl: './interview-guides.component.html',
  styleUrls: ['./interview-guides.component.scss']
})
export class InterviewGuidesComponent implements OnInit {

  interviewGuides: InterviewGuide[] = [];

  constructor(private interviewGuideService: InterviewGuideService) {}

  ngOnInit(): void {
    this.interviewGuideService.getInterviewGuides().subscribe(guides => {
      this.interviewGuides = guides;
    });
  }

  protected readonly top = top;
}
