import {Component, Input} from '@angular/core';
import {Contact} from "../../models/contact.model";
import {SearchContact} from "../../models/search-contact.model";
import {SearchContactService} from "../../services/search-contact.service";

@Component({
  selector: 'app-portfolio-engagement',
  templateUrl: './portfolio-engagement.component.html',
  styleUrl: './portfolio-engagement.component.scss'
})
export class PortfolioEngagementComponent {

  @Input() contact!: Contact;
  dataSource: SearchContact[] = [];
  columnsToDisplay = ['status', 'portfolio_company', 'job_title', 'rating', 'dates'];
  expandedElement: SearchContact | null = null;

  constructor(private searchContactService: SearchContactService) {}

  ngOnInit(): void {

    console.log("Contact data in parent:", this.contact);


    if (this.contact) {
      this.loadContacts(this.contact.id);
    }
  }

  ngOnChanges(): void {
    if (this.contact) {
      this.loadContacts(this.contact.id);
    }
  }

  private loadContacts(contactId: number): void {
    this.searchContactService.getSearchesFor(contactId).subscribe({
      next: (contacts) => {
        this.dataSource = contacts;
      },
      error: (error) => console.error('Failed to load contacts', error)
    });
  }

  toggleRow(event: MouseEvent, element: SearchContact) {
    event.stopPropagation(); // This stops the event from bubbling up to the row
    this.expandedElement = this.expandedElement === element ? null : element;
  }

}
