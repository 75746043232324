<div class="interview-guides-container">
  <mat-accordion multi="true">
    <mat-expansion-panel *ngFor="let guide of interviewGuides" [ngClass]="{'completed' : guide.completed}">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div style="color: #999999;">
            <div style="color: #666666;"> {{ guide.category }} </div>
            <div> {{ guide.questions ?  guide?.questions?.length + ' questions' : guide?.topics?.length + ' topics' }}</div>
            <div *ngIf="guide.rating" [ngClass]="{'green' : guide.rating >= 3, 'red': guide.rating < 3}"> {{ guide.rating }} / 4.0</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let question of guide.questions">
        <mat-divider></mat-divider>
        <div *ngIf="question" style="padding-top: 1%; padding-bottom: 0.25%">
          <div>{{ question.criteria }}</div>
          <span *ngIf="question.description" style="color: #999999"> {{ question.description }}</span>
          <span *ngIf="question.critical" style="color: #999999"><strong>Criteria:</strong> {{ question.critical }}</span>
          <span *ngIf="question.important" style="color: #999999"><strong>Important:</strong> {{ question.important }}</span>

<!--          <p>{{ question.description }}</p>-->
<!--          <p><strong>Minimum Rating Necessary:</strong> {{ question.minimumRatingNecessary }}</p>-->
<!--          <p><strong>Interviewer(s):</strong> {{ question.interviewer }}</p>-->
<!--          <p><strong>Notes:</strong> {{ question.interviewerNotes }}</p>-->
        </div>
      </ng-container>

      <ng-container *ngFor="let topic of guide.topics">
        <mat-divider></mat-divider>
        <div *ngIf="topic" style="padding-top: 1%; padding-bottom: 0.25%">

          <li *ngIf="topic.name">{{ topic.name }}</li>
          <span *ngIf="topic.description" style="color: #999999"> {{ topic.description }}</span>
        </div>
      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>
</div>
