<div style="margin-left: 2.5%; height: auto; padding-top: .5%;">

  <ng-container *ngIf="portfolioCompany">
    <app-portfolio-company-summary [portfolioCompany]="portfolioCompany"></app-portfolio-company-summary>
  </ng-container>

  <div style="padding-right: 2%; margin-top: 2%;">
    <mat-tab-group mat-stretch-tabs style="background-color: #FFFFFF; border-radius: 10px; margin-bottom: 10%">
      <mat-tab label="Company Info">
        <ng-container *ngIf="portfolioCompany">
          <app-portfolio-company-info [portfolioCompany]="portfolioCompany"></app-portfolio-company-info>
        </ng-container>
      </mat-tab>
      <mat-tab label="Employees">
        <ng-container *ngIf="portfolioCompany">
          <app-portfolio-company-employees [portfolioCompanyId]="portfolioCompany.id"></app-portfolio-company-employees>
        </ng-container>
      </mat-tab>
      <mat-tab label="Searches">
        <ng-container *ngIf="portfolioCompany">
          <app-portfolio-company-searches [portfolioCompanyId]="portfolioCompany.id"></app-portfolio-company-searches>
        </ng-container>
      </mat-tab>
      <mat-tab label="VCIs">
        <!-- Content for Tab 4 -->
      </mat-tab>
      <mat-tab label="Documents">
        <!-- Content for Tab 4 -->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
