import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PortfolioCompanyService} from "../../services/portfolio-company.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortfolioCompany} from "../../models/portfolio-company.model";
import {LOCATIONS} from "../../constants/locations.constants";
import {FUNCTION_GROUPS} from "../../constants/function-group.constants";
import {FUNDING_ROUND} from "../../constants/funding-round.constants";
import {PORTFOLIO_STATUSES} from "../../constants/portfolio-status.constants";
import {PORTFOLIO_INDUSTRIES} from "../../constants/portfolio-industry.constants";

@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrl: './create-company-dialog.component.scss'
})
export class CreateCompanyDialogComponent implements OnInit {

  @Output() dialogClose = new EventEmitter<{ success: boolean, message: string }>();

  companyForm!: FormGroup;
  fundingRounds = FUNDING_ROUND;
  statuses = PORTFOLIO_STATUSES;
  industries = PORTFOLIO_INDUSTRIES;

  constructor(
    private fb: FormBuilder,
    private portfolioCompanyService: PortfolioCompanyService,
    public dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { portfolioCompany: PortfolioCompany }
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.companyForm = this.fb.group({
      name: ['', Validators.required], // string - input
      fundingRound: ['', []], // list of strings - dropdown
      portfolioStatus: ['', []], // list of strings - dropdown
      portfolioIndustry: ['', []], // list of string - dropdown
      dealCloseDate: ['', Validators.required], // date
      employeeCount: ['', Validators.required], // number - input
      fillBoardAtInvestment: [''], // boolean
      companyRevenue: [''], // number - input
    });
  }

  onSubmit() {
    if (this.companyForm.valid) {
      // Prepare the company data to include the investmentFirm field
      const companyData = {
        ...this.companyForm.value,
        investmentFirm: {
          id: 2
        }
      };

      // Call the service method to create the company
      this.portfolioCompanyService.createPortfolioCompany(companyData).subscribe({
        next: (response) => {
          console.log('Company created successfully:', response);
          this.dialogRef.close({success: true, message: 'Company created successfully'});
          this.dialogClose.emit({success: true, message: 'Company created successfully'});
        },
        error: (error) => {
          console.error('Failed to create company', error);
          this.dialogRef.close({success: false, message: 'Failed to create company'});
          this.dialogClose.emit({success: false, message: 'Failed to create company'});
        }
      });
    } else {
      console.log('Form is not valid');
      this.dialogRef.close({success: false, message: 'Invalid form data'});
      this.dialogClose.emit({success: false, message: 'Invalid form data'});
    }
  }

}
