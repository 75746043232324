<mat-card class="cardWithShadow theme-card">
  <mat-card-header>
    <mat-card-title class="m-b-0">Create New Contact</mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>

  <mat-card-content class="b-t-1">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName">
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
      </mat-form-field>

      <!-- Job Location Input -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Location</mat-label>
        <mat-select placeholder="Select Location" formControlName="address">
          <mat-option *ngFor="let address of locations" [value]="address.viewValue">
            {{ address.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="contactForm.get('jobLocation')?.invalid">Address is required.</mat-error>
      </mat-form-field>

      <!-- Job Title Dropdown -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Job Title</mat-label>
        <mat-select placeholder="Select Job Title" formControlName="jobTitle">
          <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.value">
            {{ jobTitle.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="contactForm.get('jobTitle')?.invalid">Job Title is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="contactForm.get('email')?.hasError('required')">
          Email is required.
        </mat-error>
        <mat-error *ngIf="contactForm.get('email')?.hasError('email')">
          Please enter a valid email.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phoneNumber">
        <mat-error *ngIf="contactForm.get('phoneNumber')?.hasError('required')">
          Phone number is required.
        </mat-error>
        <mat-error *ngIf="contactForm.get('phoneNumber')?.hasError('pattern')">
          Please enter a valid phone number.
        </mat-error>
      </mat-form-field>

      <!-- Portfolio Company -->
      <mat-form-field appearance="outline" class="full-width" *ngIf="!searchPortfolioCompany">
        <mat-label>Portfolio Company</mat-label>
        <mat-select placeholder="Select Portfolio Company" formControlName="portfolioCompany">
          <mat-option *ngFor="let company of portfolioCompanies" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="contactForm.get('portfolioCompany')?.invalid">Portfolio Company is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width" *ngIf="searchPortfolioCompany">
        <mat-label>Portfolio Company</mat-label>
        <input matInput [value]="searchPortfolioCompany" readonly>
      </mat-form-field>

      <!-- Submit button -->
      <div class="form-actions">
        <button mat-flat-button class="submit-button" [disabled]="contactForm.invalid">Create</button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
