import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SearchContactService } from "../../services/search-contact.service";
import { SearchContact } from "../../models/search-contact.model";
import { Contact } from "../../models/contact.model";
import {UserProfile} from "../../models/user-profile.model";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {TableRowCollapseEvent, TableRowExpandEvent} from "primeng/table";
import {Assessment} from "../../models/assessment.model";

@Component({
  selector: 'app-contact-searches-expandable-table',
  templateUrl: './contact-searches-expandable-table.component.html',
  styleUrls: ['./contact-searches-expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContactSearchesExpandableTableComponent implements OnInit {

  @Input() userProfile!: UserProfile;
  dataSource: PortfolioSearchEngagement[] = [];
  columnsToDisplay = ['status', 'search', 'stage', 'search_progress', 'rating', 'lead', 'last_active_date'];
  // columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: SearchContact | null = null;
  expandedRows = {};

  constructor(
    private portfolioSearchEngagementService: PortfolioSearchEngagementService
  )
  {}

  ngOnInit(): void {
    if (this.userProfile) {
      this.loadContacts(this.userProfile.id);
    }
  }

  ngOnChanges(): void {
    if (this.userProfile) {
      this.loadContacts(this.userProfile.id);
    }
  }

  private loadContacts(userProfileId: number): void {
    this.portfolioSearchEngagementService.getSearchesFor(userProfileId).subscribe({
      next: (response) => {
        console.log("RESPONSE :: ", response)
        this.dataSource = response;
      },
      error: (error) => console.error('Failed to load contacts', error)
    });
  }

  toggleRow(event: MouseEvent, element: SearchContact) {

    // console.log('Current expanded element:', this.expandedElement);
    // console.log('Element to potentially expand:', element);
    event.stopPropagation(); // This stops the event from bubbling up to the row
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  isExpansionDetailRow = (i: number, row: SearchContact) => row === this.expandedElement;

  expandAll() {
    // this.expandedRows = this.products.reduce((acc, p) => (acc[p.id] = true) && acc, {});
  }

  collapseAll() {
    // this.expandedRows = {};
  }

  onRowExpand(event: TableRowExpandEvent) {
    // this.messageService.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    // this.messageService.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  }

  getCandidateProgress(assessments: Assessment[]) {
    if (assessments.length === 0) return undefined;
    return assessments[assessments.length - 1].candidateStage;
  }

  getSearchProgress(assessments: Assessment[]) {
    if (assessments.length === 0) return undefined;
    return assessments[assessments.length - 1].searchProgress;
  }

  getTempRating(assessments: Assessment[]) {
    if (assessments.length === 0) return undefined;
    return assessments[assessments.length - 1].assessmentRating;
  }
}
