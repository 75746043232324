import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {UserProfile} from "../models/user-profile.model";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private apiUrl = environment.apiUrl + '/user-profiles';
  private babylonUrl = environment.babylonUrl + '/rest';

  constructor(private http: HttpClient) { }

  addUserProfile(userProfile: any): Observable<UserProfile> {
    return this.http.post<UserProfile>(this.apiUrl, userProfile);
  }

  getSuggestedContact(searchId: number): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(this.apiUrl + '/' + searchId + '/suggested-contacts');
  }

  getUserProfileById(id: number): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.apiUrl + '/' + id);
  }

  getUserProfilesByInvestmentFirm(investmentFirmId: number) {
    return this.http.get<UserProfile[]>(this.apiUrl + '/investment-firm/' + investmentFirmId);
  }

  getUserProfilesBy(portfolioCompanyId: number): Observable<UserProfile[]> {
    // return this.http.get<UserProfile[]>(`${this.apiUrl}/by-type/${types.join(',')}`);
    return this.http.get<UserProfile[]>(this.apiUrl + '/user-profiles/with-management/' + portfolioCompanyId);
  }

  /*ogin(email: string, password: string): Observable<any> {
    const loginPayload = { email, password };  // Plain text password
    // Use HttpClient to make the POST request to your backend
    return this.http.post('http://localhost:8099/rest/system-login', loginPayload);
  }*/

  login(email: string, password: string): Observable<UserProfile> {
    const loginPayload = { email, password };
    return this.http.post<UserProfile>( this.babylonUrl + '/profile/auth', loginPayload, { withCredentials: true });
  }

  getUserProfile() {
    return this.http.get<UserProfile>(this.babylonUrl + '/session/user-profile', { withCredentials: true });
  }

}
