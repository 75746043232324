import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserProfileService} from "../../services/user-profile.service";
import {Contact} from "../../models/contact.model";
import {UserProfile} from "../../models/user-profile.model";

@Component({
  selector: 'app-show-contact',
  templateUrl: './show-contact.component.html',
  styleUrl: './show-contact.component.scss'
})
export class ShowContactComponent {

  userProfile!: UserProfile;

  constructor(
    private route: ActivatedRoute,
    private contactService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.loadContactDetails(+id);
      }
    });
  }

  private loadContactDetails(id: number): void {
    this.contactService.getUserProfileById(id).subscribe({
      next: (userProfile) => {
        this.userProfile = userProfile;
        // console.log('Show Contact :: ', this.contact)
      },
      error: (error) => {
        console.error('Failed to load contact details:', error);
      }
    });
  }

}
