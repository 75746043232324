import {Component, Input} from '@angular/core';
import {PortfolioCompany} from "../../models/portfolio-company.model";

@Component({
  selector: 'app-portfolio-company-summary',
  templateUrl: './portfolio-company-summary.component.html',
  styleUrl: './portfolio-company-summary.component.scss'
})
export class PortfolioCompanySummaryComponent {

  isCollapsed = false;
  @Input() portfolioCompany!: PortfolioCompany;

  constructor() {}

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

}
