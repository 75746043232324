<div class="stats-card">
<!--  <mat-icon>{{ icon }}</mat-icon>-->
  <div class="stats-values">
    <div *ngIf="headerValue" style="align-content: center; height: 5vw">
      <div class="value-with-header"
           [ngStyle]="{'color': headerValueFontColor || fontColor }"
           matTooltip="KPI Target">
        {{ headerValue }}
      </div>
      <mat-divider></mat-divider>
      <div class="value-with-header" [ngStyle]="{'color': fontColor || 'defaultColor'}">{{ value }}</div>
    </div>
    <div *ngIf="!headerValue" style="align-content: center; height: 5vw">
      <span class="value" [ngStyle]="{'color': fontColor || 'defaultColor'}">{{ value }}</span>
    </div>
    <div class="title" [ngStyle]="{'color': '#777777' || 'defaultColor'}">{{ title }}</div>
  </div>
</div>
