import {Component, Input} from '@angular/core';
import {Search} from "../../models/search.model";
import {Router} from "@angular/router";
import {PortfolioCompany} from "../../models/portfolio-company.model";

@Component({
  selector: 'app-search-summary',
  templateUrl: './search-summary.component.html',
  styleUrls: ['./search-summary.component.scss']
})
export class SearchSummaryComponent {

  isCollapsed = false; // Controls the visibility of the collapsible section
  @Input() search!: Search;

  tempImgName!: string;

  constructor(
    private router: Router
  ) {}

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.tempImgName = this.getRandomImageName();
    console.log('REALLY!!!!!')
  }

  getRandomImageName(): string {
    const randomNumber = Math.floor(Math.random() * 5) + 1; // Generates a number between 1 and 9
    return `company${randomNumber}.png`;
  }

  goToPortfolioPage(portfolioCompany: PortfolioCompany) {
    this.router.navigate(['/home/portfolio-board', portfolioCompany.id]);
  }
}
