import { Component, Input, OnChanges, HostListener } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnChanges {
  @Input() rating?: number;  // Now it's clearly optional
  @Input() displayRatingValue = true;

  fullStars: number[] = [];
  emptyStars: number[] = [];
  hasHalfStar: boolean = false;
  windowWidth!: number;
  roundedRating: number = 0;  // Default as 0

  constructor() {
    this.updateWindowWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateWindowWidth();
  }

  ngOnChanges(): void {
    this.updateStars();
  }

  updateStars(): void {
    if (this.rating !== undefined) {  // Check if rating is defined
      const fullStarsCount = Math.floor(this.rating);
      this.fullStars = Array(fullStarsCount).fill(0);
      this.hasHalfStar = this.rating % 1 >= 0.5;
      const emptyStarsCount = 5 - Math.ceil(this.rating);
      this.emptyStars = Array(emptyStarsCount).fill(0);
      this.roundedRating = Math.round(this.rating);
    } else {
      this.fullStars = [];
      this.emptyStars = Array(5).fill(0);
      this.hasHalfStar = false;
    }
  }

  private updateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }
}
