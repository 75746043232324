// portfolio-industry.constants.ts
export const PORTFOLIO_INDUSTRIES = [
  { value: 'TECHNOLOGY', viewValue: 'Technology' },
  { value: 'HEALTHCARE', viewValue: 'Healthcare' },
  { value: 'CONSUMER_GOODS', viewValue: 'Consumer Goods' },
  { value: 'FINANCIAL_SERVICES', viewValue: 'Financial Services' },
  { value: 'MANUFACTURING_AND_INDUSTRIALS', viewValue: 'Manufacturing and Industrials' },
  { value: 'REAL_ESTATE', viewValue: 'Real Estate' },
  { value: 'MEDIA_AND_ENTERTAINMENT', viewValue: 'Media and Entertainment' },
  { value: 'TELECOMMUNICATIONS', viewValue: 'Telecommunications' },
  { value: 'TRANSPORTATION_AND_LOGISTICS', viewValue: 'Transportation and Logistics' },
  { value: 'EDUCATION', viewValue: 'Education' },
  { value: 'HOSPITALITY_AND_LEISURE', viewValue: 'Hospitality and Leisure' }
];
