<div class="card">
  <p-table [value]="dataSource" dataKey="id" [tableStyle]="{ 'min-width': '60rem' }"
           [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-wrap justify-content-end gap-2">
        <p-button label="Expand All" icon="pi pi-plus" text (onClick)="expandAll()" />
        <p-button label="Collapse All" icon="pi pi-minus" text (onClick)="collapseAll()" />
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th style="width: 12rem">Status</th>
        <th style="width: 20rem">Search</th>
        <th style="width: 15rem">Stage</th>
        <th style="width: 15rem;">Search Progress</th>
        <th style="width: 6rem">Rating</th>
        <th style="width: 15rem">Lead</th>
        <th style="width: 15rem">Last Active Date</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
      <tr>
        <td>
          <p-button type="button" pRipple [pRowToggler]="rowData" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
        </td>
        <td>{{ rowData.search?.status }}</td>
        <td>{{ rowData.search?.portfolioCompany?.name }} - {{ rowData.search?.jobTitle }}</td>
        <td style="align-content: start">
          <span class="candidate-stage" *ngIf="rowData && rowData.search">
            {{ getCandidateProgress(rowData.search.assessments) }}
          </span>
        </td>
        <td>
          <ng-container *ngFor="let assessment of rowData.search.assessments">
            <app-progress-tracker [currentStage]="assessment.searchProgress"></app-progress-tracker>
          </ng-container>
        </td>
        <td>
          <span class="candidate-stage" *ngIf="rowData && rowData.search">
            <app-rating [rating]="getTempRating(rowData.search.assessments)"></app-rating>
          </span>
        </td>
        <td>
<!--          <app-rating [rating]="rowData.assessmentRating"></app-rating>-->
        </td>
        <td>{{ rowData.search.triggerDate }}</td>
      </tr>
    </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData>
        <tr>
          <td colspan="7">
            <div class="p-3">
              <p-table [value]="rowData.search.assessments" dataKey="id">
                <ng-template pTemplate="body" let-assessment>
                  <tr>
                    <td style="width: 20%;"></td>
                    <td style="width: 25%;"> Assessment By </td>
                    <td>{{ assessment.candidateStage }}</td>
                    <td>
                      <app-progress-tracker [currentStage]="assessment.searchProgress"></app-progress-tracker>
                    </td>
                    <td>
<!--                      <p-rating [ngModel]="assessment.assessmentRating" [readonly]="true" [cancel]="false" />-->
                      <app-rating [rating]="assessment.assessmentRating"></app-rating>
                    </td>
                    <td>
<!--                      <p-tag [value]="assessment.assessmentRating" />-->
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6">There are no order for this product yet.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
  </p-table>
</div>
