<div class="container">
  <h2 style="margin-top: 0px">TEAM ALIGNMENT</h2>
  <table class="alignment-table">
    <thead>
    <tr>
      <th *ngFor="let role of roles">{{ role }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td *ngFor="let role of roles">
        <mat-form-field appearance="fill">
          <mat-select [(ngModel)]="selectedUserIds[role]" (selectionChange)="onProfileSelectionChange($event.value)">
            <mat-option *ngFor="let profile of categorizedProfiles[role]" [value]="profile.id" >
              <ng-container *ngIf="!profile.id">HI</ng-container>
              {{ profile.firstName }} {{ profile.lastName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle [checked]="selectedUserIsStakeholderValues[role]"
                          (change)="updatePortfolioManagement($event.checked, role, 0, false)"
                          [disabled]="!this.selectedUserIds[role]"
                          style="width: 100%; padding: 10%" color="primary">
        </mat-slide-toggle>
        <mat-label style="font-size: 12px; color: #999999">Search Stakeholder</mat-label>
      </td>
    </tr>
    </tbody>
  </table>

  <h2>MARKET</h2>
  <p>{{ portfolioCompany.name }} operates in the healthcare payments sector, a market expected to reach $500 billion by
    2027 with a 15% CAGR. The North American segment, {{ portfolioCompany.name }}'s primary focus, has a serviceable
    available market (SAM) of $100 billion. Targeting healthcare providers, insurers, and
    patients, {{ portfolioCompany.name }} offers solutions that streamline transactions, reduce administrative burdens,
    and enhance patient satisfaction. As healthcare digitizes, {{ portfolioCompany.name }} is poised to drive
    significant market growth.</p>

  <h2>INVESTMENT THESIS</h2>
  <p>Investing in {{ portfolioCompany.name }} enables growth through operational efficiencies, strategic partnerships,
    and advanced technology. Infinitus will enhance scalability, optimize sales, and invest in R&D to drive revenue
    growth and maximize shareholder value.</p>

  <h2>FINANCIALS</h2>
  <table class="financials-table">
    <tr style="border-bottom: 1px solid #EFEFEF;">
      <th>Check Size</th>
      <th>Equity</th>
      <th>2023 Revenue</th>
      <th>YoY Growth</th>
      <th>2023 EBITDA</th>
      <th>YoY Growth</th>
    </tr>
    <tr>
      <td>$50M</td>
      <td>54%</td>
      <td>$19.8M</td>
      <td>47%</td>
      <td>$8M</td>
      <td>0%</td>
    </tr>
    <tr>
      <td colspan="2"></td>
      <td>2022 Revenue</td>
      <td></td>
      <td>2022 EBITDA</td>
      <td></td>
    </tr>
    <tr>
      <td colspan="2"></td>
      <td>$10.1M</td>
      <td></td>
      <td>$8M</td>
      <td></td>
    </tr>
  </table>

  <h2>MARKETING</h2>

  <h3>COMPANY DESCRIPTION</h3>
  <p>{{ portfolioCompany.name }} is a cutting-edge financial technology company committed to revolutionizing the way
    healthcare businesses manage payments and financial transactions. Our mission is to provide seamless, secure, and
    efficient payment solutions that empower businesses to grow and thrive.
  </p>

  <h3>COMPANY CULTURE</h3>
  <p>
    At {{ portfolioCompany.name }}, our innovative spirit is matched by our commitment to fostering a diverse and
    inclusive workplace. We believe that every employee's unique perspectives and talents are essential to our mission
    of revolutionizing healthcare payments. We prioritize creating an environment where everyone feels valued,
    empowered, and inspired to contribute to our collective success. Together, we strive to deliver seamless, secure,
    and efficient payment solutions that enable healthcare businesses to grow and thrive.
  </p>


</div>
