import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {SearchStateServiceService} from "../../states/search-state-service.service";

@Component({
  selector: 'app-search-interview',
  templateUrl: './search-interview.component.html',
  styleUrl: './search-interview.component.scss'
})
export class SearchInterviewComponent implements OnInit {

  selectedSearch: any = null;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private searchStateServiceService: SearchStateServiceService) {

    this.subscriptions.add(this.searchStateServiceService.selectedSearch$.subscribe(search => {
      this.selectedSearch = search;
    }));
  }

  ngOnInit(): void {
    console.log("Search Pre pComponent ********** ")
  }

}
