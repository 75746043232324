<div class="table-responsive m-t-30">
  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="assigned">
      <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14 p-l-0">
        Assigned
      </th>
      <td mat-cell *matCellDef="let element" class="p-l-0">
        <div class="d-flex align-items-center">
          <img [src]="element.imagePath" alt="users" width="40" class="rounded-circle" />
          <div class="m-l-16">
            <h6 class="mat-subtitle-1 f-s-14 f-w-600">
              {{ element.name }}
            </h6>
            <span class="mat-body-1 f-s-12">
              {{ element.position }}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
        Name
      </th>
      <td mat-cell *matCellDef="let element" class="mat-body-1">
        {{ element.fullname }}
      </td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
        Priority
      </th>
      <td mat-cell *matCellDef="let element">
        <span [ngClass]="{
            'bg-light-accent text-accent': element.priority === 'low',
            'bg-light-primary text-primary': element.priority === 'medium',
            'bg-light-warning text-warning': element.priority === 'high',
            'bg-light-error text-error': element.priority === 'critical',
            'bg-light-success text-success': element.priority === 'moderate'
          }"
              class="rounded f-w-600 p-6 p-y-4 f-s-12">
          {{ element.priority | titlecase }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="budget">
      <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
        Budget
      </th>
      <td mat-cell *matCellDef="let element" class="mat-body-1">
        ${{ element.budget }}k
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
