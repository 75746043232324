import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {PortfolioCompanyService} from "../../services/portfolio-company.service";
import {UserProfileService} from "../../services/user-profile.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Search} from "../../models/search.model";
import {LOCATIONS} from "../../constants/locations.constants";
import {JOB_TITLES} from "../../constants/job-titles.constants";

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrl: './create-contact-dialog.component.scss'
})
export class CreateContactDialogComponent implements OnInit {

  @Output() dialogClose = new EventEmitter<{ success: boolean, message: string }>();

  contactForm!: FormGroup;
  portfolioCompanies: any[] = [];
  searchPortfolioCompany!: string | undefined;
  locations = LOCATIONS;
  jobTitles = JOB_TITLES;

  constructor(
    private fb: FormBuilder,
    private portfolioCompanyService: PortfolioCompanyService,
    private userProfileService: UserProfileService,
    public dialogRef: MatDialogRef<CreateContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { search?: Search }
  ) {
    this.createForm();
  }

  ngOnInit(): void {

    if (!this.data) {
      this.portfolioCompanyService.getPortfolioCompanies().subscribe(response => {
        this.portfolioCompanies = response;
      });
    } else {
        this.searchPortfolioCompany = this.data?.search?.portfolioCompany.name
    }
  }

  createForm() {
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      address: ['', Validators.required],
      jobTitle: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      portfolioCompany: [this.data?.search ? this.data.search.portfolioCompany.id : '', []],
      investmentFirmId: [2]  // Presuming this is static or will be set dynamically elsewhere
    });
  }

  onSubmit() {

    if (!this.contactForm.valid) {
      return;
    }

    const userProfileData = {
      ...this.contactForm.value,
      portfolioCompanyId: this.contactForm.value.portfolioCompany || null,
      searchId: this.data?.search?.id || null
    };

    console.log("userProfileData Submitted :: ", userProfileData);

    this.userProfileService.addUserProfile(userProfileData).subscribe({
      next: () => this.dialogClose.emit({ success: true, message: 'Contact added successfully!' }),
      error: error => this.dialogClose.emit({ success: false, message: 'Error adding contact: ' + error.message })
    });
  }

  // private prepJsonData(formData: any) {
  //   // Prepare the JSON object
  //   const preparedData = {
  //     contact: formData,
  //     search: {
  //       id: this.data.search.id,
  //       portfolioCompany: {
  //         id: this.data.search.portfolioCompany.id
  //       },
  //     }
  //   };
  //
  //   console.log(preparedData);
  //   return preparedData;
  // }

  // closeDialog(trigger: boolean, msg: string) {
  //   this.dialogClose.emit(trigger, msg); // Emit false when the dialog is closed without adding
  // }

  // openConfirmationDialog() {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     width: '350px',
  //     data: {message: 'Contact added successfully! Would you like to add another?'}
  //   });
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.contactForm.reset();
  //     } else {
  //       this.dialogClose.emit(true); // Assuming dialogClose still should close the parent dialog
  //     }
  //   });
  // }

}
