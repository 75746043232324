import {Component, OnInit} from '@angular/core';
import {Document} from "../../models/document.model";
import {FileUploadEvent} from "primeng/fileupload";
import {DocumentService} from "../../services/document.service";

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-search-documents',
  templateUrl: './search-documents.component.html',
  styleUrl: './search-documents.component.scss'
})
export class SearchDocumentsComponent implements OnInit {

  selectedDocument: Document | null = null;
  uploadedFiles: any[] = [];
  defaultPdfUrl: string = 'assets/files/job_description.pdf'; // Provide a default PDF path

  documents: Document[] = [
    { id: 1, name: 'Job Description', content: 'Generated Content for Job Description', pdfUrl: 'assets/files/job_description.pdf' },
    { id: 2, name: 'Candidate Requirements', content: 'Details about Candidate Requirements', pdfUrl: 'assets/files/job_matrix.pdf' },
    { id: 3, name: 'Interview Process Outline', content: 'Detailed steps of the interview process', pdfUrl: 'path/to/interview_process.pdf' }
  ];

  totalSizePercent: any;
  totalSize: string | undefined;

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {
    this.selectedDocument = this.documents[0];
    this.loadDocuments();
  }

  loadDocuments(): void {
    // this.documentService.getDocuments().subscribe({
    //   next: (docs) => this.documents = docs,
    //   error: (error) => console.error('Failed to load documents', error)
    // });
  }

  selectDocument(doc: Document): void {
    // Ensure doc.pdfUrl is defined, else use default
    const pdfUrl = doc.pdfUrl || this.defaultPdfUrl;
    this.selectedDocument = {...doc, pdfUrl}; // Ensuring an entirely new object is set
  }

  onUpload($event: FileUploadEvent) {
    console.log('Doc :: ', event);
    // for(let file of event.files) {
    //   this.uploadedFiles.push(file);
    // }
  }

  onFileSelect(event: any): void {
    for (let file of event.files) {
      this.documentService.uploadFile(file).subscribe({
        next: (response) => console.log('Upload successful', response),
        error: (error) => console.error('Upload failed', error)
      });
    }
  }

  onDownload(documentId: number): void {
    this.documentService.getDownloadLink(documentId).subscribe({
      next: (data) => window.location.href = data.url,
      error: (error) => console.error('Failed to get download link', error)
    });
  }

  onRename(file: any, newName: string) {
    file.name = newName;
    // Additional logic to handle renaming on the server or locally
  }

  choose($event: MouseEvent, chooseCallback: any) {
    chooseCallback();
  }

  onTemplatedUpload() {

  }

  onRemoveTemplatingFile($event: MouseEvent, file: any, removeFileCallback: any, index: any) {

  }

}
