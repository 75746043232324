import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationCount = 0;

  constructor(private snackBar: MatSnackBar) {}

  showNotification(message: string, action: string = 'Close', duration: number = 10000) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar'],
      // Adjust the vertical position based on the number of active notifications
      data: { index: this.notificationCount }
    });

    this.notificationCount++; // Increment the count of active notifications

    snackBarRef.afterDismissed().subscribe(() => {
      this.notificationCount--; // Decrement the count when a notification is dismissed
    });
  }
}
