import {Component, Input} from '@angular/core';
import {Contact} from "../../models/contact.model";
import {UserProfile} from "../../models/user-profile.model";

@Component({
  selector: 'app-contact-summary',
  templateUrl: './contact-summary.component.html',
  styleUrl: './contact-summary.component.scss'
})
export class ContactSummaryComponent {

  isCollapsed = false; // Controls the visibility of the collapsible section
  @Input() userProfile!: UserProfile;

  constructor() {}

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
