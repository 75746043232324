<div class="todo-container">
  <span class="custom-card">Candidate Documents</span>
  <p-divider></p-divider>
  <div class="todo-content">
    <div *ngFor="let document of documentList" class="todo-item">
      <p-checkbox [(ngModel)]="document.completed" binary="true" (onChange)="onToggleTodoComplete(document.id)"></p-checkbox>
      <span class="todo-desc" [ngClass]="{'completed': document.completed}">{{ document.description }}</span>
    </div>
  </div>
</div>
