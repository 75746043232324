import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private tenantId: string | null = null;

  setTenantId(tenantId: string): void {
    this.tenantId = tenantId;
    localStorage.setItem('tenantId', tenantId); // Store in localStorage for persistence
  }

  getTenantId(): string | null {
    return this.tenantId || localStorage.getItem('tenantId'); // Retrieve from localStorage if not in memory
  }

  clearTenantId(): void {
    this.tenantId = null;
    localStorage.removeItem('tenantId');
  }
}
