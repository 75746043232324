import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {UserProfileService} from "../../services/user-profile.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TenantService} from "../../services/tenant.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  email: string = '';
  password: string = '';
  loginError: string = '';
  private babylonUrl = environment.babylonUrl;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private tenantService: TenantService
  ) {}

  login() {
    this.userProfileService.login(this.email, this.password).subscribe({
      next: (response) => {
        if (response && response.tenantId) {
          const tenantId = response.tenantId; // Assuming response includes tenantId
          this.tenantService.setTenantId(tenantId); // Store tenantId
          this.router.navigate(['/home/dashboard']);
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.loginError = 'Unauthorized: Invalid credentials';
        } else if (error.status === 500) {
          this.loginError = 'Server error: Please try again later';
        } else {
          this.loginError = 'An unexpected error occurred. Please try again';
        }
      }
    });
  }

  redirectToGoogle() {
    window.location.href = this.babylonUrl + '/oauth2/authorization/google';
  }

  redirectToLinkedIn() {
  }

  redirectToMicrosoft() {
    window.location.href = this.babylonUrl + '/oauth2/authorization/microsoft';
  }
}
