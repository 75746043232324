<div class="todo-container">
    <span class="custom-card">To-do List</span>
    <p-divider></p-divider>
    <div class="todo-content">
      <div *ngFor="let todo of todos" class="todo-item">
        <p-checkbox [(ngModel)]="todo.completed" binary="true" (onChange)="onToggleTodoComplete(todo.id)"></p-checkbox>
        <span class="todo-desc" [ngClass]="{'completed': todo.completed}">{{ todo.description }}</span>
      </div>

      <div class="input-group">
        <input pInputText id="task" [(ngModel)]="newTaskDescription" placeholder="Enter new task" />
      </div>

      <button pButton type="button" label="Add New Task" (click)="onAddTodo()" class="add-task-btn"></button>
    </div>
</div>
