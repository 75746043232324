<div style="margin-left: 2.5%; min-height: 1000px; padding-top: .5%">

  <app-search-summary [search]="selectedSearch"></app-search-summary>

  <div class="search-management-container">
    <div style="margin-top: 2.5%; margin-right: 2%; margin-bottom: 5%; background-color: white;">
      <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Matrix">
          <app-search-matrix></app-search-matrix>
        </mat-tab>
        <mat-tab label="Interview Process">
          <app-interview-process></app-interview-process>
        </mat-tab>
        <mat-tab label="Interview Guides">
          <app-interview-guides></app-interview-guides>
        </mat-tab>
        <mat-tab label="Working Session">
        </mat-tab>
        <mat-tab label="Search Documents">
          <app-search-documents></app-search-documents>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
