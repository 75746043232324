<div class="search-summary-container">
  <div class="search-summary-header" (click)="toggleCollapse()">
    <img src="assets/images/companies/company4.png" alt="Company Logo" class="company-logo">
    <div class="company-details">
      <h2 class="search-summary-title">
        {{ portfolioCompany.name }}
      </h2>
    </div>
    <mat-icon class="toggle-icon">{{ isCollapsed ? 'expand_more' : 'expand_less' }}</mat-icon>
  </div>
  <div class="search-summary-details" *ngIf="!isCollapsed">
    <div class="search-summary-info">
      <mat-icon class="icon">event</mat-icon> <!-- Changed to 'event' for date-related contexts -->
      <span class="search-summary-label">Investment Date:</span>
      <span class="search-summary-value">{{ portfolioCompany.dealCloseDate | date }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">person</mat-icon> <!-- Changed to 'person' for personal representation -->
      <span class="search-summary-label">
        CEO
      </span>
      <span class="search-summary-value">
        Bob Marley
      </span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">person</mat-icon> <!-- Changed to 'person' for personal representation -->
      <span class="search-summary-label">
        Executive Chair
      </span>
      <span class="search-summary-value">
        Jessica Alba
      </span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">info</mat-icon> <!-- Kept 'info' as it's suitable for status -->
      <span class="search-summary-label">
        Status:
      </span>
      <span class="search-summary-value">{{ portfolioCompany.portfolioStatus }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">business</mat-icon> <!-- Changed to 'business' for industry-related context -->
      <span class="search-summary-label">
         Years in portfolio:
      </span>
      <span class="search-summary-value"> 5 </span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">attach_money</mat-icon> <!-- Changed to 'attach_money' for revenue -->
      <span class="search-summary-label">
         Annual Revenue:
      </span>
      <span class="search-summary-value priority">{{ portfolioCompany.companyRevenue }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">group</mat-icon> <!-- Changed to 'group' for employees -->
      <span class="search-summary-label">
         Employees:
      </span>
      <span class="search-summary-value priority">{{ portfolioCompany.employeeCount }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">group</mat-icon> <!-- Changed to 'group' for employees -->
      <span class="search-summary-label">
         Fund:
      </span>
      <span class="search-summary-value priority">{{ portfolioCompany.fundingRound }}</span>
    </div>
    <div class="search-summary-info">
      <mat-icon class="icon">group</mat-icon> <!-- Changed to 'group' for employees -->
      <span class="search-summary-label">
         Location:
      </span>
      <span class="search-summary-value priority">{{ portfolioCompany.location }}</span>
    </div>
  </div>

    <div class="search-summary-tags" *ngIf="portfolioCompany.portfolioCompanyTags && portfolioCompany.portfolioCompanyTags.length > 0 && !isCollapsed">
      <app-tags [tags]="portfolioCompany.portfolioCompanyTags"></app-tags>
    </div>
</div>
