<div class="contact-card-horizontal">
  <mat-card class="horizontal-card">
    <mat-card-content>

      <div class="add-remove-icon" *ngIf="!candidates" matTooltip="Add contact to Search" >
        <i class="fa-sharp fa-solid fa-user-plus" (click)="confirmAddContact(userProfile)"></i>
      </div>
      <div class="add-remove-icon" *ngIf="candidates" matTooltip="Remove Contact from Search">
        <i class="fa-sharp fa-solid fa-user-minus" (click)="confirmRemoveContact(candidates)"></i>
      </div>

      <div class="container">
        <div class="col-sm-2 left-panel">
          <img src="assets/images/profile/{{tempImgName}}" class="rounded-circle" alt="Profile Picture">
        </div>
        <div class="right-panel">
          <div class="top-right" style="margin-bottom: 1%">
            <div class="name-and-title" style="float: left; padding: .5% 0%">
              {{ candidates ? (candidates.userProfile.firstName) : (userProfile.firstName || null) }}
              {{ candidates ? (candidates.userProfile.lastName) : (userProfile.lastName || null) }} -
              {{ candidates ? (candidates.userProfile.jobTitle) : (userProfile.jobTitle || null) }}
            </div>
            <span style="float: left; padding-left: 2%" *ngIf="candidates && candidates.userProfile">
              <app-rating [rating]="candidates.userProfile.assessmentRating" [displayRatingValue]="true"></app-rating>
            </span>
          </div>
          <div class="bottom-right">
            <mat-divider></mat-divider>
            <div class="location">
              {{ candidates ? (candidates.userProfile.address) : (userProfile.address || null) }}
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 0.25% 3%">
        <div class="tags-section"
             *ngIf="userProfile && userProfile.userProfileTags">
          <app-tags [tags]="userProfile.userProfileTags.slice(0,5) || []"></app-tags>
        </div>
        <div class="tags-section"
             *ngIf="candidates && candidates.userProfile && candidates.userProfile.userProfileTags">
          <app-tags [tags]="candidates.userProfile.userProfileTags.slice(0,3) || []"></app-tags>
        </div>
        <div class="details" *ngIf="candidates">
          <span>
            <ng-container *ngFor="let assessment of candidates.userProfile.assessments">
              <app-progress-tracker [currentStage]="assessment.searchProgress"></app-progress-tracker>
            </ng-container>
          </span>
          <span class="candidate-stage" *ngIf="candidates && candidates.userProfile && candidates.userProfile.assessments">
            {{ getCandidateProgress(candidates.userProfile.assessments) }}
          </span>
        </div>
      </div>

    </mat-card-content>

    <div style="padding: 3% 3%; display: flex; justify-content: space-between">

      <div style="width: 60%;">
        <div class="icon-container" (click)="openCreateEmailDialog()">
          <i class="fa-solid fa-envelope" matTooltip="Send Email"></i>
        </div>
        <div class="icon-container">
          <i class="fa-duotone fa-solid fa-phone" matTooltip="Phone Number"></i>
        </div>
        <div class="icon-container">
          <i class="fa-solid fa-file" matTooltip="Resume"></i>
        </div>
        <div class="icon-container" (click)="op.toggle($event)" *ngIf="candidates">
          <i class="fa-sharp fa-solid fa-book" matTooltip="Documents">

            <p-overlayPanel #op>
              <div>
                <app-candidate-documents></app-candidate-documents>
              </div>
            </p-overlayPanel>

          </i>
        </div>
        <div class="icon-container">
          <i class="fa-brands fa-linkedin" matTooltip="LinkedIn Account"></i>
        </div>
      </div>

      <div style="margin-top: 0.5%">
        <a (click)="showContactDetails(candidates ? candidates.userProfile : userProfile)"
           class="contact-details-button"
           style="">Show Contact</a>
      </div>

    </div>
  </mat-card>

</div>
