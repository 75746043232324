import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PortfolioManagement} from "../models/portfolio-management.model";

@Injectable({
  providedIn: 'root'
})
export class PortfolioManagementService {

  private apiUrl = environment.apiUrl + '/portfolioManagement';

  constructor(private http: HttpClient) { }

  updatePortfolioManagement(searchId: number, data: PortfolioManagement): Observable<any> {
    return this.http.put(this.apiUrl, data);
  }

}
