import {Component, Input, OnInit} from '@angular/core';
import {Search} from "../../models/search.model";
import {CreateContactDialogComponent} from "../create-contact-dialog/create-contact-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {SearchStateServiceService} from "../../states/search-state-service.service";
import {SearchContactService} from "../../services/search-contact.service";
import {SearchContact} from "../../models/search-contact.model";
import {TodoListComponent} from "../todo-list/todo-list.component";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {CreateEmailDialogComponent} from "../create-email-dialog/create-email-dialog.component";

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrl: './search-card.component.scss'
})
export class SearchCardComponent implements OnInit {

  @Input() search!: Search;
  @Input() isByCompany: boolean = false;
  public message: string = '';
  topCandidate!: SearchContact;
  pinnedSearches!: Search[];
  pinnedSearchCount!: number;

  constructor(
    private searchService: SearchService,
    private portfolioSearchEngagementService: PortfolioSearchEngagementService,
    public dialog: MatDialog,
    public router: Router,
    private searchStateServiceService: SearchStateServiceService
  ) {}

  openCreateContactDialog() {

    const dialogRef = this.dialog.open(CreateContactDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', top: '0px' },
      data: { search: this.search }
    });
    dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {

      this.message = message;
      dialogRef.close(); // Close the dialog when the event is received
      console.log(message);
      setTimeout(() => {
        this.message = '';
      }, 10000);
    });
  }

  updatePinnedSearch(searchId: number, pinnedSearch: boolean) {

    if (pinnedSearch && this.pinnedSearchCount >= 5) {
      console.log('Maximum number of pinned searches reached');
      return;
    }

    // Prepare the data as per required fields
    const portfolioSearchEngagementRequestData: Partial<PortfolioSearchEngagement> = {
      searchId: searchId,
      pinnedSearch: pinnedSearch,
      userProfileId: 2
    };

    this.portfolioSearchEngagementService.updateEngagement(portfolioSearchEngagementRequestData).subscribe({
      next: (response) => {

        console.log('Search updated successfully', response);

        if (pinnedSearch) {
          this.pinnedSearches.push(this.search); // Add the search to the pinned list
        } else {
    //       // Remove the search if it is unpinned
          this.pinnedSearches = this.pinnedSearches.filter(s => s.id !== searchId);
        }
        this.searchStateServiceService.updatePinnedSearches(this.pinnedSearches);
        this.searchStateServiceService.updatePinnedSearchCount(this.pinnedSearches.length);
      },
      error: (error) => console.error('Failed to update search', error)
    });
  }

  private handlePinningChange(pinnedSearch: boolean) {
    const newCount = pinnedSearch ? this.pinnedSearchCount + 1 : Math.max(this.pinnedSearchCount - 1, 0);
    this.searchStateServiceService.updatePinnedSearchCount(newCount); // Update the count in the state
  }

  ngOnInit(): void {

    this.searchStateServiceService.pinnedSearches$.subscribe(searches => {
      this.pinnedSearches = searches;
    });

    this.searchStateServiceService.pinnedSearchCount$.subscribe(count => {
      this.pinnedSearchCount = count;
    });

    // this.searchContactService.getSearchContactTopCandidates(this.search.id).subscribe(
    //   {
    //     next: (response) => {
    //       this.topCandidate = response;
    //       console.log('Show topCandidate :: ', this.topCandidate)
    //     },
    //     error: (error) => {
    //       console.error('Failed to load contact details:', error);
    //     }
    //   }
    // );
    this.updateSearchDetails();
  }

  updateSearchDetails(): void {
    this.search.daysInStatus = this.getDateByStatus(this.search);
    this.search.daysSinceCreated = this.getDaysFromToday(this.search.createdAt);
  }

  canBePinned(searchId: number): boolean {

    if (this.pinnedSearchCount <= 5 && !this.pinnedSearches.some(search => search.id === searchId)) {
      return true;
    }
    return false;
  }

  getDaysFromToday(dateStr: string): string {
    return this.searchService.getDaysFromToday(dateStr);
  }

  getDateByStatus(search: Search) {

    const date = this.searchService.getDateByStatus(search)
    if (date) {
      return this.getDaysFromToday(date);
    }
    return -1;
  }

  goToSearchPrep() {
    // set Selected Search
    this.searchStateServiceService.setSelectedSearch(this.search);
    this.router.navigate([`/home/search/${this.search.id}/prep`]);
  }

  openTodoList() {
    const dialogRef = this.dialog.open(TodoListComponent, {
      width: '400px', // Set width as needed
      panelClass: ['custom-todo'],
      data: { /* any data you need to pass */ }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // Handle any actions after closing the dialog
    });
  }

  openCreateEmailDialog() {
    const dialogRef = this.dialog.open(CreateEmailDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', bottom: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    // dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {
    //
    //   if(success) {
    //     // this.getGroupSearches();
    //   }
    //   this.message = message;
    //   dialogRef.close(); // Close the dialog when the event is received
    //   console.log(message);
    //   setTimeout(() => {
    //     this.message = '';
    //   }, 10000);
    // });
  }
}
