import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Matrix } from "../../models/matrix.models";
import { MatrixService } from "../../services/matrix.service";

@Component({
  selector: 'app-search-matrix',
  templateUrl: './search-matrix.component.html',
  styleUrls: ['./search-matrix.component.scss']
})
export class SearchMatrixComponent implements OnInit {

  form!: FormGroup;
  currentCategory: string = 'Experience & Competencies';
  fullMatrixList: Matrix[] = [];
  filterMatrixList!: Matrix[];
  displayedColumns: string[] = ['description', 'critical', 'important', 'beneficial', 'actions'];

  constructor(
    private fb: FormBuilder,
    private matrixService: MatrixService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      matrix: this.fb.array([])
    });
    this.loadMatrix();
  }

  get matrixFormArray(): FormArray {
    return this.form.get('matrix') as FormArray;
  }

  loadMatrix(): void {
    this.matrixService.getMatrix().subscribe(matrix => {
      this.fullMatrixList = matrix;
      this.filterMatrix(this.currentCategory);
    });
  }

  filterMatrix(category: string): void {
    this.filterMatrixList = this.fullMatrixList.filter(m => m.category === category);
    this.updateMatrixFormArray();
  }

  updateMatrixFormArray(): void {
    const matrixFormGroups = this.filterMatrixList.map(matrix => this.createMatrixFormGroup(matrix));
    this.form.setControl('matrix', this.fb.array(matrixFormGroups));
  }

  createMatrixFormGroup(matrix: Matrix): FormGroup {
    return this.fb.group({
      id: [matrix.id],
      description: [matrix.description, Validators.required],
      category: [matrix.category],
      critical: [matrix.critical],
      important: [matrix.important],
      beneficial: [matrix.beneficial],
      weight: [matrix.weight]
    });
  }

  promptAddMatrix(): void {
    const description = window.prompt('Enter the description for the new Criteria:');
    if (description) {
      this.addMatrix(description);
    }
  }

  addMatrix(description: string): void {
    const matrix: Matrix = {
      id: this.fullMatrixList.length + 1,
      description: description,
      category: this.currentCategory,
      critical: false,
      important: false,
      beneficial: false,
      weight: 1
    };
    this.filterMatrixList.push(matrix);
    this.updateMatrixFormArray();
  }

  removeMatrix(index: number): void {
    this.filterMatrixList.splice(index, 1);
    this.updateMatrixFormArray();
  }

  generateJobDescription(): void {
    // console.log('Job Description based on:', this.form.value);
  }

  toggleTabs(category: string): void {
    this.currentCategory = category;
    this.filterMatrix(category);
  }
}
