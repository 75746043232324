import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {InterviewStep} from "../../models/interview-step.model";

@Component({
  selector: 'app-interview-process',
  templateUrl: './interview-process.component.html',
  styleUrls: ['./interview-process.component.scss']
})
export class InterviewProcessComponent implements OnInit {

  interviewSteps: InterviewStep[] = [
    {
      phase: 1,
      step: 'Candidate calibration',
      description: 'Review resumes and align with PE on candidate quality',
      owner: 'Search firm',
      ownerOptions: ['Search firm', 'HC team']
    },
    {
      phase: 2,
      step: 'Search firm screening',
      description: 'Initial interview - will cover critical criteria, career transitions, and comp requirements',
      owner: 'Search firm',
      ownerOptions: ['Search firm']
    },
    {
      phase: 3,
      step: 'Initial CEO interview',
      description: '45-minute initial assessment and cultural fit',
      owner: 'CEO',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 4,
      step: 'Caliper',
      description: 'Assessment and readout with candidate',
      owner: 'HC team',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 5,
      step: 'Technical interview',
      description: 'Assess finance technical competencies and expertise',
      owner: 'Technical interviewer',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 6,
      step: 'Company interview',
      description: 'Assess ability to work with portfolio customers and team',
      owner: 'Executive Chair',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 7,
      step: 'Second CEO interview',
      description: '45-minute deep dive and final alignment',
      owner: 'CEO',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 8,
      step: 'Working session',
      description: 'Realistic and practical evaluation of candidate\'s abilities in a collaborative and dynamic setting',
      owner: 'CEO, EC, VCO',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 9,
      step: 'Board selling conversation',
      description: 'Convey the value, opportunities, and expectations of the role to the candidate',
      owner: 'Board members',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 10,
      step: 'References and backchannels',
      description: 'Conduct backchannels throughout the process. References must include one direct report, peer, and supervisor. Ensure two backchannels or company reviews',
      owner: 'Stakeholders',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    },
    {
      phase: 11,
      step: 'Offer prep and extension',
      description: 'Prepare and extend a formal job offer to the selected candidate',
      owner: 'Hiring manager',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    }
  ];

  form!: FormGroup;
  displayedColumns: string[] = ['phase', 'action', 'step', 'description', 'owner'];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      interviewSteps: this.fb.array(this.interviewSteps.map(step => this.createStepFormGroup(step)))
    });
    console.log(this.form.value);
  }

  get interviewStepsFormArray(): FormArray {
    return this.form.get('interviewSteps') as FormArray;
  }

  createStepFormGroup(step: InterviewStep): FormGroup {
    return this.fb.group({
      phase: [step.phase],
      step: [step.step],
      description: [step.description],
      owner: [step.owner],
      ownerOptions: [step.ownerOptions]
    });
  }

  addStep(index: number): void {
    const stepForm = this.createStepFormGroup({
      phase: this.interviewStepsFormArray.length + 1,
      step: '',
      description: '',
      owner: '',
      ownerOptions: ['Search firm', 'CEO', 'HC team', 'Executive Chair']
    });
    this.interviewStepsFormArray.insert(index + 1, stepForm);
  }

  removeStep(index: number): void {
    this.interviewStepsFormArray.removeAt(index);
  }
}
