<div class="col-lg-4">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-y-24">
      <mat-card-title style="font-size: calc(0.75vw + 12px);; padding-left: 2%; padding-top: 2%">
        Portfolio {{ label }}s
      </mat-card-title>

      <div *ngFor="let contact of getFilteredContacts(); trackBy: trackByContact" style="margin-top: 5%; margin-left: 2%">
        <div class="d-flex align-items-center m-b-24">
          <span>
            <img [src]="contact.img" width="75" alt="icon" style="border-radius: 50%"/>
          </span>
          <div class="m-l-16 m-r-auto" style="padding-top: 3%">
            <div class="mat-body-2 f-w-600" style="font-size: calc(0.5vw + 10px);">
              {{ contact.firstName }} {{ contact.lastName }} - {{ contact.position }}
            </div>
            <div class="mat-body-1" style="margin-top: 10%; font-size: calc(0.5vw + 8px);">
              {{ contact.companyName }}
            </div>
          </div>
          <div class="f-w-600 f-s-14" style="padding-top: 3%; margin-right: 2.5%; font-size: calc(0.4vw + 8px);">
            <div class="mat-body-2 f-w-600" style="color: #CCCCCC">
              {{label}} Date
            </div>
            <div class="mat-body-1" style="margin-top: 10%; font-size: calc(0.5vw + 10px);">
              {{ contact.date }}
            </div>
          </div>
<!--          <span class="f-w-600 f-s-14" style="font-size: 16px; margin-right: 3%">-->
<!--            -->
<!--          </span>-->
        </div>
      </div>

      <button mat-stroked-button color="primary" class="w-100 m-t-8">
        View More
      </button>
    </mat-card-content>
  </mat-card>
</div>
