import {Component, OnInit} from '@angular/core';
import {GridOptions, ValueFormatterParams} from 'ag-grid-community';
import {SearchService} from "../../services/search.service";
import {Router} from "@angular/router";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {SearchContact} from "../../models/search-contact.model";
import {SearchContactService} from "../../services/search-contact.service";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";
import { Chart, registerables } from 'chart.js';
import {CreateEmailDialogComponent} from "../create-email-dialog/create-email-dialog.component";
import {MatDialog} from "@angular/material/dialog";
Chart.register(...registerables);

@Component({
  selector: 'app-ag-grid-searches',
  templateUrl: './ag-grid-searches.component.html',
  styleUrl: './ag-grid-searches.component.scss'
})
export class AgGridSearchesComponent implements OnInit {

  public rowData: any[] = [];
  public columnDefs: any[] = [];
  public selectedRows: any[] = [];
  public defaultColDef: any;
  public gridOptions: GridOptions;

  constructor(
    private searchService: SearchService,
    private searchContactService: SearchContactService,
    private searchProfileService: PortfolioSearchEngagementService,
    public dialog: MatDialog,
    private router: Router) {

    this.gridOptions = {
      onRowDoubleClicked: event => {
        this.onRowDoubleClick(event)
      },
      // onRowSelected: this.onRowSelected.bind(this),
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onSelectionChanged: event => this.onSelectionChanged(event)
    };

    this.columnDefs = [
      {
        headerCheckboxSelection: true, // Enables the header checkbox for selecting all rows
        checkboxSelection: true, // Enables the checkbox for each row
        maxWidth: 40,
        filter: false,
      },
      {headerName: 'Job Function', field: 'jobClassification.jobFunction', minWidth: 140},
      {headerName: 'Job Title', field: 'jobTitle', minWidth: 120},
      {headerName: 'Portfolio Company', field: 'portfolioCompany.name', minWidth: 180},
      { headerName: 'Lead', field: '', minWidth: 160,
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          return this.getLeadName(params.data.searchProfiles);
        },
      },
      {
        headerName: 'Status', field: 'status', minWidth: 120,
        filter: 'agSetColumnFilter',
        // cellStyle: (params: ValueFormatterParams) => {
        //   if (params?.value?.toLowerCase() === 'TRIGGERED'.toLowerCase()) {
        //     return {backgroundColor: '#56CC70', color: 'black'};
        //   } else if (params?.value?.toLowerCase() === 'OPEN'.toLowerCase()) {
        //     return {backgroundColor: 'orange', color: 'black'};
        //   }
        //   return null;
        // }
      },
      {headerName: 'Priority', field: 'priority'},
      {headerName: 'Search Strategy', field: 'searchStrategy'},
      { headerName: 'Placement', field: '',
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          return this.getPlacement(params.data.searchContacts);
        },
      },
      {headerName: 'Confidential', field: 'confidential'},
      {headerName: 'Remote', field: 'remote'},
      // { headerName: 'Human Capital Lead', field: 'humanCapitalLead.name' },
    ];

    //valueFormatter: params => `${params.data.contact.firstName} ${params.data.contact.lastName}

    this.defaultColDef = {
      flex: 1,
      minWidth: 70,
      filter: true,
      resizable: true
    };
  }

  ngOnInit() {
    this.fetchData();
  }

  onRowDoubleClick(event: any) {
    this.router.navigate(['/home/searches', event.data.id]);
  }

  fetchData() {
    this.searchService.getAllSearches().subscribe({
      next: (data) => {
        this.rowData = data;
        // this.initChart(this.rowData);
        // console.log('Grouped Searches:', this.rowData);
      },
      error: (err) => console.error('Failed to load searches:', err)
    });
  }

  getLeadName(searchProfiles: PortfolioSearchEngagement[]): any {

    return this.searchProfileService.getLeadName(searchProfiles);
  }

  getPlacement(searchContacts: SearchContact[]): any {

    return this.searchContactService.getPlacement(searchContacts);
  }

  onRowSelected(event: any) {
    if (event.node.selected) {
      // this.initChart([event.data]);
    }
  }

  onSelectionChanged(event: any): void {
    const selectedNodes = event.api.getSelectedNodes();
    this.selectedRows = selectedNodes.map((node: { data: any; }) => node.data);
    console.log('Selected Nodes:', this.selectedRows);

    // Example: trigger an action like enabling a button based on selection
    // this.enableActionButton(selectedData.length > 0);
  }

  openCreateEmailDialog() {
    const dialogRef = this.dialog.open(CreateEmailDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', bottom: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    // dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {
    //
    //   if(success) {
    //     // this.getGroupSearches();
    //   }
    //   this.message = message;
    //   dialogRef.close(); // Close the dialog when the event is received
    //   console.log(message);
    //   setTimeout(() => {
    //     this.message = '';
    //   }, 10000);
    // });
  }

  // initChart(data: any[]): void {
  //   const ctx = document.getElementById('myChart') as HTMLCanvasElement;
  //   const myChart = new Chart(ctx, {
  //     type: 'doughnut',
  //     data: {
  //       labels: data.map(item => item.jobTitle),
  //       datasets: [{
  //         label: '# of Votes',
  //         data: data.map(item => item.priority), // Assume you have a 'priority' field
  //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
  //         borderColor: 'rgba(255, 99, 132, 1)',
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       scales: {
  //         y: {
  //           beginAtZero: true
  //         }
  //       }
  //     }
  //   });
  // }

}
