<div class="interview-process-container" [formGroup]="form">
  <mat-table [dataSource]="interviewStepsFormArray.controls" class="mat-elevation-z8" style="margin-top: 3%">
    <!-- Phase Column -->
    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef>Phase</th>
      <td mat-cell *matCellDef="let element">{{ element.value.phase }}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element; let i = index" class="action-buttons">
        <button class="action-button add-button" (click)="addStep(i)">
          <mat-icon class="icon">add</mat-icon>
        </button>
        <button class="action-button remove-button" (click)="removeStep(i)">
          <mat-icon class="icon">remove</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Step Column -->
    <ng-container matColumnDef="step">
      <th mat-header-cell *matHeaderCellDef>Step</th>
      <td mat-cell *matCellDef="let element">{{ element.value.step }}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.value.description }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef>Owner</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" style="height: 70px; margin-top: 10%">
          <mat-select [formControlName]="'owner'">
            <mat-option *ngFor="let owner of element.get('ownerOptions').value" [value]="owner">
              {{ owner }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row-style"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
  </mat-table>

  <div style="margin-top: 2%">
    <button mat-button class="button">
      <i class="fa fa-share-nodes"></i> Hiring Manager
    </button>
    <button mat-button class="button">
      <i class="fa fa-share-nodes"></i> Stakeholders
    </button>
  </div>
</div>
