import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Matrix} from "../models/matrix.models";

@Injectable({
  providedIn: 'root'
})
export class MatrixService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getMatrix(): Observable<Matrix[]> {
    return this.http.get<Matrix[]>(this.apiUrl + '/matrix');
  }

}
