export const TEAM_ROLES = [
  { value: 'SOURCING', viewValue: 'Sourcing' },
  { value: 'SCREENING', viewValue: 'Screening' },
  { value: 'TEASER_DECK', viewValue: 'Teaser Deck' },
  { value: 'COMP_ILLUSTRATION', viewValue: 'Compensation Illustration' },
  { value: 'HIRING_MANAGER', viewValue: 'Hiring Manager' },
  { value: 'ASSESSMENT_READOUT', viewValue: 'Assessment Readout' },
  { value: 'TECHNICAL_ASSESSMENT', viewValue: 'Technical Assessment' },
  { value: 'COMPANY_INTERVIEW', viewValue: 'Company Interview' },
  { value: 'SELLING_CONVERSATION', viewValue: 'Selling Conversation' },
  { value: 'REFERENCES_BACKCHANNEL', viewValue: 'References/Backchannel' },
  { value: 'OFFER_PREPARATION', viewValue: 'Offer Preparation' },
  { value: 'OFFER_EXTENSION', viewValue: 'Offer Extension' },
  { value: 'ONBOARDING', viewValue: 'Onboarding' }
];
