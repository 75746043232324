import {Component, Input} from '@angular/core';
import {Contact} from "../../models/contact.model";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {

  @Input() contact!: Contact;

  test($event: KeyboardEvent) {

  }

  test2() {

  }

}
