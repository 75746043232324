import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import {PortfolioCompany} from "../models/portfolio-company.model";

@Injectable({
  providedIn: 'root'
})
export class PortfolioCompanyService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getPortfolioCompanies(): Observable<PortfolioCompany[]> {
    return this.http.get<any[]>(this.apiUrl + '/portfolio-companies/investment-firm/2/portfolio-companies');
  }

  getPortfolioCompanyById(id: number): Observable<PortfolioCompany> {
    return this.http.get<PortfolioCompany>(this.apiUrl + `/portfolio-companies/${id}`);
  }

  createPortfolioCompany(company: PortfolioCompany): Observable<PortfolioCompany> {
    return this.http.post<PortfolioCompany>(this.apiUrl + '/portfolio-companies', company);
  }

}
