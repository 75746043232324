<div style="margin-top: 3%">
    <div style="float: right">
      <button mat-raised-button
              [ngClass]="{'button-enabled': selectedRows.length > 0, 'button-disabled': selectedRows.length === 0}"
              (click)="openCreateEmailDialog()"
              [disabled]="!selectedRows.length">
        Send Email
      </button>
  </div>
  <br/><br/><br/>
  <div style="width: auto">
    <ag-grid-angular
      style="width: 100%; height: 650px;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions">
      >
    </ag-grid-angular>
  </div>
</div>

<!--<canvas id="myChart" width="400" height="100" style="background-color: #FFFFFF; margin-top: 2%; margin-bottom: 3%"></canvas>-->
