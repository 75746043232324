import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SearchStateServiceService} from "../../states/search-state-service.service";

@Component({
  selector: 'app-search-management',
  templateUrl: './search-management.component.html',
  styleUrl: './search-management.component.scss'
})
export class SearchManagementComponent implements OnInit {

  // searchId: number | null = null;
  searchDetails: any;

  constructor(
    private route: ActivatedRoute,
    private searchStateServiceService: SearchStateServiceService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.searchDetails = data['searchDetails'];

      // Update searchDetails immutably
      this.searchDetails = {
        ...this.searchDetails,
        loadedTime: new Date(),  // Adds the current timestamp when data is loaded
      };
      this.searchStateServiceService.setSelectedSearch(this.searchDetails);
      // console.log('Search Details loaded via Resolver:', this.searchDetails);
    });
  }

}
