import {Component, Input} from '@angular/core';
import {PortfolioCompany} from "../../models/portfolio-company.model";
import {ActivatedRoute, Router} from "@angular/router";
import {PortfolioCompanyService} from "../../services/portfolio-company.service";

@Component({
  selector: 'app-show-portfolio-company',
  templateUrl: './show-portfolio-company.component.html',
  styleUrl: './show-portfolio-company.component.scss'
})
export class ShowPortfolioCompanyComponent {

  public portfolioCompany!: PortfolioCompany;

  constructor(
    private activatedRoute: ActivatedRoute,
    private portfolioCompanyService: PortfolioCompanyService
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      const id = +params.get('id')!;
      if (id) {
        this.portfolioCompanyService.getPortfolioCompanyById(id).subscribe({
          next: (data) => {
            this.portfolioCompany = data;
          },
          // error: (err) => console.error('Failed to load portfolio company', err)
        });
      }
    });
  }

}
