import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {SearchStateServiceService} from "../../states/search-state-service.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-search-prep',
  templateUrl: './search-prep.component.html',
  styleUrl: './search-prep.component.scss'
})
export class SearchPrepComponent implements OnInit {

  selectedSearch: any = null;
  selectedTabIndex: number = 0;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private searchStateServiceService: SearchStateServiceService) {

    this.subscriptions.add(this.searchStateServiceService.selectedSearch$.subscribe(search => {
      this.selectedSearch = search;
    }));
  }

  ngOnInit(): void {

  }

  goToTab(index: number): void {
    this.selectedTabIndex = index;
  }

}
