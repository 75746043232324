import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from "@angular/material/toolbar";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MatMenuModule} from "@angular/material/menu";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCardModule} from "@angular/material/card";
import {StatsCardComponent} from './components/stats-card/stats-card.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatDividerModule} from "@angular/material/divider";
import {ContactComponent} from './components/contact/contact.component';
import {PortfolioComponent} from './components/portfolio/portfolio.component';
import {LeftNavComponent} from './components/left-nav/left-nav.component';
import {BasicTableComponent} from './components/basic-table/basic-table.component';
import {MatTableModule} from "@angular/material/table";
import {PortfolioBoardComponent} from './pages/portfolio-board/portfolio-board.component';
import {SearchBoardComponent} from './components/search-board/search-board.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SearchesComponent} from './pages/searches/searches.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SearchCardComponent} from './components/search-card/search-card.component';
import {CreateSearchDialogComponent} from './components/create-search-dialog/create-search-dialog.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NotesComponent} from './pages/notes/notes.component';
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {LoginComponent} from './pages/login/login.component';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {AgGridModule} from 'ag-grid-angular';
import {AgChartsModule } from 'ag-charts-angular';
import {CryptoCardComponent} from './trading-app/crypto-card/crypto-card.component';
import {TradingDashboardComponent} from './trading-app/trading-dashboard/trading-dashboard.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {AgGridSearchesComponent} from './components/ag-grid-searches/ag-grid-searches.component';
import {CreateContactDialogComponent} from './components/create-contact-dialog/create-contact-dialog.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SearchManagementComponent} from './pages/search-management/search-management.component';
import {UpdateSearchComponent} from './components/update-search/update-search.component';
import {ContactCardHorizontalComponent} from './components/contact-card-horizontal/contact-card-horizontal.component';
import {SearchContactsComponent} from './components/search-contacts/search-contacts.component';
import {ShowContactComponent} from './pages/show-contact/show-contact.component';
import {RatingComponent} from './components/rating/rating.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatTabsModule} from "@angular/material/tabs";
import { ContactSearchesExpandableTableComponent } from './components/contact-searches-expandable-table/contact-searches-expandable-table.component';
import { PortfolioEngagementComponent } from './components/portfolio-engagement/portfolio-engagement.component';
import { ProgressTrackerComponent } from './components/progress-tracker/progress-tracker.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { AssessmentListComponent } from './components/assessment-list/assessment-list.component';
import { SearchCardCompanyViewComponent } from './components/search-card-company-view/search-card-company-view.component';
import { SearchPrepComponent } from './pages/search-prep/search-prep.component';
import { SearchInterviewComponent } from './pages/search-interview/search-interview.component';
import { SearchMatrixComponent } from './components/search-matrix/search-matrix.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { SearchDocumentsComponent } from './components/search-documents/search-documents.component';
import {MatListItem, MatNavList} from "@angular/material/list";
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { InterviewProcessComponent } from './components/interview-process/interview-process.component';
import { InterviewGuidesComponent } from './components/interview-guides/interview-guides.component';
import { SearchSummaryComponent } from './components/search-summary/search-summary.component';
import { TagsComponent } from './components/tags/tags.component';
import { InterviewStakeholdersComponent } from './components/interview-stakeholders/interview-stakeholders.component';
import { ContactSummaryComponent } from './components/contact-summary/contact-summary.component';
import { PortfolioCompanySummaryComponent } from './components/portfolio-company-summary/portfolio-company-summary.component';
import { ShowPortfolioCompanyComponent } from './pages/show-portfolio-company/show-portfolio-company.component';
import { PortfolioCompanySearchesComponent } from './components/portfolio-company-searches/portfolio-company-searches.component';
import { ContactPortfolioEngagementComponent } from './components/contact-portfolio-engagement/contact-portfolio-engagement.component';
import { CreateCompanyDialogComponent } from './components/create-company-dialog/create-company-dialog.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { TodoListComponent } from './components/todo-list/todo-list.component';
import { PortfolioCompanyInfoComponent } from './components/portfolio-company-info/portfolio-company-info.component';
import { NetworkComponent } from './components/network/network.component';
import { PortfolioCompanyEmployeesComponent } from './components/portfolio-company-employees/portfolio-company-employees.component';
import {
  MatAccordion, MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelDescription, MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CreateEmailDialogComponent } from './components/create-email-dialog/create-email-dialog.component';
import { QuillModule } from 'ngx-quill';
import {CardModule} from "primeng/card";
import {CheckboxModule} from "primeng/checkbox";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {DividerModule} from "primeng/divider";
import { TestingComponent } from './pages/testing/testing.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import { CandidateDocumentsComponent } from './components/candidate-documents/candidate-documents.component';
import {TooltipModule} from "primeng/tooltip";
import {TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {TagModule} from "primeng/tag";
import {RatingModule} from "primeng/rating";
import {FileUploadModule} from "primeng/fileupload";
import { FileSizePipe } from './pipes/file-size.pipe';
import { WebsiteComponent } from './pages/website/website.component';
import {TenantInterceptor} from "./services/tenant-interceptor.service";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    StatsCardComponent,
    ContactComponent,
    PortfolioComponent,
    LeftNavComponent,
    BasicTableComponent,
    PortfolioBoardComponent,
    SearchBoardComponent,
    SearchesComponent,
    SearchCardComponent,
    CreateSearchDialogComponent,
    NotesComponent,
    LoginComponent,
    LandingPageComponent,
    CryptoCardComponent,
    TradingDashboardComponent,
    AgGridSearchesComponent,
    CreateContactDialogComponent,
    ConfirmationDialogComponent,
    SearchManagementComponent,
    UpdateSearchComponent,
    ContactCardHorizontalComponent,
    SearchContactsComponent,
    ShowContactComponent,
    RatingComponent,
    ContactSearchesExpandableTableComponent,
    PortfolioEngagementComponent,
    ProgressTrackerComponent,
    AssessmentListComponent,
    SearchCardCompanyViewComponent,
    SearchPrepComponent,
    SearchInterviewComponent,
    SearchMatrixComponent,
    SearchDocumentsComponent,
    SafeUrlPipe,
    InterviewProcessComponent,
    InterviewGuidesComponent,
    SearchSummaryComponent,
    TagsComponent,
    InterviewStakeholdersComponent,
    ContactSummaryComponent,
    PortfolioCompanySummaryComponent,
    ShowPortfolioCompanyComponent,
    PortfolioCompanySearchesComponent,
    ContactPortfolioEngagementComponent,
    CreateCompanyDialogComponent,
    TodoListComponent,
    PortfolioCompanyInfoComponent,
    NetworkComponent,
    PortfolioCompanyEmployeesComponent,
    CreateEmailDialogComponent,
    TestingComponent,
    CandidateDocumentsComponent,
    FileSizePipe,
    WebsiteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCardModule,
    MatStepperModule,
    MatDividerModule,
    MatTableModule,
    DragDropModule,
    HttpClientModule,
    MatSidenavModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    AgGridModule,
    AgChartsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatNavList,
    MatListItem,
    MatSnackBarModule,
    MatExpansionPanelDescription,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    BrowserAnimationsModule,
    MatExpansionModule,
    QuillModule.forRoot({
      theme: 'snow'
    }),
    CardModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    DividerModule,
    OverlayPanelModule,
    TooltipModule,
    TableModule,
    ToastModule,
    TagModule,
    RatingModule,
    FileUploadModule
  ],
  providers: [
    provideAnimationsAsync('noop'),
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
