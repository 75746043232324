import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import { AgCharts as AgCharts$1 } from 'ag-charts-community';
class AgChartsBase {
  chart;
  _nativeElement;
  _initialised = false;
  ngZone;
  ngAfterViewInit() {
    const options = this.patchChartOptions(this.options);
    this.chart = this.runOutsideAngular(() => this.createChart(options));
    this._initialised = true;
    this.chart.chart.waitForUpdate().then(() => {
      this.onChartReady.emit(this.chart);
    });
  }
  // noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
  ngOnChanges(_changes) {
    this.runOutsideAngular(() => {
      if (!this._initialised || !this.chart) {
        return;
      }
      this.chart.update(this.patchChartOptions(this.options));
    });
  }
  ngOnDestroy() {
    if (this._initialised && this.chart) {
      this.chart.destroy();
      this.chart = undefined;
      this._initialised = false;
    }
  }
  patchChartOptions(propsOptions) {
    const patchListeners = listenerConfig => {
      const config = listenerConfig ?? {};
      for (const [listenerName, listener] of Object.entries(config)) {
        if (typeof listener !== 'function') continue;
        config[listenerName] = (...args) => {
          this.runInsideAngular(() => listener(...args));
        };
      }
    };
    patchListeners(propsOptions?.legend?.listeners);
    patchListeners(propsOptions?.listeners);
    propsOptions.series?.forEach(series => {
      patchListeners(series.listeners);
    });
    if (propsOptions.container) {
      return propsOptions;
    }
    return {
      ...propsOptions,
      container: this._nativeElement
    };
  }
  runOutsideAngular(callback) {
    return this.ngZone ? this.ngZone.runOutsideAngular(callback) : callback();
  }
  runInsideAngular(callback) {
    return this.ngZone ? this.ngZone.run(callback) : callback();
  }
}

// noinspection AngularIncorrectTemplateDefinition
class AgCharts extends AgChartsBase {
  ngZone;
  options = {};
  onChartReady = new EventEmitter();
  constructor(elementDef, ngZone) {
    super();
    this.ngZone = ngZone;
    this._nativeElement = elementDef.nativeElement;
  }
  createChart(options) {
    return AgCharts$1.create(options);
  }
  static ɵfac = function AgCharts_Factory(t) {
    return new (t || AgCharts)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AgCharts,
    selectors: [["ag-charts"]],
    inputs: {
      options: "options"
    },
    outputs: {
      onChartReady: "onChartReady"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function AgCharts_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgCharts, [{
    type: Component,
    args: [{
      selector: 'ag-charts',
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    onChartReady: [{
      type: Output
    }]
  });
})();

// noinspection AngularIncorrectTemplateDefinition
class AgFinancialCharts extends AgChartsBase {
  ngZone;
  options = {};
  onChartReady = new EventEmitter();
  constructor(elementDef, ngZone) {
    super();
    this.ngZone = ngZone;
    this._nativeElement = elementDef.nativeElement;
  }
  createChart(options) {
    return AgCharts$1.createFinancialChart(options);
  }
  static ɵfac = function AgFinancialCharts_Factory(t) {
    return new (t || AgFinancialCharts)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AgFinancialCharts,
    selectors: [["ag-financial-charts"]],
    inputs: {
      options: "options"
    },
    outputs: {
      onChartReady: "onChartReady"
    },
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function AgFinancialCharts_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgFinancialCharts, [{
    type: Component,
    args: [{
      selector: 'ag-financial-charts',
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    onChartReady: [{
      type: Output
    }]
  });
})();
class AgChartsModule {
  static ɵfac = function AgChartsModule_Factory(t) {
    return new (t || AgChartsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AgChartsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgChartsModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [AgCharts, AgFinancialCharts],
      exports: [AgCharts, AgFinancialCharts]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AgCharts, AgChartsModule, AgFinancialCharts };
