import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Project} from "../../models/project.model";
import {SearchService} from "../../services/search.service";
import {forkJoin} from "rxjs";
import {UserProfileService} from "../../services/user-profile.service";
import {UserProfile} from "../../models/user-profile.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {

  steps = [
    {name: 'Triggered', count: 3, completed: true},
    {name: 'Prep', count: 1, completed: true},
    {name: 'Open', count: 0, completed: true},
    {name: 'Offer Prep', count: 1, completed: true},
    {name: 'Signed', count: 3, completed: true},
    {name: 'Closed', count: 18, completed: true},
    // ... other steps
  ];

  public activeSearches: any;
  public closedSearches: any;

  userProfile: UserProfile | null = null;


  constructor(
    private userProfileService: UserProfileService,
    private searchService: SearchService) {
  }

  ngOnInit(): void {

    this.userProfileService.getUserProfile().subscribe(profile => {
      this.userProfile = profile;
      console.log('User Profile:', this.userProfile);
    });

    forkJoin({
      activeSearches: this.searchService.getActiveSearches(),
      closedSearches: this.searchService.getClosedSearches()
    }).subscribe({
      next: (results) => {
        this.activeSearches = results.activeSearches;
        this.closedSearches = results.closedSearches;
        console.log('Active Searches:', this.activeSearches);
        console.log('Closed Searches:', this.closedSearches);
      },
      error: (err) => console.error('Failed to load searches:', err)
    });
  }

}
