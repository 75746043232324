import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SearchService} from "../../services/search.service";
import {Search} from "../../models/search.model";
import {JOB_FUNCTIONS} from "../../constants/job-functions.constants";
import {PRIORITIES} from "../../constants/priority.constants";
import {FUNCTION_GROUPS} from "../../constants/function-group.constants";
import {SEARCH_STRATEGIES} from "../../constants/search-strategy.constants";
import {SALARY_RANGES} from "../../constants/salary-range.constants";
import {LOCATIONS} from "../../constants/locations.constants";
import {TRIGGERS} from "../../constants/trigger.constants";
import {Engagements} from "../../constants/engagement.constants";
import {EquityTypes} from "../../constants/equity-type.constants";
import {JOB_TITLES} from "../../constants/job-titles.constants";
import {TRIGGER_REASONS} from "../../constants/trigger-reason.constants";

@Component({
  selector: 'app-update-search',
  templateUrl: './update-search.component.html',
  styleUrl: './update-search.component.scss'
})
export class UpdateSearchComponent implements OnInit, OnChanges {

  @Input() searchDetails!: Search;
  searchForm!: FormGroup;

  updateSuccessMessage = '';
  showUpdateSuccess = false;

  priorities = PRIORITIES;
  searchStrategies = SEARCH_STRATEGIES;
  locations = LOCATIONS;
  engagements = Engagements;
  jobTitles = JOB_TITLES;
  triggerReasons = TRIGGER_REASONS;

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      jobLocation: [this.searchDetails?.jobLocation, Validators.required],
      priority: [this.searchDetails?.priority, Validators.required],
      searchStrategy: [this.searchDetails?.searchStrategy, Validators.required],
      jobTitle: [this.searchDetails?.jobTitle, Validators.required],
      triggerReason: [this.searchDetails?.triggerReason, Validators.required],
      triggerDate: [this.searchDetails?.triggerDate, Validators.required],
      triggerNote: [this.searchDetails?.triggerNote],
      confidential: [this.searchDetails?.confidential],
      remote: [this.searchDetails?.remote],
      isSubSearchFirm: [this.searchDetails?.isSubSearchFirm],
      budgetedCompensation: [this.searchDetails?.budgetedCompensation],
      status: [this.searchDetails?.status],
      engagement: [this.searchDetails?.engagement, Validators.required]
    });
  }

  loadSearchDetails() {
    // Populate the form with the search details
    this.searchForm.patchValue(this.searchDetails);
  }

  updateSearch() {
    if (this.searchForm.valid) {

      this.searchService.updateSearch(this.searchDetails.id, this.searchForm.value).subscribe({
        next: (response) => {
          // console.log('Search updated successfully', response);
          this.updateSuccessMessage = 'Search updated successfully!';
          this.showUpdateSuccess = true;
          this.searchDetails = {...this.searchDetails, ...response};
          this.loadSearchDetails();
          setTimeout(() => this.showUpdateSuccess = false, 5000);
        },
        error: (error) => {
          console.error('Failed to update search', error)
          console.error('Failed to update search', error);
          this.updateSuccessMessage = 'Failed to update search.';
          this.showUpdateSuccess = true;
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.searchDetails) {
      this.loadSearchDetails();
    }
  }

  get jobClassificationDisplay(): string {
    const { functionGroup = '', jobFunction = '' } = this.searchDetails.jobClassification || {};
    return `${functionGroup} - ${jobFunction}`;
  }

}
