import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {PortfolioBoardComponent} from "./pages/portfolio-board/portfolio-board.component";
import {SearchesComponent} from "./pages/searches/searches.component";
import {NotesComponent} from "./pages/notes/notes.component";
import {LoginComponent} from "./pages/login/login.component";
import {LandingPageComponent} from "./pages/landing-page/landing-page.component";
import {CryptoCardComponent} from "./trading-app/crypto-card/crypto-card.component";
import {SearchManagementComponent} from "./pages/search-management/search-management.component";
import {ShowContactComponent} from "./pages/show-contact/show-contact.component";
import {SearchDetailsResolverService} from "./resolvers/search-details-resolver.service";
import {SearchPrepComponent} from "./pages/search-prep/search-prep.component";
import {SearchInterviewComponent} from "./pages/search-interview/search-interview.component";
import {ShowPortfolioCompanyComponent} from "./pages/show-portfolio-company/show-portfolio-company.component";
import {NetworkComponent} from "./components/network/network.component";
import {InterviewGuidesComponent} from "./components/interview-guides/interview-guides.component";
import {TestingComponent} from "./pages/testing/testing.component";
import {SearchDocumentsComponent} from "./components/search-documents/search-documents.component";
import {WebsiteComponent} from "./pages/website/website.component";
import {AuthGuard} from "./guards/auth.guard";

const routes: Routes = [
  { path: '', component: WebsiteComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'trading', component: CryptoCardComponent },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: LandingPageComponent,
    children: [  // Define child routes that should be displayed within the LandingPageComponent
      { path: 'dashboard', component: DashboardComponent },
      { path: 'portfolio-board', component: PortfolioBoardComponent },
      { path: 'portfolio-board/:id', component: ShowPortfolioCompanyComponent },
      { path: 'searches', component: SearchesComponent },
      { path: 'network', component: NetworkComponent },
      { path: 'notes', component: NotesComponent },
      { path: 'interview-guide', component: InterviewGuidesComponent },
      { path: 'searches/:id', component: SearchManagementComponent,
        resolve: {
          searchDetails: SearchDetailsResolverService
        }
      },
      { path: 'search/:id/prep', component: SearchPrepComponent },
      { path: 'search/:id/interview', component: SearchInterviewComponent },
      { path: 'search/:id/stakeholders', component: SearchInterviewComponent },
      { path: 'search/:id/onboarding', component: SearchInterviewComponent },
      { path: 'search/:id/post-survey', component: SearchInterviewComponent },
      { path: 'contact/:id', component: ShowContactComponent },
      { path: 'test', component: SearchDocumentsComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
