import { Component, OnInit } from '@angular/core';
import {CellClickedEvent, GridOptions, ValueFormatterParams} from 'ag-grid-community';
import { AgCharts } from 'ag-charts-angular';
import { AgChartOptions } from 'ag-charts-community';
import {Router} from "@angular/router";
import {EmploymentDetailsService} from "../../services/employment-details.service";
import {Search} from "../../models/search.model";
import {EmploymentDetails} from "../../models/employment-details.model";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateEmailDialogComponent} from "../../components/create-email-dialog/create-email-dialog.component";

@Component({
  selector: 'app-portfolio-board',
  // standalone: true,
  // imports: [AgCharts],
  templateUrl: './portfolio-board.component.html',
  styleUrls: ['./portfolio-board.component.scss'],
})
export class PortfolioBoardComponent implements OnInit {

  public rowData: any[] = [];
  public selectedRows: any[] = [];
  public columnDefs: any[];
  public defaultColDef: any;
  public gridOptions: GridOptions;
  public chartOptions: AgChartOptions;
  public message: string = '';

  constructor(
    private router: Router,
    private employmentDetailsService: EmploymentDetailsService,
    public dialog: MatDialog,
  ) {
    this.columnDefs = [
      {
        headerCheckboxSelection: true, // Enables the header checkbox for selecting all rows
        checkboxSelection: true, // Enables the checkbox for each row
        maxWidth: 40,
        filter: false,
      },
      { headerName: 'Company', field: 'companyName', minWidth: 180,
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToPortfolioPage(event),
      },
      { headerName: 'Contact Name', field: 'contactName',
        valueFormatter: (params: ValueFormatterParams) => {
          // Ensure that both firstName and lastName are present
          if (params.data.userProfile && params.data.userProfile.firstName && params.data.userProfile.lastName) {
            return `${params.data.userProfile.firstName} ${params.data.userProfile.lastName}`;
          }
          return ''; // Return empty string if data is incomplete
        },
        onCellDoubleClicked: (event: CellClickedEvent) =>
          this.goToContactPage(event),
      },
      { headerName: 'Function', field: 'userProfile.jobClassification.jobFunction' },
      { headerName: 'Job Title', field: 'jobTitle' },
      { headerName: 'Location', field: 'userProfile.address' },
      { headerName: 'Email', field: 'email' },
      { headerName: 'Phone Number', field: 'phoneNumber' },
      {
        headerName: 'Board',
        field: 'isBoardMember',
        cellRenderer: (params: ValueFormatterParams) => {
          // Extract necessary data from params
          const isBoardMember = params.data.isBoardMember;
          const jobFunction = params.data?.contact?.jobClassification?.jobFunction;

          // Checking conditions based on multiple properties
          const isBoardOrCEO = jobFunction === 'BOARD' || jobFunction === 'CEO';

          // Log to see what's being processed
          // console.log('Is Board Member:', isBoardMember, 'Job Function:', jobFunction);

          // Returning a checkbox, checked if any conditions are true
          return `<input type="checkbox" ${isBoardMember || isBoardOrCEO ? 'checked' : ''} disabled />`;
        },
      },
      { headerName: 'Summit', field: 'isInvitedToSummit',
        cellRenderer: (params: ValueFormatterParams) => {
          // Extract necessary data from params
          const isBoardMember = params.data.isBoardMember;
          const jobFunction = params.data?.contact?.jobClassification?.jobFunction;

          // Checking conditions based on multiple properties
          const isBoardOrCEO = jobFunction === 'BOARD' || jobFunction === 'CEO';

          // Log to see what's being processed
          // console.log('Is Board Member:', isBoardMember, 'Job Function:', jobFunction);

          // Returning a checkbox, checked if any conditions are true
          return `<input type="checkbox" ${isBoardMember || isBoardOrCEO ? 'checked' : ''} disabled />`;
        },}
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      sortable: true,
    };

    this.gridOptions = {
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onGridReady: event => this.fetchData(),
      getRowStyle: params => {

        const search = params.data?.portfolioCompany?.searches.find((search: Search) => search.jobTitle === params.data.positionHeld);

        console.log('Matched search: ', search);

        if (search && (search.status === 'Triggered' || search.status === 'Prep') ) {
          return { backgroundColor: '#ddeffc' };
        } else if (search && ['Open', 'Offer Prep', 'Offer Extended', 'Signed'].includes(search.status)) {
          return { backgroundColor: '#e8e8e8' };
        }

        return undefined;
      },
      // enable pagination
      pagination: true,
      paginationPageSize: 50,
      onSelectionChanged: event => this.onSelectionChanged(event),
    };

    this.chartOptions = {
      // Data: Data to be displayed in the chart
      data: [
        // Can you help to create please?
      ],
      // Series: Defines which chart type and data to use
      series: [{ type: 'bar', xKey: 'month', yKey: 'iceCreamSales' }]
    };
  }

  ngOnInit(): void {}

  fetchData(): void {
    this.employmentDetailsService.getPortfolioBoardMembers().subscribe({
      next: data => {
        this.rowData = this.processEngagementData(data);
        console.log('Processed Data:', this.rowData);
        this.prepareChartData(this.rowData); // Prepare data for the chart
      },
      error: err => console.error('Failed to load portfolio search engagements:', err)
    });
  }

  processEngagementData(data: EmploymentDetails[]): any[] {
    return data.map(employment => ({
      ...employment,
      companyName: employment.portfolioCompany.name,
      contactName: employment.userProfile.firstName,
      jobTitle: employment.userProfile.jobTitle,
      address: employment.userProfile.address,
      email: employment.userProfile.email,
      phoneNumber: employment.userProfile.phoneNumber,
      // isBoardMember: employment.isBoardMember,
      // isInvitedToSummit: employment.isInvitedToSummit
    }));
  }

  goToPortfolioPage(event: any) {
    this.router.navigate(['/home/portfolio-board', event.data.portfolioCompany.id]);
  }

  goToContactPage(event: any) {
    this.router.navigate(['/home/contact', event.data.contact.id]);
  }

  onSelectionChanged(event: any): void {
    const selectedNodes = event.api.getSelectedNodes();
    this.selectedRows = selectedNodes.map((node: { data: any; }) => node.data);
    console.log('Selected Nodes:', this.selectedRows);

    // Example: trigger an action like enabling a button based on selection
    // this.enableActionButton(selectedData.length > 0);
  }

  openCreateEmailDialog() {
    const dialogRef = this.dialog.open(CreateEmailDialogComponent, {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
      position: { right: '0px', bottom: '0px' }
      // panelClass: 'custom-dialog-container'
    });

    // dialogRef.componentInstance.dialogClose.subscribe(({ success, message }) => {
    //
    //   if(success) {
    //     // this.getGroupSearches();
    //   }
    //   this.message = message;
    //   dialogRef.close(); // Close the dialog when the event is received
    //   console.log(message);
    //   setTimeout(() => {
    //     this.message = '';
    //   }, 10000);
    // });
  }

  prepareChartData(data: EmploymentDetails[]): void {
    // Count engagements by employment status
    const engagementByStatus = data.reduce((acc, curr) => {
      const status = curr.employmentStatus;
      // acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    const chartData = Object.keys(engagementByStatus).map(status => ({
      status: status,
      engagements: data
    }));

    this.chartOptions = {
      title: {
        text: "Engagements by Employment Status"
      },
      data: chartData,
      series: [{
        type: 'bar',
        xKey: 'status',
        yKey: 'engagements'
      }],
      axes: [
        {
          type: 'category',  // Correct type for the x-axis when data is categorical
          position: 'bottom', // Standard for category axis
          title: { text: 'Employment Status' }
        },
        {
          type: 'number',  // Correct type for the y-axis when data is numerical
          position: 'left', // Standard for numerical axis
          title: { text: 'Number of Engagements' }
        }
      ],
      legend: { enabled: false }  // Optional: Disable legend if not needed
    };
  }

}
