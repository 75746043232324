<div style="width: 96%; height: 1200px; margin-left: 2.5%;">
  <h2>
    <p>Portfolio Board and Management Leaders</p>
  </h2>

  <div class="stats-cards">
    <app-stats-card style="margin-right: 10%;"
                    [icon]="'search'"
                    [title]="'Portfolio Companies'"
                    [value]="32"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Portfolio Leaders'"
                    [value]="280"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Board Members'"
                    [value]="58"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Board Seats'"
                    [value]="128"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card style="margin-right: 10%;"
                    [icon]="'settings'"
                    [title]="'Top Performers'"
                    [value]="54"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>

    <app-stats-card
                    [icon]="'settings'"
                    [title]="'Founders'"
                    [value]="13"
                    [backgroundColor]="'#F7F7F5'"
                    [fontColor]="'#777777'"
    >
    </app-stats-card>
  </div>

  <div style="margin-top: 2.5%; height: 4%;">
    <div style="float: left; padding: .5%; background-color: #FFFFFF; border-radius: 10px">
      <span style="padding-right: 5px" matTooltip="Trigger, Prep">
        <i class="fa-solid fa-circle" style="color: #ddeffc;"></i>
      </span>
      <span matTooltip="Open, Offer Prep, Offer Extended, Signed">
        <i class="fa-solid fa-circle" style="color: #e8e8e8;"></i>
      </span>
    </div>
    <div style="float: right">
      <button mat-raised-button
              [ngClass]="{'button-enabled': selectedRows.length > 0, 'button-disabled': selectedRows.length === 0}"
              (click)="openCreateEmailDialog()"
              [disabled]="!selectedRows.length">
        Send Email
      </button>
    </div>
  </div>
  <div>
    <ag-grid-angular
      style="width: 100%; height: 800px;"
      class="ag-theme-alpine"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions">
      >
    </ag-grid-angular>
  </div>

  <!-- AG Charts Component -->
<!--  <div style="width: 100%; height: 500px;">-->
<!--    <ag-charts-angular [options]="chartOptions"></ag-charts-angular>-->
<!--  </div>-->

</div>

<!--<app-portfolio></app-portfolio>-->
