import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrl: './stats-card.component.scss'
})
export class StatsCardComponent {

  @Input() icon!: string;
  @Input() title!: string;
  @Input() value!: any;
  @Input() backgroundColor!: string;
  @Input() fontColor!: string;
  @Input() headerValue!: string;
  @Input() headerValueFontColor!: string;
}
