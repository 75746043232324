import {ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {Search} from "../../models/search.model";
import {Subscription} from "rxjs";
import {SearchStateServiceService} from "../../states/search-state-service.service";
import {PortfolioSearchEngagement} from "../../models/portfolio-search-engagement.model";
import {PortfolioSearchEngagementService} from "../../services/portfolio-search-engagement.service";

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrl: './left-nav.component.scss'
})
export class LeftNavComponent implements OnInit, OnDestroy {

  @Output() navToggle = new EventEmitter<boolean>();

  searches!: Search[];
  isNavCollapsed: boolean = false;
  isNavToggledDueToSize: boolean = false
  currentSubMenu: any = null;

  navItems!: any[];
  expandedStates: { [key: string]: boolean } = {};
  selectedSearch: any = null;

  prepSearchUrl!: string;
  interviewSearchUrl!: string;
  searchFirmhUrl!: string;
  stakeholders!: string;
  onboarding!: string;
  postSurvey!: string;

  pinnedSearchCount!: number;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private portfolioSearchEngagementService: PortfolioSearchEngagementService,
    private searchStateServiceService: SearchStateServiceService) {
  }

  ngOnInit(): void {

    this.expandedStates['Dashboard'] = true; // Ensure it starts expanded

    this.getPinnedSearches();

    // Update links when selected search changes
    this.subscriptions.add(this.searchStateServiceService.selectedSearch$.subscribe(search => {
      this.selectedSearch = search;
      if (search) {
        this.setSearchLinks(search.id);
        if (!this.searches.some(s => s.id === this.selectedSearch.id)) {
          this.searches.push({...search, pinnedSearch: false});
        }
      }
    }));

    this.checkScreenSize();
  }

  toggleSubMenu(item: any): void {
    item.isExpanded = !item.isExpanded;
    this.expandedStates[item.label] = item.isExpanded;

    this.currentSubMenu = this.currentSubMenu === item ? null : item;
  }

  isSubMenuOpen(item: any): boolean {
    return this.currentSubMenu === item;
  }

  toggleNav(): void {
    this.isNavCollapsed = !this.isNavCollapsed;
    this.navToggle.emit(this.isNavCollapsed);
  }

  goToSearchDetails(searchId: number, event: Event) {
    event.stopPropagation();
    this.router.navigate(['/home/searches', searchId]);
  }

  ngOnDestroy() {
    console.log('Destroy has been called!');
    this.selectedSearch = null;
    this.subscriptions.unsubscribe();
  }

  initializeNavItems(searchId?: number): void {
    this.navItems = [
      { icon: 'fa fa-binoculars', label: 'Dashboard', link: '/home/dashboard', isExpanded: this.expandedStates['Dashboard'],
        submenu: [
          { icon: 'fa fa-clipboard-list', label: 'Search Prep', link: this.prepSearchUrl },
          { icon: 'fa fa-comments', label: 'Candidates', link: this.interviewSearchUrl },
          { icon: 'fa fa-industry', label: 'Search Firm', link: this.searchFirmhUrl },
          { icon: 'fa fa-users', label: 'Stakeholders', link: this.stakeholders },
          { icon: 'fa fa-user-check', label: 'Onboarding', link: this.onboarding },
          { icon: 'fa fa-poll', label: 'Post Survey', link: this.postSurvey }
        ]},
      // { icon: 'fa fa-tachometer-alt', label: 'Dashboard',  },
      { icon: 'fa fa-search', label: 'Searches', link: '/home/searches', submenu: [
          { icon: 'fa fa-warehouse', label: 'Capacity', link: '/home/searches/active' },
          { icon: 'fa fa-toolbox', label: 'Compensation Tool', link: '/home/searches/completed' },
          { icon: 'fa fa-align-center', label: 'HC Alignment', link: '/home/notes' }
        ]},
      { icon: 'fa fa-clipboard-list', label: 'Portfolio', link: '/home/portfolio-board', submenu: [
          { icon: 'fa fa-book', label: 'Cheatsheets', link: '/portfolio/notes' },
          { icon: 'fa fa-align-center', label: 'Alignment', link: '/portfolio/notes' }
        ]},
      { icon: 'fa fa-industry', label: 'Search Firms', link: '/home/interview-guide' },
      { icon: 'fa fa-sitemap', label: 'Network', link: '/home/network',
        // submenu: [
        //   { icon: 'fa fa-industry', label: 'Search Firm', link: '/network/search-firm' },
        //   { icon: 'fa fa-briefcase', label: 'Portfolio', link: '/network/portfolio' },
        //   { icon: 'fa fa-users', label: 'Candidates', link: '/network/candidates' },
        //   { icon: 'fa fa-address-book', label: 'Contact', link: '/network/contact' }
        // ]
      },
      { icon: 'fa fa-building', label: 'Companies', link: this.prepSearchUrl },
      { icon: 'fa fa-chart-line', label: 'Insights', link: '/home/reports' },
      { icon: 'fa fa-cogs', label: 'Tools', link: '/home/settings', submenu: [
          { icon: 'fa fa-sticky-note', label: 'Notes', link: '/home/notes' },
          { icon: 'fa fa-envelope', label: 'Email', link: '/home/notes' },
          { icon: 'fa fa-list', label: 'To-Do List', link: '/home/notes' },
        ]},
    ];
  }

  setSearchLinks(searchId: number) {
    const defaultSearchId = this.searches?.length > 0 ? this.searches[0]?.id : 0;  // Default to 0 or another safe value

    this.prepSearchUrl = `/home/search/${searchId || defaultSearchId}/prep`;
    this.interviewSearchUrl = `/home/search/${searchId || defaultSearchId}/interview`;
    this.searchFirmhUrl = `/home/search/${searchId || defaultSearchId}/firm`;
    this.stakeholders = `/home/search/${searchId || defaultSearchId}/stakeholders`;
    this.onboarding = `/home/search/${searchId || defaultSearchId}/onboarding`;
    this.postSurvey = `/home/search/${searchId || defaultSearchId}/postSurvey`;

    // console.log('URLs updated: ', this.prepSearchUrl, this.interviewSearchUrl);
    this.initializeNavItems();  // Reinitialize nav items with updated URLs
  }

  // Listen to window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    // Check if the width is less than or equal to 1450 and hasn't been toggled yet
    if (width <= 1450 && !this.isNavToggledDueToSize) {
      console.log('Window - Width :: ', width);
      this.toggleNav();
      this.isNavToggledDueToSize = true;  // Set the flag to true after toggling
    } else if (width > 1450 && this.isNavToggledDueToSize) {
      // If the width is greater than 1450 and the nav was toggled, toggle back
      this.toggleNav();
      this.isNavToggledDueToSize = false;  // Reset the flag
    }
  }

  updatePinnedSearch(searchId: number, pinnedSearch: boolean) {

    // Prepare the data as per required fields
    const portfolioSearchEngagementRequestData: Partial<PortfolioSearchEngagement> = {
      searchId: searchId,
      pinnedSearch: pinnedSearch,
      userProfileId: 2
    };

    this.portfolioSearchEngagementService.updateEngagement(portfolioSearchEngagementRequestData).subscribe({
      next: (response) => {
        console.log('Search updated successfully', response);
        const updatedSearches = this.searches.filter(search => search.id !== searchId);
        // console.log('Updated Searches:', updatedSearches);
        this.searches = updatedSearches;
        this.handlePinningChange(pinnedSearch);
        this.searchStateServiceService.updatePinnedSearches(updatedSearches);
        this.cdr.markForCheck();
      },
      error: (error) => console.error('Failed to update search', error)
    });
  }

  private handlePinningChange(pinnedSearch: boolean) {
    const newCount = pinnedSearch ? this.pinnedSearchCount + 1 : Math.max(this.pinnedSearchCount - 1, 0);
    this.searchStateServiceService.updatePinnedSearchCount(newCount); // Update the count in the state
  }

  private getPinnedSearches() {
    this.searchService.getPinnedSearchesByProfile(2).subscribe({
      next: (response) => {

        this.searches = response
          .map(searchEngagement => searchEngagement.search ? ({
            ...searchEngagement.search,
            pinnedSearch: searchEngagement.pinnedSearch
          }) : undefined)
          .filter(search => search !== undefined) as Search[]; // Assert that all elements are of type Search

        // console.log("Pinned Searches :: ", this.searches)
        // // Update the pinned searches in the SearchStateServiceService
        this.searchStateServiceService.updatePinnedSearches(this.searches);
        //
        this.initializeNavItems();
        // // Initialize links with default search once searches are loaded
        this.setSearchLinks(this.selectedSearch?.id || response[0]?.id);
      },
      error: (err) => console.error('Failed to load grouped searches:', err)
    });
  }

  goToHomePage() {
    this.router.navigate(['/home/dashboard']);
  }
}
